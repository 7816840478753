import React,{useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { NotifyUsers, HandleCompleteFees,
     HandleWallet, SuspendUsers, HandleUserProfile, ResetUserPass } from '../auth/ErrorHandler';
import Head from '../Nav/Head';

function ViewParent() {
      const {uid} = useParams();
      const [wallet, setWallet] = useState(null);
      const [stud, setStud] = useState([]);
      const [trans, setTrans] = useState([]);
      const [pData, setPData] = useState(); 
      const [loading, setLoading] = useState(false);
      const [names, setName] = useState('');
      const [dob, setDob] = useState('');
      const [gender, setGender] = useState('');
      const [race, setRace] = useState('');
      const [region, setRegion] = useState([]);
      const [homeLang, setHomeLang] = useState('');
      const [otherLang, setOtherLang] = useState('');
      const [idtype, setIdType] = useState('');
      const [idNum, setIdNum] = useState('');
      const [subSection, setSubSection] = useState(false);
      const [address, setAddress] = useState('');
      const [studID, setStudID] = useState();
      const [uregion, setURegion] = useState('');
      const [loadbut, setLoadBut] = useState(false)
      const [notis, setNotis] = useState(null);
      const [pid, setPid] = useState();
      const [mStatus, setMStatus] = useState();
      const [reload, setReload] = useState(false);
      const [mid, setMid] = useState();
        let mtype = 'parent';
      const susData = {mtype,pid,mStatus};
      const parentData = {pData}
  

      const history = useHistory();


      const validate = () =>{
                let valid = true;

        if(!names.trim() || !dob.trim() || !gender.trim() || !race.trim()
            || !uregion.trim() || !homeLang.trim() 
        || !otherLang.trim() || !idtype.trim() || !idNum.trim() || !address.trim()){
              valid = false
        }


                 return valid
      }


 const handleAdminStud = () =>{
                 setNotis(null)
         if(!validate()){
             setNotis(<div className='alert alert-danger text-center'>All input field must not be empty</div>)
         }else
         {
              setLoadBut(true)
             let datas = {names,uregion,idNum,idtype,otherLang,homeLang,race,address,dob,gender,pid}
            axios.post('/api-adminaddnewstudent-process',datas,{
                 headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                    setTimeout(()=>{
                            if(res.data.status === 'error'){
                                 setNotis(<div className={`alert alert-${res.data.color}`}>{res.data.message}</div>)
                            }else
                            {
                                setStudID(res.data.sid);
                                setSubSection(true);
                                setNotis(null)
                                setLoadBut(false)
                            }
                        
                    },1000)
            }).catch((err)=>{
                  console.log(err)
            })

         }
 }



     
 const noti = (type,message) =>{
       let data = {type,message}
      return (
        <>
         <NotifyUsers datas={data} />
        </>
      )
 }

 const studDetails = () =>{
      return (
        <div className='row'>
            { notis === null ? null : <div className='col-xl-12'>{notis} </div>}
        <div className='col-xl-12'>
            <div className='mb-3'>
            <label>Names</label>
            <input type='text' className='form-control' onChange={(e)=>setName(e.target.value)} />
            </div>

        </div>

        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Gender</label>
            <select type='text' className='custom-select' onChange={(e)=>setGender(e.target.value)} >
                <option value={''}>Select option</option>
                <option value={'Male'}>Male</option>
                <option value={'Female'}>Female</option>
                </select>
            </div>

        </div>



        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Date of Birth</label>
            <input type='date' className='form-control' onChange={(e)=>setDob(e.target.value)}/>
            </div>

        </div>


        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Race</label>
            <select type='text' className='custom-select' onChange={(e)=>setRace(e.target.value)}>
                <option value={''}>Choose Option</option>
                 <option value={'Black African'}>Black African</option>
                <option value={'Colored'}>Colored</option>
                <option value={'White'}>White</option>
                <option value={'Indian / Asia'}>Indian / Asia</option>
                 </select>
            </div>

        </div>

        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Region</label>
            <select type='text' className='custom-select' onChange={(e)=>setURegion(e.target.value)}>
            <option value={''}>Choose</option>
            { region.length > 0 ? region && region.map((r,i)=>{
                 return(
                    <option key={i} value={r.location}>{r.location}</option>
                 )
            }) : <option disabled>Not availabe</option>}
               
            </select>
            </div>

        </div>



        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Home Language</label>
            <input type='text' className='form-control' onChange={(e)=>setHomeLang(e.target.value)} />
            </div>

        </div>

        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Other Language</label>
            <input type='text' className='form-control' onChange={(e)=>setOtherLang(e.target.value)} />
            </div>

        </div>

        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Learner ID Type</label>
            <select type='text' className='custom-select' onChange={(e)=>setIdType(e.target.value)}>
                <option value={''}>Select option</option>
                <option value={'Student ID Number'}>Student ID</option>
                 <option value={'Passport ID'}>Passport ID</option>
                </select>
            </div>

        </div>

        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Learner ID Number</label>
            <input type='text' className='form-control' onChange={(e)=>setIdNum(e.target.value)} />
            </div>

        </div>

        <div className='col-xl-12'>
            <div className='mb-3'>
            <label>Address</label>
            <textarea type='text' className='form-control' onChange={(e)=>setAddress(e.target.value)}></textarea>
            </div>

        </div>

        <div className='col-xl-12 text-center'>
             { loadbut === false ?
            <button className='btn btn-blue' onClick={() => handleAdminStud()}>Continue</button>
            :
            <button className='btn btn-blue' disabled>Please wait...</button>
             }
        </div>


     </div>
      )
 }


 const showWarning =() =>{
    return(
      <div id="success-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-sm">
          <div class="modal-content modal-filled bg-danger">
              <div class="modal-body p-4">
                  <div class="text-center">
                  <i class="dripicons-wrong h1 text-white"></i>
                      <h4 class="mt-2 text-white">Oop Snap!</h4>
                      <p class="mt-3 text-white">You about to delete learner, all details about this learner will be lost. </p>
                     
                      <button type="button" onClick={()=> removeUser('student')} class="btn btn-light" data-dismiss="modal">Delete</button>
                      
                   
                    
                  </div>
              </div>
          </div>
      </div>
  </div>
    )
 }


 const removeUser = (usr) =>{
  const datas = {usr,uid:mid}
  axios.post('/api-removeuser-platform',datas,{
     headers:{'Content-Type':'application/json'}
  }).then((res)=>{
      if(res.data.status === 'success'){
         setReload(true)
      }
  }).catch((err)=>{
      console.log(err)
  })
}



 const userWallet = () => {
            let ps = {pid}
    return(

        <HandleWallet datas={ps} />
      )

 }

 const deleteUsers = (id) =>{
    setMid(id)
  
}


 const addStudentData = () =>{
      const ms = {studID,pid}
      return(
       
        <div class="modal fade" id="centermodal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="myCenterModalLabel">Add Learner</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body">
                               

                            { subSection === false ? studDetails() : <HandleCompleteFees datas={ms} /> }

                      </div>
                </div>
            </div>
        </div>
      )
 }

    useEffect(()=> {
         
         axios.get('/api-getusers-data/'+uid+'/parent',{
                headers:{'Content-Length':'application/json'}
         }).then((res)=>{
               
              setWallet(res.data.wallet[0].balance);
              setStud(res.data.stdData);
              setTrans(res.data.trans);
              setPData(res.data.udatas[0]);
              setRegion(res.data.region)
              setPid(res.data.udatas[0].userid)
              setMStatus(res.data.udatas[0].status)
              setLoading(true)
            
               

         }).catch((err)=>{
                console.log(err)
         })
         
    },[])
  return (
    <div id="wrapper">
    <Head />
                         <div className="content-page">
                    <div className="content">
                <div className="container-fluid mb-4">

                    { loading === false ? <p>Loading Content. Please wait...</p> :
                <>
                { showWarning() }
                <div className="row">
         <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                  <button type="button" className="btn btn-warning waves-effect btn-sm waves-light" data-toggle="modal" data-target="#centermodal"> Add Student</button>
                     &nbsp;&nbsp;

                     <button type="button" className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#centerwallet" data-backdrop="static"> <i className="mdi mdi-wallet-plus" /> Fund / Deduct From Wallet</button>
                     &nbsp;&nbsp;
                        {
                            <SuspendUsers datas={susData} />
                        }
                 
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Parent Dashboard</h4>
          </div>
        </div>
                 </div>


                <div class="row mt-2">
                            <div class="col-md-6 col-xl-4">
                                <div class="card-box">
                                    <i class="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i>
                                    <h4 class="mt-0 font-16">Wallet Balance</h4>
                                    <h2 class="text-primary my-3 text-center">R<span data-plugin="counterup">{ wallet !== null ? wallet : '0.00' }</span></h2>
                                    <p class="text-muted mb-0">Total income: $22506 <span class="float-right"><i class="fa fa-caret-up text-success mr-1"></i>10.25%</span></p>
                                </div>
                            </div>

                            <div class="col-md-6 col-xl-4">
                                <div class="card-box">
                                    <i class="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i>
                                    <h4 class="mt-0 font-16">Total Student</h4>
                                    <h2 class="text-primary my-3 text-center"><span data-plugin="counterup">{ stud.length }</span></h2>
                                    <p class="text-muted mb-0">Total sales: 2398 <span class="float-right"><i class="fa fa-caret-down text-danger mr-1"></i>7.85%</span></p>
                                </div>
                            </div>

                            <div class="col-md-6 col-xl-4">
                                <div class="card-box">
                                    <i class="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i>
                                    <h4 class="mt-0 font-16">Total Transaction</h4>
                                    <h2 class="text-primary my-3 text-center"><span data-plugin="counterup">{ trans.length }</span></h2>
                                    <p class="text-muted mb-0">Total users: 121 M <span class="float-right"><i class="fa fa-caret-up text-success mr-1"></i>3.64%</span></p>
                                </div>
                            </div>

                          
                        </div>
                         <HandleUserProfile datas={parentData} />
                         <ResetUserPass datas={parentData} />
                            { addStudentData() }
                            {  userWallet() }
                     <div className='row'>

                     <div className="col-lg-4 col-xl-4">
                                <div className="card-box text-center">
                                    <img src="../assets/images/users/user-1.jpg" className="rounded-circle avatar-lg img-thumbnail"
                                        alt="profile" />

                                    <h4 className="mb-0">{ pData.names }</h4>
                                    <p className="text-muted">PARENT</p>

                                    <button type="button" className="btn btn-success btn-xs waves-effect mb-2 waves-light" data-toggle="modal" data-target="#centerUserProfile" data-backdrop="static">Edit User Information</button>
                                    <button type="button" className="btn btn-danger btn-xs waves-effect mb-2 waves-light" data-toggle="modal" data-target="#resetPass" data-backdrop="static">Reset User Password</button>

                                    <div className="text-left mt-3">
                                        <h4 className="font-13 text-uppercase">About Me :</h4>

                                        <div class="table-responsive">
                                          <table class="table mb-0">
                                            <tr>
                                                <th>Email</th>
                                                <td>{ pData.email }</td>
                                            </tr>

                                            <tr>
                                                <th>Mobile</th>
                                                <td>{ pData.phone }</td>
                                            </tr>

                                            <tr>
                                                <th>Address</th>
                                                <td>{ pData.address }</td>
                                            </tr>


                                            <tr>
                                                <th>Temp Password</th>
                                                <td className='bg-danger text-white'>{ pData.temporary === null ? 'N/A' : pData.temporary }</td>
                                            </tr>

                                            <tr>
                                                <th>Date Register</th>
                                                <td>{ pData.date_reg }</td>
                                            </tr>

                                            </table>
                                            </div>
                                      
                                             </div>

                                   
                                </div> 

                             

                            </div> 



                            <div className="col-lg-8 col-xl-8">


                            <div className="card">
                                    <div className="card-body">
                                        
                                    <div className="row mb-2">
                                            <div className="col-sm-4">
                                                <h4>Learners</h4>
                                            </div>

                                          

                                        </div>

                                        <div className="table-responsive">


                                         
                                            { stud.length > 0 ?
                                        <table className="table table-centered table-nowrap table-hover mb-0">
                                                <thead>
                                                <tr>
                                                        <th>Names</th>
                                                       
                                                        <th>Grade</th>
                                                        <th>Accademic Year</th>
                                                        <th>Profile Status</th>
                                                        <th>Fee Due Date</th>
                                                        <th style={{width: 82}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                { stud && stud.map((s)=>{
                                                     return (
                                                        <tr>
                                                        <td className="table-user">
                                                          { s.names }
                                                        </td>
                                                        <td>
                                                           {s.grade }
                                                        </td>
                                                        <td>
                                                           {s.academic_year }
                                                        </td>
                                                        <td>
                                                           { s.profile_status !== 'Active' ? 'Pending' : 'Active'}
                                                        </td>
                                                        <td>
                                                            { s.due_date === null ? 'N/A' : s.due_date }
                                                        </td>
                                                      
                                                        <td>
                                                            <div style={{ cursor:'pointer' }} className="action-icon" title='view learner' onClick={()=> history.push('/student/'+s.stud_userid)}> <i className="mdi mdi-eye"></i></div>
                                                            &nbsp;&nbsp;
                                                            <div style={{ cursor:'pointer' }} className="action-icon" data-toggle="modal" data-target="#success-alert-modal" onClick={() => deleteUsers(s.stud_userid)}> <i className="mdi mdi-delete"></i></div>
                                                        </td>
                                                    </tr>
                                                     )
                                                })}
                                                   
                                                    </tbody>
                                                  
                                            </table>
                                            :  noti('info',`${pData.names} has not register any learner`) }
                                          

                                          </div>

                                          
                                </div>
                            </div>


                            </div>


                     </div>

                     </> }

                        </div>
                     </div>
                 </div>
             </div>
  )
}

export default ViewParent