import React,{useEffect, useState, useMemo} from 'react'
import { useHistory } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios'
import {handleLoad, NotifyUsers } from '../auth/ErrorHandler'
import Head from '../Nav/Head'
function Subjects() {

     const [subData, setSubData] = useState([])
     const [loading, setLoading] = useState(false);
     const [editSubject, setEditSubject] = useState(false);
     const [addSubject, setAddSubject] = useState(false);
     const [hideshow, setHideShow] = useState(false);
     const [sname, setSName] = useState('');
     const [price, setPrice] = useState('');
     const [grade, setGrade] = useState('');
     const [cate, setCate] = useState('');
     const [term, setTerm] = useState('');
     const [sid, setSid] = useState();
     const [notify, setNotify] = useState(null);
     const [lodSub, setLodSub] = useState(false)

     const history = useHistory()


    const noti = (type,message) =>{
        let data = {type,message}
       return (
         <>
          <NotifyUsers datas={data} />
         </>
       )
  }

  const controlSwitch = (type) =>{
            switch(type){
                 case 'add':
                     setAddSubject(true);
                     setHideShow(true)
                    break;
                case 'edit':
                    setEditSubject(true)
                    setHideShow(true)
                    break;
                   default:
                    setHideShow(false);
                    setEditSubject(false);
                    setAddSubject(false);

            }
  }

  const handleFormDatas = (type,uid) => {

                
       if(type === 'delete'){
                  
            const datas = {snames:sname,grade,price,cate,term,id:uid,type};
            axios.post('/api-process-subject',datas,{
               headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                  if(res.data.status === 'success'){
                     window.location=""
                  }
            }).catch((err)=>{
                console.log(err)
            });

        }else
        {
        if(!sname.trim() || !grade.trim() || !cate.trim() || !term.trim()){
               setNotify(noti('danger','Please make sure all form input are not empty'))
        }else
        {
             setLodSub(true)
             let id = sid;
             const datas = {snames:sname,grade,price,cate,term,id,type};
             axios.post('/api-process-subject',datas,{
                headers:{'Content-Type':'application/json'}
             }).then((res)=>{
                   setTimeout(()=>{
                    setLodSub(false)
                    setNotify(noti(res.data.status,res.data.message))
                   },1000)
                 
             }).catch((err)=>{
                 console.log(err)
             });

        }
    } 
  }

  const addSujectForm = () =>{
    return(
        <>
        <div class="col-lg-3">
            </div>
        <div class="col-lg-6">
                                <div class="card">
                                <div className='row'>
                                       
                                            <div className='col-lg-12'>
                                              
                                                <button type="button" onClick={()=> controlSwitch('')} class="btn btn-success waves-effect waves-light">Back</button>

                                             
                                           
                                            </div>
                                         </div>
                                      
                                    <div class="card-body">
                                    <h4 class="mb-3 header-title">Add subject</h4>
                                         { notify !== null ? notify : null }
                                        <form>
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Subject Name</label>
                                                <input type="text" onChange={(e)=>setSName(e.target.value)} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Grade</label>
                                                <select  class="form-control custom-select" onChange={(e)=>setGrade(e.target.value)} >
                                                    <option value={''}>Choose...</option>
                                                    <option value={1}>Grade 1</option>
                                                    <option value={2}>Grade 2</option>
                                                    <option value={3}>Grade 3</option>
                                                    <option value={4}>Grade 4</option>
                                                    <option value={5}>Grade 5</option>
                                                    <option value={6}>Grade 6</option>
                                                    <option value={7}>Grade 7</option>
                                                    <option value={8}>Grade 8</option>
                                                    <option value={9}>Grade 9</option>
                                                    <option value={10}>Grade 10</option>
                                                    <option value={11}>Grade 11</option>
                                                    <option value={12}>Grade 12</option>
                                                    </select>
                                            </div>

                                            <div class="form-group mb-3">
                                                <label for="exampleInputPassword1">Term</label>
                                                <select  class="form-control custom-select" onChange={(e)=>setTerm(e.target.value)} >
                                                    <option value={''}>Choose...</option>
                                                    <option value={1}>Term 1</option>
                                                    <option value={2}>Term 2</option>
                                                    <option value={3}>Term 3</option>
                                                    <option value={4}>Term 4</option>
                                                
                                                    </select>
                                            </div>

                                            <div class="form-group mb-3">
                                                <label for="exampleInputPassword1">Category</label>
                                                <select  class="form-control custom-select" onChange={(e)=>setCate(e.target.value)} >
                                                    <option value={''}>Choose...</option>
                                                    <option value={'Cores'}>Cores</option>
                                                    <option value={'Elect'}>Elect</option>
                                                
                                                
                                                    </select>
                                            </div>

                                            <div class="form-group mb-3">
                                                <label for="exampleInputPassword1">Price</label>
                                                <input type="text" class="form-control" id="exampleInputPassword1" onChange={(e)=>setPrice(e.target.value)}/>
                                            </div>

                                            { lodSub === false ?
                                            <button type="button" onClick={()=> handleFormDatas('add',null)} class="btn btn-primary waves-effect waves-light">Submit</button>
                                                    :
                                                    <button type="button" class="btn btn-primary waves-effect waves-light" disabled>Please wait...</button>
                                            }
                                            </form>

                                    </div> 
                                </div> 
                            </div>
         </>
     )

  }

  const editSubjectForm = () =>{

     return(
        <>
        <div class="col-lg-3">
            </div>
        <div class="col-lg-6">
                                <div class="card">
                                <div className='row'>
                                       
                                            <div className='col-lg-12'>
                                              
                                                <button type="button" onClick={()=> controlSwitch('')} class="btn btn-success waves-effect waves-light">Back</button>

                                             
                                           
                                            </div>
                                         </div>
                                      
                                    <div class="card-body">
                                    <h4 class="mb-3 header-title">Update Subject</h4>
                                         { notify !== null ? notify : null }
                                        <form>
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Subject Name</label>
                                                <input type="text" onChange={(e)=>setSName(e.target.value)} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" defaultValue={sname} />
                                                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Grade</label>
                                                <select  class="form-control custom-select" onChange={(e)=>setGrade(e.target.value)} >
                                                    <option value={grade}>Grade {grade}</option>
                                                    <option value={1}>Grade 1</option>
                                                    <option value={2}>Grade 2</option>
                                                    <option value={3}>Grade 3</option>
                                                    <option value={4}>Grade 4</option>
                                                    <option value={5}>Grade 5</option>
                                                    <option value={6}>Grade 6</option>
                                                    <option value={7}>Grade 7</option>
                                                    <option value={8}>Grade 8</option>
                                                    <option value={9}>Grade 9</option>
                                                    <option value={10}>Grade 10</option>
                                                    <option value={11}>Grade 11</option>
                                                    <option value={12}>Grade 12</option>
                                                    </select>
                                            </div>

                                            <div class="form-group mb-3">
                                                <label for="exampleInputPassword1">Term</label>
                                                <select  class="form-control custom-select" onChange={(e)=>setTerm(e.target.value)} >
                                                    <option value={term}>{term}</option>
                                                    <option value={1}>Term 1</option>
                                                    <option value={2}>Term 2</option>
                                                    <option value={3}>Term 3</option>
                                                    <option value={4}>Term 4</option>
                                                
                                                    </select>
                                            </div>

                                            <div class="form-group mb-3">
                                                <label for="exampleInputPassword1">Category</label>
                                                <select  class="form-control custom-select" onChange={(e)=>setCate(e.target.value)} >
                                                    <option value={cate}>{cate}</option>
                                                    <option value={'Cores'}>Cores</option>
                                                    <option value={'Elect'}>Elect</option>
                                                
                                                
                                                    </select>
                                            </div>

                                            <div class="form-group mb-3">
                                                <label for="exampleInputPassword1">Price</label>
                                                <input type="text" class="form-control" id="exampleInputPassword1" onChange={(e)=>setPrice(e.target.value)} defaultValue={price}/>
                                            </div>

                                            { lodSub === false ?
                                            <button type="button" onClick={()=> handleFormDatas('update',null)} class="btn btn-primary waves-effect waves-light">Submit</button>
                                                    :
                                                    <button type="button" class="btn btn-primary waves-effect waves-light" disabled>Please wait...</button>
                                            }
                                            </form>

                                    </div> 
                                </div> 
                            </div>
         </>
     )

  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'names', //access nested data with dot notation
        header: 'Subject Names',
        size: 150,
      },
      {
        accessorKey: 'cate',
        header: 'Category',
        size: 150,
      },
      {
        accessorKey: 'grade', //normal accessorKey
        header: 'Grade',
        size: 200,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 150,
      },
      {
        accessorKey: 'term',
        header: 'Term',
        size: 150,
      },
    ],
    [],
  );

  const getSubject = (id) =>{ 

        axios.get('/api-get-admin-subject/'+id,{
             headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                setTimeout(()=>{
                     
                     let udata = res.data.sdata;
                      setSName(udata[0].names);
                      setPrice(udata[0].price);
                      setCate(udata[0].cate);
                      setTerm(udata[0].term);
                      setGrade(udata[0].grade);
                      setSid(id)
                     setHideShow(true)
                     setEditSubject(true)
                },500)
        }).catch(err=> {
                console.log(err)
        }) 
  }
 
  useEffect(()=>{
        axios.get('/api-get-allsubject',{
             headers:{'Content-Type':'application/json'}
        }).then((res)=>{
              setSubData(res.data.sub);
              setLoading(true)
        }).catch((err)=>{
                console.log(err)
        })
  },[])

  return (
    <div id="wrapper">
    <Head />
                         <div className="content-page">
                    <div className="content">
                <div className="container-fluid mb-4">

                <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                    { hideshow === false ? 
                  <button type="button" className="btn btn-warning waves-effect btn-sm waves-light" onClick={()=>controlSwitch('add')}> Add Subject</button>
                   : null }
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Subject</h4>
          </div>
        </div>
                     </div>

                    <div className='row'>

                          { addSubject === false ? null : addSujectForm() }

                          { editSubject === false ? null : editSubjectForm() }

                          
                            { hideshow === false ?
                        <div className='col-lg-12'>

                        { loading !==false ? 
                       subData && <MaterialReactTable columns={columns} data={subData}
                        enableRowActions
                        renderRowActionMenuItems={({ row }) => [
                          <MenuItem key="edit" onClick={() => getSubject(row.original.subject_id) }>
                             <ListItemIcon>
                         <Edit fontSize='small' />
                  </ListItemIcon>
                            Edit
                          </MenuItem>,
                          <MenuItem key="delete" onClick={() => handleFormDatas('delete',row.original.subject_id)}>
                             <ListItemIcon>
                         <Delete fontSize='small' />
                        </ListItemIcon>
                            Delete
                          </MenuItem>,
                        ]}
                        />:
                        noti('danger','No subject as been added')
                    }

                        </div> : null }
                  
                  
                    </div>
                      

                    </div>
                  </div>
              </div>
            </div>
  )
}

export default Subjects