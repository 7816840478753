import React from 'react'


function Result({report}) {
 
  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  
  const todaysDateTime = () =>{
    const pay_time = formatAMPM(new Date());
     let date_ob = new Date();
    let monthe = ["jan", "feb", "mar", "apr", "may", "jun", "july", "aug", "sep", "oct", "nov", "dec"];
    let expire_month = date_ob.getMonth();
    let next_pay =  date_ob.getDate();
    let current_month = monthe[expire_month];
    let total_date_time = next_pay+' '+current_month+' '+' '+date_ob.getFullYear()+', '+pay_time;
  
      return total_date_time;
  }
  


  return (
     <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-body row'>
                       
                         { report.getReport !== null ?
                                <>
                        <div className='col-md-12'>

                                    <div className="invoice-title">
                                            <h4 className="float-end font-size-16">Assessment Report</h4>
                                            <div className="mb-4">
                                                <img src="/assets/images/logo-dark.png" alt="logo" height="20"/>
                                            </div>
                                        </div>
                                        <hr className='text-danger' style={{ marginTop:-5 }} />


                                          { report.centDetails === null ?
                                        <div className='row'>
                                        <div className="col-sm-6">
                                                <address style={{ lineHeight:2 }}>
                                                    <strong></strong><br />
                                                   <strong style={{ fontSize:20 }}>Names: { report.viewStud.names }</strong> <br />
                                                    
                                                    { report.viewStud.address }<br />
                                                </address>
                                            </div>

                                            <div className="col-sm-6">
                                                <address className="mt-2 mt-sm-0 text-start" style={{ marginRight:20, float:'right'}}>
                                                  <strong></strong><br />
                                                <strong>Learner ID: { report.viewStud.stud_userid }</strong><br />
                                                <strong>Grade: { report.viewStud.grade }</strong><br />
                                                <strong>Date: { todaysDateTime() }</strong>
                                                </address>
                                            </div>
                                        </div> :

                                <div className='row'>
                                <div className="col-sm-5">
                                        <address style={{ lineHeight:2 }}>
                                            <strong></strong><br />
                                          <strong className='font-size-16' style={{ textTransform:'uppercase' }}>Learners Names: { report.viewStud.names }</strong> <br />
                                            
                                            { report.viewStud.address+', '+report.viewStud.region }<br />
                                        </address>
                                    </div>

                                    <div className="col-sm-3">
                                        <address className="mt-2 mt-sm-0 text-start">
                                          <strong></strong><br />
                                        <strong >Learner ID: { report.viewStud.stud_userid }</strong><br />
                                        <strong>Grade: { report.viewStud.grade }</strong><br />
                                        <strong>Date: { todaysDateTime() }</strong>
                                        </address>
                                    </div>

                                    <div className="col-sm-3">
                                        <address className="mt-2 mt-sm-0 text-start" style={{ marginRight:20, float:'right'}}>
                                          <strong></strong><br />
                                        <strong className='font-size-16' style={{ textTransform:'uppercase' }}>Centre Name: { report.centDetails[0].centre_name }</strong><br />
                                        <strong>Centre ID: { report.centDetails[0].centre_id }</strong><br />
                                       
                                        </address>
                                    </div>
                                </div>
                                            }

                              <hr className='text-danger' />

                        <table className="table table-striped mb-0" style={{ backgroundColor:'#f9f3f3'}}>
                          <thead style={{ backgroundColor:'darkgreen', color:'#ffffff', textTransform:'uppercase' }}>
                            <tr>
                              <th>Displaying Available Result for : </th>
                              <th></th>
                                
                               {
                                report.terms === 'all' ? <>
                                  <th className='text-center' style={{ backgroundColor:'#da7f90' }}>Term 1</th>
                                  <th className='text-center'>Term 2</th>
                                  <th className='text-center' style={{ backgroundColor:'#da7f90' }}>Term 3</th>
                                  <th className='text-center'>Term 4</th>
                                </>
                               :
                               report.tm_Split.map((x, index)=>{
                                  return(
                                    <th key={index+1} className='text-center'>Term {x}</th>
                                  )
                                
                               })}
                             
                            
                            </tr>
                          </thead>
                          </table>
                   
                          <table className="table table-striped mb-0 mt-4" style={{ backgroundColor:'#f9f3f3' }}>
                          <thead style={{ backgroundColor:'darkblue', color:'white' }}>
                            <tr>
                              <th>Subject</th>
                              <th>Assessment</th>
                               {
                                report.terms === 'all' ?<>
                                  <th className='text-center'>Score Rating Term 1</th>
                                <th className='text-center'>Score Rating Term 1</th>
                                <th className='text-center'>Score Rating Term 1</th>
                                <th className='text-center'>Score Rating Term 1</th>
                              
                                
                                </>
                              
                                :
                               report.tm_Split.map((x, index)=>{
                                return(
                                  <th className='text-center' key={index+1}>Score Rating term {x}</th>
                                )
                              
                             }

                            
                             
                             )}

                              { report.lenTerm === 1 ?
                              <>
                                <th className='text-center'>Percentage</th>
                                <th className='text-center'>Symbol</th>
                              </> : null }
                              
                             
                            </tr>
                          </thead>
                          <tbody>
                                  { report.getReport && report.getReport.map((r)=>{
                                      return(
                                            <>
                                            {  report.checkDisplayTable(r) }
                                            </>
                                      )
                                       
                                  })}
                                

                               
                          </tbody>
                          </table>

                          { report.comment === '' ? null : 
                          <div>
                           <hr className='text-danger' />
                          <blockquote className="p-4 border-light border bg-primary text-white rounded mb-4">
                
                          <div className="d-flex">
                              <div className="me-3">
                                  <i className="bx bxs-quote-alt-left text-white font-size-24"></i>
                              </div>
                              <div>
                                  <strong className="mb-0"> Comment</strong>
                                  <p className="mb-0"> { report.comment }</p>
                              </div>
                          </div>
                          
                      </blockquote>
                      </div>
                          
                          }
                        </div>

                        <div className='col-md-7 justify-content-center mt-4'>

                            

                        </div>


                        <div className='col-md-5 justify-content-center mt-4'>
                        <table className="table table-striped mb-0">
                          <thead className='font-size-10' style={{ backgroundColor:'darkblue', color:'#ffffff', textTransform:'uppercase' }}>
                            <tr>
                              <th className='text-center'>Code</th>
                              <th className='text-center'>Description of Competence</th>
                              <th className='text-center'>Percentage</th>
                              <th className='text-center'>Symbol</th>
                           
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                  7
                              </td>
                              <td>
                              Outstanding achievement: Exceptional and, commendable execution of the skill
                              </td>
                              <td>
                              80%-100%
                              </td>
                              <td>
                                    A+
                              </td>
                            </tr>
                            <tr>
                              <td>
                                  6
                              </td>
                              <td>
                              Meritorious achievement: Very good execution of the skill.
                              </td>
                              <td>
                              70%-79%
                              </td>
                              <td>
                                  A
                              </td>
                            </tr>
                            <tr>
                              <td>
                                  5
                              </td>
                              <td>
                              Substantial achievement: Good execution of the skill.
                              </td>
                              <td>
                              60%-69%
                              </td>
                              <td>
                                  B
                              </td>
                            </tr>
                            <tr>
                              <td>
                                  4
                              </td>
                              <td>
                              Adequate achievement: Satisfactory progress.
                              </td>
                              <td>
                              50%-59%
                              </td>
                              <td>
                              C
                              </td>
                            </tr>
                            <tr>
                              <td>
                                  3
                              </td>
                              <td>
                              Moderate achievement: Little progress and assistance is required.
                              </td>
                              <td>
                              40%-49%
                              </td>
                              <td>
                                D
                              </td>
                            </tr>
                            <tr>
                              <td>
                                  2
                              </td>
                              <td>
                              Elementary achievement: Very little progress and requires constant assistance.
                              </td>
                              <td>
                              30%-39%
                              </td>
                              <td>
                                  E
                              </td>
                            </tr>
                            <tr>
                              <td>
                                  1
                              </td>
                              <td>
                              Not achieved: No progress and requires immediate attention.
                              </td>
                              <td>
                              0%-29%
                              </td>
                              <td>
                                  F
                              </td>
                            </tr>
                          </tbody>
                          </table>
                        </div>

                        <div className='col-md-12'>
                          <hr className='text-danger' />
                        <div className="d-print-none mt-4">
                                            <div className='text-center'>
                                       <a href="javascript:window.print()" className="btn btn-success waves-effect waves-light me-1"><i className="fa fa-print"></i></a>
                                       <button href="#" style={{ marginLeft:10 }} className="btn btn-primary w-md waves-effect waves-light">Download Result</button>
                                   </div>
                             </div>
                        </div>
                        </> : <div className='col-md-12 text-center'>{report.NotifyMe('danger','We are unable to get result for the selected term and year') } </div> }


                      </div>

                   
                    </div>

                  </div>

                 </div>
  )
}

export default Result