import React,{useMemo, useEffect, useState} from 'react'
import axios from 'axios'
import {useHistory} from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import {handleLoad, NotifyUsers } from '../auth/ErrorHandler'

import Head from '../Nav/Head';

function UploadQuestion() {
   
      const [paper, setPaper] = useState([]);
      const [loading, setLoading] = useState(false);
      const [uploadQ, setUploadQ] = useState(false);
      const [updateQ, setUpdateQ] = useState(false);
      const [hideShowQ, setHideShowQ] = useState(false)
      const [subject, setSubject] = useState([])
      const [year, setYear] = useState('');
      const [grade, setGrade] = useState('');
      const [sub, setSub] = useState('');
      const [subID, setSubID] = useState();
      const [term, setTerm] = useState('');
      const [cate, setCate] = useState('');
      const [dueDate, setDueDate] = useState('');
      const [notify, setNotify] = useState(null);
      const [umemo, setUMemo] = useState();
      const [uquestion, setUQuestion] = useState();
      const [uplStatus, setUplStatus] = useState(false);
      const [downID, setDownID] = useState();
      const [questAva, setQuestAva] = useState(null);
      const [memoAva, setMemoAva] = useState(null);
      const [assCate, setAssCate] = useState([]);
      const [delID, setDelID] = useState();
      const [dis, setDis] = useState(false);


      const history = useHistory();


      const deleteAssessment = (did) => {
    
              setDelID(did);
            document.getElementById('showmodal').click();
      }

      const removeAssess = () =>{
                  setDis(true);
                axios.post('/remove-assement-data',{delID},{
                  headers:{'Content-Type' : 'application/json'}
                }).then((res)=>{
                       if(res.data.mstate === 'success'){
                        setDis(false);
                       }
                }).catch(err=>{
                  console.log(err);
                })
      }

      const showWarning =() =>{
        return(
          <div id="success-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-sm">
              <div class="modal-content modal-filled bg-danger">
                  <div class="modal-body p-4">
                      <div class="text-center">
                      <i class="dripicons-wrong h1 text-white"></i>
                          <h4 class="mt-2 text-white">Oop Snap!</h4>
                          <p class="mt-3 text-white">You about to delete Assessment, all details about this assessment will be lost. </p>
                         
                          <button type="button" onClick={()=> removeAssess()} class="btn btn-light" data-dismiss="modal">Delete</button>
                  
                      </div>
                  </div>
              </div>
          </div>
      </div>
        )
     }


      const ACat = (datas,tm) =>{
              let ms;
               switch(tm){
                  case "1":
                    ms = datas.term1
                  break;
                  case "2":
                    ms = datas.term2
                  break;
                  case "3":
                    ms = datas.term3
                    break;
                  case "4":
                    ms = datas.term4
                    break;
               }

                        let sd = ms.split(',');
                       setAssCate(sd);

      }


      const getAssessmentCate = (tm) => {
                setTerm(tm)
              if(!tm.trim()){}else{
              axios.post('/get-assess-cate',{tm},{
                 headers:{'Content-Type' : 'application/json'}
              }).then((res)=>{
                    if(res.data.termData === 'empty'){
                      setAssCate([]);
                    }else
                    {
                         ACat(res.data.termData[0],tm)
                      // console.log(res.data.termData[0].term1)
                    
                    }
                  
              }).catch((err)=>{
                 console.log(err)
              })
            }
      }


      const viewUpdate = (did) =>{
          console.log(did)
        axios.get('/get-upload-data/'+did,{
          headers:{'Content-Type':'application/json'}
        }).then((res)=>{
              setTimeout(()=>{
                  let ud = res.data.uplData[0];
                   setGrade(ud.grade)
                   getSubject(ud.grade)
                   setTerm(ud.term);
                   setSub(ud.subject_name);
                   setSubID(ud.subject_id);
                   setDueDate(ud.due_date);
                   setYear(ud.year);
                   setCate(ud.asses_cate);
                   setDownID(did)
                   setMemoAva(ud.memo);
                   setQuestAva(ud.download_file)
                    getAssessmentCate(ud.term)
                   controlSwitch('edit')
              },500)
        }).catch(err=>{
          console.log(err)
        })
}




      const UploadAssessQuestion = (type) =>{
              setNotify(null)
        if(!year.trim() || !grade.trim() || !sub.trim() || !term.trim() || !cate.trim() || !dueDate.trim()){
              setNotify(NotifyMe('danger','Please make sure all form input are not empty'));
        }else
        {

            let qupl  = uquestion;
            let memo = umemo;

            if(type === 'add'){

            if(qupl){
              setUplStatus(true)
                      let formData = new FormData()

                      formData.append('year',year);
                      formData.append('grade',grade);
                      formData.append('subject',sub);
                      formData.append('term',term);
                      formData.append('cate',cate);
                      formData.append('dueDate',dueDate);
                      formData.append('question',qupl);
                      formData.append('memo',memo);
                      formData.append('type',type)
                      axios.post('/api-uploadquestion',formData,{
                          headers:{'Content-Type':'multipart/form-data'}
                      }).then((res)=>{
                               setTimeout(()=>{
                                  setUplStatus(false)
                                setNotify(NotifyMe(res.data.color,res.data.message))
                               },1000)
                           
                      }).catch(err=>{
                           console.log(err)
                      })

            }else
            {
              setNotify(NotifyMe('danger','Question paper is require before proceeding'));
            }
          }else{

            setUplStatus(true)
            let formData = new FormData()

            formData.append('year',year);
            formData.append('grade',grade);
            formData.append('subject',sub);
            formData.append('term',term);
            formData.append('cate',cate);
            formData.append('dueDate',dueDate);
            formData.append('question',qupl);
            formData.append('memo',memo);
            formData.append('type',type);
            formData.append('id',downID)
            axios.post('/api-uploadquestion',formData,{
                headers:{'Content-Type':'multipart/form-data'}
            }).then((res)=>{
                     setTimeout(()=>{
                        setUplStatus(false)
                      setNotify(NotifyMe(res.data.color,res.data.message))
                     },1000)
                 
            }).catch(err=>{
                 console.log(err)
            })


          }
        }
}




    const getYears = () =>{
           let i = new Date().getFullYear() + 1;
             let m =[];
             for (let k=2021; k < i; k++){
                     m.push(k);
             }

              return m;
    }

    const getSubject = (grd) =>{
          setGrade(grd)
          axios.get('/api-get-subject/'+grd,{
             headers:{'Content-Type':'application/json'}
          }).then((res)=>{
                setTimeout(()=>{
                  if(res.data.subjectData !== 'empty'){
                    setSubject(res.data.subjectData)
              }else
              {
                    setSubject([])
              }
                },500)
              
          }).catch((err)=>{
             console.log(err)
          })
    }

    const NotifyMe = (type,message) =>{
        const datas = {type,message}
         return (
            <NotifyUsers datas={datas} />
         )
    }

    const controlSwitch = (type) =>{
      switch(type){
           case 'add':
               setUploadQ(true);
               setHideShowQ(true)
              break;
          case 'edit':
              setUpdateQ(true)
              setHideShowQ(true)
          
              break;
             default:
              setHideShowQ(false);
              setUploadQ(false);
              setUpdateQ(false);

      }
}

 

    const uplodaeAssessment = () =>{

       return (
        <div className='row'>
          <div className='col-lg-3'></div>
          <div className='col-lg-6'>
                                        
             <div className='card'>
              <div className='card-body'>
              <button type="button" onClick={()=> controlSwitch('')} class="btn btn-success waves-effect waves-light" style={{ float:'right' }}>Back</button>
          
              <h4 class="mb-3 header-title">Upload Assessment</h4>

                              { notify !== null ? notify : null}
                               <div class="form-group">
                                                <label for="exampleInputPassword1">Grade</label>
                                                <select  class="form-control custom-select" onChange={(e)=> getSubject(e.target.value)} >
                                                    <option value={''}>Choose...</option>
                                                    <option value={1}>Grade 1</option>
                                                    <option value={2}>Grade 2</option>
                                                    <option value={3}>Grade 3</option>
                                                    <option value={4}>Grade 4</option>
                                                    <option value={5}>Grade 5</option>
                                                    <option value={6}>Grade 6</option>
                                                    <option value={7}>Grade 7</option>
                                                    <option value={8}>Grade 8</option>
                                                    <option value={9}>Grade 9</option>
                                                    <option value={10}>Grade 10</option>
                                                    <option value={11}>Grade 11</option>
                                                    <option value={12}>Grade 12</option>
                                                    </select>
                                            </div>


                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Subject</label>
                                                <select  class="form-control custom-select" onChange={(e)=> setSub(e.target.value)}>
                                                    <option value={''}>Choose...</option>
                                                      { subject.length > 0 ? subject && subject.map((s,i)=>{
                                                          return (
                                                                <option key={i} value={s.names+'=>'+s.subject_id}>{s.names +' - '+s.cate}</option>
                                                          )
                                                      }) : <option disabled>Not Available</option>}
                                                    </select>
                                            </div>


                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Term</label>
                                                <select  class="form-control custom-select" onChange={(e)=> getAssessmentCate(e.target.value)}>
                                                    <option value={''}>Choose...</option>
                                                    <option value={1}>Term 1</option>
                                                    <option value={2}>Term 2</option>
                                                    <option value={3}>Term 3</option>
                                                    <option value={4}>Term 4</option>
                                                   
                                                    </select>
                                            </div>


                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Assessment Category</label>
                                                <select  class="form-control custom-select" onChange={(e)=> setCate(e.target.value)}>
                                                    <option value={''}>Choose...</option>
                                                     { assCate.length > 0 ? assCate && assCate.map((c,i)=>{
                                                            return(
                                                              <option key={i} value={c}>{c}</option>
                                                            )
                                                     }) : <option disabled>Not available</option> }
                                                  
                                                    </select>
                                            </div>

                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Year</label>
                                                <select  class="form-control custom-select" onChange={(e)=> setYear(e.target.value)} >
                                                    <option value={''}>Choose...</option>
                                                      { getYears().map((x)=>{
                                                            return(
                                                              <option value={x}>{x}</option>
                                                            )
                                                      })}
                                                    </select>
                                            </div>

                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Due Date</label>
                                                <input type="date" class="form-control" onChange={(e)=> setDueDate(e.target.value)} id="exampleInputEmail1" aria-describedby="emailHelp" />
                                               
                                            </div>
                                         
                                            <div class="form-group row mb-3">
                                              <div className='col-lg-6'>
                                              <label for="exampleInputEmail1">Upload Question</label>
                                                <input type="file" class="form-control" id="question" onChange={(e)=> setUQuestion(e.target.files[0])} aria-describedby="emailHelp" />
                                               
                                              </div>
                                              <div className='col-lg-6'>
                                              <label for="exampleInputEmail1">Upload Memo</label>
                                                <input type="file" class="form-control" id="memo" onChange={(e)=> setUMemo(e.target.files[0])} aria-describedby="emailHelp" />
                                               
                                              </div>
                                              
                                            </div>
                                                  {  uplStatus === false ?
                                            <button type="button" onClick={()=> UploadAssessQuestion('add')} class="btn btn-primary waves-effect waves-light pull-right">Upload Question</button>
                                                      :
                                                      <button type="button" class="btn btn-primary waves-effect waves-light pull-right" disabled>Please wait...</button>
                                       
                                                  }

                                            

              </div>
             </div>
          </div>
        </div>
       )

    }



    const updateAssessment = () =>{
      return (
        <div className='row'>
          <div className='col-lg-3'></div>
          <div className='col-lg-6'>
                                        
             <div className='card'>
              <div className='card-body'>
              <button type="button" onClick={()=> controlSwitch('')} class="btn btn-success waves-effect waves-light" style={{ float:'right' }}>Back</button>
          
              <h4 class="mb-3 header-title">Update Assessment</h4>


              { notify !== null ? notify : null}
                               <div class="form-group">
                                                <label for="exampleInputPassword1">Grade</label>
                                                <select  class="form-control custom-select" onChange={(e)=> getSubject(e.target.value)} >
                                                    <option value={grade}>Grade {grade}</option>
                                                    <option value={1}>Grade 1</option>
                                                    <option value={2}>Grade 2</option>
                                                    <option value={3}>Grade 3</option>
                                                    <option value={4}>Grade 4</option>
                                                    <option value={5}>Grade 5</option>
                                                    <option value={6}>Grade 6</option>
                                                    <option value={7}>Grade 7</option>
                                                    <option value={8}>Grade 8</option>
                                                    <option value={9}>Grade 9</option>
                                                    <option value={10}>Grade 10</option>
                                                    <option value={11}>Grade 11</option>
                                                    <option value={12}>Grade 12</option>
                                                    </select>
                                            </div>


                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Subject</label>
                                                <select  class="form-control custom-select" onChange={(e)=> setSub(e.target.value)}>
                                                    <option value={sub+'=>'+subID}>{sub}</option>
                                                      { subject.length > 0 ? subject && subject.map((s,i)=>{
                                                          return (
                                                                <option key={i} value={s.names+'=>'+s.subject_id}>{s.names +' - '+s.cate}</option>
                                                          )
                                                      }) : <option disabled>Not Available</option>}
                                                    </select>
                                            </div>


                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Term</label>
                                                <select  class="form-control custom-select" onChange={(e)=> getAssessmentCate(e.target.value)}>
                                                    <option value={term}>{term}</option>
                                                    <option value={1}>Term 1</option>
                                                    <option value={2}>Term 2</option>
                                                    <option value={3}>Term 3</option>
                                                    <option value={4}>Term 4</option>
                                                   
                                                    </select>
                                            </div>


                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Assessment Category</label>
                                                <select  class="form-control custom-select" onChange={(e)=> setCate(e.target.value)}>
                                                    <option value={cate}>{cate}</option>
                                                    { assCate.length > 0 ? assCate && assCate.map((c,i)=>{
                                                            return(
                                                              <option key={i} value={c}>{c}</option>
                                                            )
                                                     }) : <option disabled>Not available</option> }
                                                  
                                                  
                                                    </select>
                                            </div>

                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Year</label>
                                                <select  class="form-control custom-select" onChange={(e)=> setYear(e.target.value)} >
                                                    <option value={year}>{year}</option>
                                                      { getYears().map((x)=>{
                                                            return(
                                                              <option value={x}>{x}</option>
                                                            )
                                                      })}
                                                    </select>
                                            </div>

                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Due Date</label>
                                                <input type="date" class="form-control" onChange={(e)=> setDueDate(e.target.value)} defaultValue={dueDate} id="exampleInputEmail1" aria-describedby="emailHelp" />
                                               
                                            </div>
                                         
                                            <div class="form-group row mb-3">
                                              <div className='col-lg-6 mb-3'>
                                              <label for="exampleInputEmail1">Upload Question</label>
                                                <input type="file" class="form-control" id="question" onChange={(e)=> setUQuestion(e.target.files[0])} aria-describedby="emailHelp" />
                                                { questAva !== null ? <small className='text-dark'>Question is available already. but you can change the question if you like by uploading another</small> :
                                               <small className='text-danger'>No question available. You can upload question</small>
                                               }
                                              </div>
                                              <div className='col-lg-6 mb-3'>
                                              <label for="exampleInputEmail1">Upload Memo</label>
                                                <input type="file" class="form-control" id="memo" onChange={(e)=> setUMemo(e.target.files[0])} aria-describedby="emailHelp" />
                                               { memoAva !== null ? <small className='text-dark'>Memo is available already. but you can change the memo if you like by uploading another memo</small> :
                                               <small className='text-danger'>No memo available. You can upload memo</small>
                                               }
                                              </div>
                                              
                                            </div>
                                                  {  uplStatus === false ?
                                            <button type="button" onClick={()=> UploadAssessQuestion('update')} class="btn btn-primary waves-effect waves-light pull-right">Upload Question</button>
                                                      :
                                                      <button type="button" class="btn btn-primary waves-effect waves-light pull-right" disabled>Please wait...</button>
                                       
                                                  }

                                            

              </div>
             </div>
          </div>
        </div>
       )
    }

    const columns = useMemo(
        () => [
          {
            accessorKey: 'memo', //access nested data with dot notation
            header: 'Memo',
            size: 150,
          },
          {
            accessorKey: 'names',
            header: 'Subject Name',
            size: 150,
          },
          {
            accessorKey: 'sid', //normal accessorKey
            header: 'Subject ID',
            size: 200,
          },
          {
            accessorKey: 'grade',
            header: 'Grade',
            size: 150,
          },
          {
            accessorKey: 'term',
            header: 'Term',
            size: 150,
          },
          {
            accessorKey: 'cate',
            header: 'Category',
            size: 150,
          },
          {
            accessorKey: 'year',
            header: 'Year',
            size: 150,
          },
        ],
        [],
      );


      useEffect(()=>{
        
            axios.get('/api-getdownload-question',{
               headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                  setTimeout(()=>{
                       setPaper(res.data.downData);
                      setLoading(true)
                  },1000)
            }).catch((err)=>{
                console.log(err)
            })
      },[dis])
      

  return (
    <div id="wrapper">
    <Head />
    <div className="content-page">
 <div className="content">
 <div className="container-fluid">


                { loading === false ? <p>Loading content. Please wait...</p>
                : 
                <>

<div className="row">
        
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                  { hideShowQ === false ? 
                  <button type="button" onClick={()=> controlSwitch('add')} className="btn btn-pink waves-effect btn-sm waves-light">  Upload Assessment</button>
                  : null }
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Assessment Upload</h4>
          </div>
        </div>
                         </div>

                  { uploadQ === false ? null : uplodaeAssessment() }

                  { updateQ === false ? null : updateAssessment() }

                 { hideShowQ !== false ? null :   paper.length > 0 ? <div className='row mt-4'>

                  <div className='col-lg-12'>
                  { paper && <MaterialReactTable columns={columns} data={paper}
                enableRowActions
                renderRowActionMenuItems={({ row }) => [
                  <MenuItem key="edit" onClick={() => viewUpdate(row.original.did)}>
                     <ListItemIcon>
                 <Edit fontSize='small' />
          </ListItemIcon>
                    Update
                  </MenuItem>,
                
                <MenuItem key="delete" onClick={() => deleteAssessment(row.original.did)}>
                     <ListItemIcon>
                 <Delete fontSize='small' />
                 </ListItemIcon>
                    Delete
                  </MenuItem>,
                ]}
                /> }
                   
                  </div>
                 </div> :
                  <div className='row'>
                    <div className='col-lg-12'>
                      { NotifyMe('danger','You have not upload any question')}
                    </div>
                  </div>
                 }
                
                </>}
            </div>
          </div>
         </div>
         <div id='showmodal' data-toggle="modal" data-target="#success-alert-modal" style={{ display:'none' }}></div>
         {showWarning()}
     </div>
  )
}

export default UploadQuestion