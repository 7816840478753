import React,{useEffect, useState, useMemo} from 'react'
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios'
import {handleLoad, NotifyUsers } from '../auth/ErrorHandler'
import Head from '../Nav/Head'

export default function Fee() {

        const [pack, setPack] = useState([]);
        const [gradeFee, setGradeFee] = useState([]);
        const [gradesData, setGradesData] = useState();
        const [editView, setEditView] = useState(false);
        const [loadBut, setLoadBut] = useState(false);
        const [gid, setGid] = useState();
        const [gnames, setGnames] = useState('');
        const [gTermPrice, setGTermPrice] = useState('');
        const [gYearPrice, setGYearPrice] = useState('');
        const [gYear, setGYear] = useState('');
        const [gNotify, setGNotify] = useState(null);
        const [gradeStatus, setGradeStatus] = useState();
        const [packName, setPackName] = useState('');
        const [packPrice, setPackPrice] = useState('');
        const [pid, setPid] = useState();
        const [loadPack, setLoadPack] = useState(false);
        const [pedit, setPEdit] = useState(false);
    



        const deleteDats = (base) =>{
              axios.get('/api-adminfee-setup/delete/'+base,{
                 headers:{'Content-Type':'application/json'}
              }).then((res)=>{
                  if(res.data.status === 'success'){
                      window.location ="";
                  }
              }).catch((err)=>{
                    console.log(err)
              })
        }


      const getEditPackage = (id) =>{
                setPid(id)
               axios.get('/api-adminfee-setup/get-fee/'+id,{
                 headers:{'Content-Type':'application/json'}
               }).then((res)=>{
                    setTimeout(()=>{
                        setPackName(res.data.mfee[0].pack);
                        setPackPrice(res.data.mfee[0].fee);
                         setPEdit(true)
                    },500)
               }).catch((err)=>{
                  console.log(err)
               })
      }


        const addGrdState = () =>{
            setGradeStatus('add');
            setEditView(true)
        }
        const NotifyMe = (type,message) =>{
          const datas = {type,message}
           return (
              <NotifyUsers datas={datas} />
           )
      }


      const UpdateUPack = (type,utype) =>{
              setGNotify(null)
           if(!packName.trim() || !packPrice.trim()){
            setGNotify(NotifyMe('danger','Please provide all form input'))
           }else{
        let link;
         if(type === 'edit'){
          link = `/api-adminfee-setup/${utype}/${pid}-${packName}-${packPrice}`
            
         }else{
          link = `/api-adminfee-setup/${utype}/${packName}-${packPrice}`
         }
          setLoadPack(true)
         axios.get(link,{
           headers:{'Content-Type':'application/json'}
         }).then((res)=>{
                setTimeout(()=>{
                  setLoadPack(false)
                  setGNotify(NotifyMe(res.data.color,res.data.message))
                },1000)
            
         }).catch((err)=>{
            console.log(err)
         })
        }

  }


      const UpdatePackage = (type,utype) =>{


        if(!gTermPrice.trim() || !gYearPrice.trim() || !gYear.trim()){
          setGNotify(NotifyMe('danger','Please provide all form input'))
         }else{
      let link;
       if(type === 'edit'){
        link = `/api-adminfee-setup/${utype}/${gid}-${gTermPrice}-${gYearPrice}-${gYear}-${gnames}`
          
       }else{
        link = `/api-adminfee-setup/${utype}/${gnames}-${gTermPrice}-${gYearPrice}-${gYear}`
       }
        setLoadBut(true)
       axios.get(link,{
         headers:{'Content-Type':'application/json'}
       }).then((res)=>{
              setTimeout(()=>{
                setLoadBut(false)
                setGNotify(NotifyMe(res.data.color,res.data.message))
              },1000)
          
       }).catch((err)=>{
          console.log(err)
       })
      }

      }

      const editGrades = (gid) =>{
              setGid(gid)
            axios.get('/api-adminfee-setup/get-grade/'+gid,{
                   headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                    setTimeout(()=>{
                     setEditView(true);
                     setGradeStatus('edit')
                      setGradesData(res.data.gData[0])
                       setGnames(res.data.gData[0].names)
                       setGTermPrice(res.data.gData[0].term)
                       setGYearPrice(res.data.gData[0].year)
                       setGYear(res.data.gData[0].term_year);
                       
                    },500)
                
            }).catch((err)=>{
               console.log(err)
            })
      }

      const columns = useMemo(
        () => [
          {
            accessorKey: 'names', //access nested data with dot notation
            header: 'Grades',
            size: 150,
          },
          {
            accessorKey: 'term',
            header: 'Termly Fee',
            size: 150,
          },
          {
            accessorKey: 'year', //normal accessorKey
            header: 'Yearly Fee',
            size: 200,
          },
          {
            accessorKey: 'valid_term',
            header: 'Current Term',
            size: 150,
          },
          {
            accessorKey: 'term_year',
            header: 'Year',
            size: 150,
          },
        ],
        [],
      );


    useEffect(()=>{
          axios.get('/api-adminfee-setup/all/notthing',{
             headers:{'Content-Type':'application/json'}
          }).then((res)=>{
              setPack(res.data.feeData);  
              setGradeFee(res.data.gradeData);          
          }).catch((err)=>{
                console.log(err)
          })
    },[])

  return (
    <div id="wrapper">
    <Head />
                  <div className="content-page">
              <div className="content">
          <div className="container-fluid mb-4">


          <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                     <button type='button' className='btn btn-sm btn-blue' onClick={()=> addGrdState()}>Add Grade</button>
                     <button type='button' className='btn btn-sm btn-primary' data-toggle="modal" data-target="#centermodal" data-backdrop="static" style={{ marginLeft:10 }}>Add New Package</button>

                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Fees & Grade</h4>
          </div>
        </div>
                     </div>

                        <div className='row'>

                        <div className="modal fade" id="centermodal" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">Add Packages</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div className="modal-body p-4">

            { gNotify !== null ? gNotify : null }
          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label"> Package Name</label>
                <input className="form-control" onChange={(e)=> setPackName(e.target.value)} id="field-7"  />
              </div>
            </div>
          </div>
        


          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label"> Price</label>
                <input className="form-control" id="field-7"  onChange={(e)=> setPackPrice(e.target.value)}   />
              </div>
            </div>
          </div>
        
        

        
        
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
       
        { loadPack === false ?  <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=> UpdateUPack('add','add-fee')}>Submit</button>
          :
          <button className="btn btn-primary" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>  }

        </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}


                            
              


           



                            <div className='col-lg-12'>
                                <div className='card'>

                                { pedit !== false ? 
                                 <div className='card-body row'>
                                  <div className='col-lg-12'>
                                    <h4>Edit Packages</h4>
                                    <button type='button' style={{ float:'right' }} onClick={()=> setPEdit(false)} className='btn btn-blue btn-sm'>Back</button>
                                  </div>
                                   <div className='col-lg-4'>
                                    </div>

                                    <div className='col-lg-4'>

                              { gNotify !== null ? gNotify : null }

                                      <label>Package Name</label>
                                  
                                  <div className="input-group input-group-merge mb-3">
                                  
                                      <input className='form-control' placeholder='Grade' onChange={(e)=> setPackName(e.target.value)} defaultValue={packName} />
                                    </div>


                                      <label>Price</label>
                                      <div className="input-group input-group-merge mb-3">
                                    
                                        <input className='form-control' placeholder='Termly' onChange={(e)=> setPackPrice(e.target.value)} defaultValue={packPrice} />
                                        </div>

                                    
                               

                                      <div className='text-center'>
                                        { loadPack === false ? 
                                        <button onClick={()=>UpdateUPack('edit','edit-fee')} className='btn btn-blue btn-sm'>Submit</button>
                                        :     <button className='btn btn-blue btn-sm' disabled>Please wait...</button>
                                        }
                                        </div>

                                    </div>
                                                                
                                </div> :
                             
                                <div className='card-body'>
                                   <h4>Package Fee</h4>
                                  { pack.length > 0 ? 
                                <table className='table'>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Package Name</th>
                                    <th>Package Price (R)</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                   { pack && pack.map((p, i)=>{
                                         return(
                                          <tr key={i}>
                                             <td>{i+1}</td>
                                            <td>{p.pack}</td>
                                            <td>{p.fee}</td>
                                            <td><button className='btn btn-xs btn-blue' onClick={()=> getEditPackage(p.id)}>Edit</button>
                                            <button className='btn btn-xs btn-danger' onClick={()=> deleteDats(p.id+'-pack_fee')} style={{ marginLeft:10 }}>Remove</button>
                                            </td>
                                          </tr>
                                         )
                                   })}
                                </tbody>


                                </table> : NotifyMe('danger','No package has been created') }

                                    </div>
                                    
                                  }
                                </div>
                            </div>

                        </div>



                        <div className='row'>

                         { editView !== false ?

                           <div className='col-lg-12'>
                           <div className='card'>

                            { gradeStatus === 'add' ? 

<div className='card-body row'>
<div className='col-lg-12'>
<h4> { gradeStatus === 'add' ?  'Add Grades' : 'Update Grade' }</h4>
<button onClick={()=> setEditView(false)} style={{ float:'right' }} className='btn btn-sm btn-blue'>Back</button>
  </div>
  


<div className='col-lg-4'>
  </div>
  <div className='col-lg-4'>

                     { gNotify !== null ? gNotify : null }

                              <label>Grade</label>
                          
                          <div className="input-group input-group-merge mb-3">
                          
                              <input className='form-control' placeholder='Grade' onChange={(e)=> setGnames(e.target.value)} />
                            </div>

                            <label>Termly Price</label>
                            <div className="input-group input-group-merge mb-3">
                          
                              <input className='form-control' placeholder='Termly' onChange={(e)=> setGTermPrice(e.target.value)} />
                              </div>

                              <label>Yearly price</label>
                              <div className="input-group input-group-merge mb-3">
                            
                              <input className='form-control' placeholder='Yearly' onChange={(e)=> setGYearPrice(e.target.value)}  />
                              </div>

                              <label>Year</label>
                              <div className="input-group input-group-merge mb-3">
                              <select className='custom-select' onChange={(e)=> setGYear(e.target.value)}>
                                <option value={''}>Choose Year</option>
                                <option value={2022}>2022</option>
                                <option value={2023}>2023</option>
                                <option value={2024}>2024</option>
                                <option value={2025}>2025</option>
                                <option value={2026}>2026</option>
                                <option value={2027}>2027</option>
                                <option value={2028}>2028</option>
                                <option value={2029}>2029</option>
                                <option value={2030}>2030</option>
                                </select>
                              </div>

                              <div className='text-center'>
                                { loadBut === false ? 
                                <button onClick={()=>UpdatePackage('edit','add-grade')} className='btn btn-blue btn-sm'>Submit</button>
                                :     <button className='btn btn-blue btn-sm' disabled>Please wait...</button>
                                }
                                </div>
                            </div>
                          </div>
                            
                          :

                            <div className='card-body row'>
                            <div className='col-lg-12'>
                            <h4>Update Grades</h4>
                            <button onClick={()=> setEditView(false)} style={{ float:'right' }} className='btn btn-sm btn-blue'>Back</button>
                              </div>
                              
                           

                            <div className='col-lg-4'>
                              </div>
                              <div className='col-lg-4'>

                                { gNotify !== null ? gNotify : null }

                                  <label>Grade</label>
                               
                              <div className="input-group input-group-merge mb-3">
                              
                                  <input className='form-control' placeholder='Grade' onChange={(e)=> setGnames(e.target.value)} defaultValue={gradesData.names} />
                                </div>

                                <label>Termly Price</label>
                                <div className="input-group input-group-merge mb-3">
                               
                                  <input className='form-control' placeholder='Termly' onChange={(e)=> setGTermPrice(e.target.value)} defaultValue={gradesData.term}/>
                                  </div>

                                  <label>Yearly price</label>
                                  <div className="input-group input-group-merge mb-3">
                                 
                                  <input className='form-control' placeholder='Yearly' onChange={(e)=> setGYearPrice(e.target.value)} defaultValue={gradesData.year} />
                                  </div>

                                  <label>Year</label>
                                  <div className="input-group input-group-merge mb-3">
                                  <select className='custom-select' onChange={(e)=> setGYear(e.target.value)}>
                                    <option value={gradesData.term_year}>{gradesData.term_year}</option>
                                    <option value={2022}>2022</option>
                                    <option value={2023}>2023</option>
                                    <option value={2024}>2024</option>
                                    <option value={2025}>2025</option>
                                    <option value={2026}>2026</option>
                                    <option value={2027}>2027</option>
                                    <option value={2028}>2028</option>
                                    <option value={2029}>2029</option>
                                    <option value={2030}>2030</option>
                                    </select>
                                  </div>

                                  <div className='text-center'>
                                    { loadBut === false ? 
                                    <button onClick={()=>UpdatePackage('edit','edit-grade')} className='btn btn-blue btn-sm'>Update</button>
                                    :     <button className='btn btn-blue btn-sm' disabled>Please wait...</button>
                                    }
                                    </div>
                                </div>
                              </div> }


                            </div>
                          </div> :
                          <div className='col-lg-12'>
                             { gradeFee.length > 0 ?
                               gradeFee && <MaterialReactTable columns={columns} data={gradeFee}
                               enableRowActions
                               renderRowActionMenuItems={({ row }) => [
                                 <MenuItem key="edit" onClick={() => editGrades(row.original.id) }>
                                    <ListItemIcon>
                                <Edit fontSize='small' />
                         </ListItemIcon>
                                   Edit
                                 </MenuItem>,
                                 <MenuItem key="delete" onClick={() => deleteDats(row.original.id+'-grades') }>
                                    <ListItemIcon>
                                <Delete fontSize='small' />
                               </ListItemIcon>
                                   Delete
                                 </MenuItem>,
                               ]}
                               /> : NotifyMe('danger','You dont have any grade created')}
                          </div> }

                        </div>



                    </div>
                </div>
             </div>
        </div>
  )

}
