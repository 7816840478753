import axios from 'axios';
import React,{ useState, useEffect, useMemo } from 'react'
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { Edit, Delete, Visibility, Add } from '@mui/icons-material';
import download from 'downloadjs'
import Head from '../Nav/Head';
 

function Support() {
  const [loader, setLoader] = useState(false);
  const [loaderDat, setLoadDat] = useState(false);
  const [loaderData, setLoadData] = useState(false);
  const [notifyAdd, setNotifyAdd] = useState();
  const [year, setYear] = useState('');
  const [title, setTitle] = useState('');
  const [alertUser, setAlertUser] = useState(null);
  const [supportData, setSupport] = useState(null);
  const [handleFile, setHandleFile] = useState('');
  const [dataID, setDataID] = useState();
  const [nameDoc, setNameDoc] = useState('');
  const [status, setStatus] = useState(false);
  const [notiDoc, setNotiDoc] = useState(null);
  const [allDoc, setAllDoc] = useState(null);
  const [mytit, setMytit] = useState(null);
  const [docuTitle, setDocuTitle] = useState();
  const [updateNameDoc, setUpdateNameDoc] = useState('');
  const [updateFile, setUpdateFile] = useState('');
  const [loaderDate, setLoaderDate] = useState(false);
  const [notiUpdate, setNotiUpdate] = useState(null);
  const [updateID, setUpdateID] = useState();
  const [notifyDele, setNotifyDele] = useState(null);
  const [addNewSupport, setAddNewSupport] = useState(false);
  const [openEditFile, setOpenEditFile] = useState(false);
  const [noticesDoc, setNoticeDoc] = useState(null);
  const [genID, setGenID] = useState();
  const [genName, setGenName] = useState();


  const columns = useMemo(
    () => [
      {
        accessorKey: 'yearid', //access nested data with dot notation
        header: 'Year',
        size: 150,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
      },
      {
        accessorKey: 'numdata', //normal accessorKey
        header: 'No. Data',
        size: 200,
      },
     
    ],
    [],
  );
 
 

  const yearsCatalog = [
      {yr:2020,id:'2020A'},
     {yr:2021,id:'2021B'},
     {yr:2022,id:'2022C'},
     {yr:2023,id:'2023D'},
     {yr:2024,id:'2024E'},
     {yr:2025,id:'2025F'},
     {yr:2026,id:'2026G'},
     {yr:2027,id:'2027H'},
     {yr:2028,id:'2028I'},
     {yr:2029,id:'2029I'},
     {yr:2030,id:'2030J'},
     {yr:2031,id:'2031K'},
     {yr:2032,id:'2032L'},
     {yr:2033,id:'2033M'},
     {yr:2034,id:'2034N'},
     {yr:2035,id:'20350'} 
 
 ]

 const taskDocument = (id,cate,title) =>{
                 setDocuTitle(title);
                 setUpdateID(id)
               switch(cate){
                    case 'delete':
                      axios.post('/delete-document-support',{
                         id:id,
                         genID:genID
                      },{
                       headers:{ 'Content-Type': 'application/json'}
                      }).then((res)=>{
                           if(res.data.removeStatus === false){
                               setNotifyDele(<div className="alert alert-info text-center">Unable to process your request</div>)
                           }else
                           {

                             //setNotifyDele(<div className="alert alert-info text-center">{res.data.removeStatus} </div>)
                             viewDocument(genID,genName)
                              // window.location.reload(false);
                           }
                      })
                      break;
                      case 'prev':

                       axios.post('/download-admin-support-dat',{
                         id:id
                     },{
                       responseType: 'blob'
                     }
                     
                     ).then((response)=>{
                       const content = response.headers['content-type'];
                       download(response.data, title+'.pdf', content)
                       
                       
                     })
                     
                        break;
                        case 'edit':
                      
                          break;
               }
 }

 const viewDocument = (id,name) =>{
             setStatus(true)
             setMytit(name)
             setGenID(id);
             setGenName(name);
           axios.get('/admin-getdocument-data/'+id,{
             headers:{ 'Content-Type': 'application/json'}
           }).then((res)=>{
                 if(res.data.documentData === false){
                       setNotiDoc(<div className="alert alert-info text-center">No data found</div>)
                        setAllDoc(null)
                 }else
                 {
                      setNotiDoc(null)
                     setAllDoc(res.data.documentData);
                 }
           })
 }

 const getDataDetails = () =>{
   setLoader(true);
   setAlertUser(null)
         axios.post('fetchall-support-data-years',{
             year:year,
             title:title
         },{
           headers:{ 'Content-Type': 'application/json'}
         }).then((res)=>{
                 setTimeout(()=>{
                   setLoader(false);
                   if(res.data.supportData === false){
                     setSupport(null)
                     setAlertUser(<div className='alert alert-info text-center'>No data found</div>)
                       
                    
                  }else
                  {
                       
                       setAlertUser(null)
                       setSupport(res.data.supportData);

                  }
                 },2000)
             
         })
 }

 const AddData = () => {
     setLoadData(true)
       if(title === ''){
         setNotifyAdd(<div className="alert alert-info text-center">Please all details must be fill</div>)
       }else
       {
     axios.post('/add-supportdata',{
         year:year,
         title:title
     },{
       headers:{ 'Content-Type': 'application/json'}
     }).then((res)=>{
         setTimeout(()=>{
           setLoadData(false)
           setNotifyAdd(<div className="alert alert-info text-center">{ res.data.status }</div>)
         },2000);
     })
   }
 }

 const AddDocument = () =>{
         setNotifyAdd(null);
        
       if(nameDoc === ''){
         setNotifyAdd(<div className="alert alert-info text-center">Document name is require</div>);
       }else{
         setLoadDat(true);
        const formData = new FormData();
        formData.append('docName',nameDoc);
        formData.append('file',handleFile);
        formData.append('ID',dataID);
       

        axios.post('/add-document-support',formData,{
          headers:{'Content-Type':'multipart/form-data'}
        }).then((res)=>{
           setTimeout(()=>{
             setLoadDat(false);
             setNotifyAdd(<div className="alert alert-info text-center">{res.data.report}</div>);
           },2000);
           
        });

       }
 }

 const updateDat = (id) => {
       setDataID(id);
       setAddNewSupport(true)

 }

 const UpdateAddDocument = () =>{
          setLoaderDate(true)

          const mform = new FormData();
              mform.append('title',updateNameDoc);
              mform.append('file',updateFile);
              mform.append('ID',updateID);

              axios.post('/update-support-document',mform,{
               headers:{ 'Content-Type': 'application/json'}
              }).then((res)=>{
                     setTimeout(()=>{
                       setLoaderDate(false);
                       setNotiUpdate(<div className="alert alert-info text-info">{ res.data.message }</div>);
                     },2000)
              })
 }


 const changeName = (id,name,year) =>{
         setUpdateNameDoc(name);
         setUpdateID(id);
         setYear(year)
         setOpenEditFile(true)


 }

 const removeSupport = (id) =>{
        axios.post('/remove-admin-support-data',{id:id},{
         headers:{ 'Content-Type': 'application/json'}
        }).then((res)=>{
             if(res.data.mstatus){
               getDataDetails();
             }
        })
 }

 const updateDocName = () =>{
           if(year === '' || updateNameDoc === ''){
                 setNoticeDoc(<div className='alert alert-info text-center'>Please make sure all field are fill completely</div>)
           }else{
           axios.post('/update-admin-docname',{updateNameDoc:updateNameDoc,id:updateID,year:year},{
             headers:{ 'Content-Type': 'application/json'}
           }).then((res)=>{
               setNoticeDoc(<div className='alert alert-info text-center'>{res.data.istatus}</div>);
           })
         }
 }

 const edittemplate = () =>{
     return (
       <div className="row">

<div className="col-lg-3">
       
       </div>  {/* end col */}
       <div className="col-lg-6">
         
         <div className="card">
           <div className="card-body">
             <h4 className="mb-3 header-title">Update Folder Name</h4>
               { noticesDoc === null ? null : noticesDoc }
             <div className="form-group">
                 <label htmlFor="exampleInputEmail1">Document Name</label>
                 <select className="form-control" id="exampleInputEmail1" onChange={(e)=> setYear(e.target.value)} value={updateNameDoc}>
                         <option value={year}>{year}</option>
                         <option value={2020}>2020</option>
                          <option value={2021}>2021</option>
                          <option value={2022}>2022</option>
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                          <option value={2031}>2031</option>
                   </select>

               </div>

               <div className="form-group">
                 <label htmlFor="exampleInputEmail1">Document Name</label>
                 <input type="email" className="form-control" id="exampleInputEmail1" onChange={(e)=> setUpdateNameDoc(e.target.value)} value={updateNameDoc} placeholder="" />

               </div>
               
              
               <button onClick={()=> updateDocName()} type="button" className="btn btn-primary waves-effect waves-light">Submit</button>  <button onClick={()=> setOpenEditFile(false)} style={{float:'right'}} type="submit" className="btn btn-primary waves-effect waves-light">Back</button>
           
           </div> {/* end card-body*/}
         </div> {/* end card*/}
       </div>
       {/* end col */}
     
     </div>
     )
 }

 
 return (
     <div id="wrapper">
       <Head />
          <div className="content-page">
             <div className="content">

                 <div class="container-fluid">


                 <div className="row">
     <div className="col-12">
       <div className="page-title-box">
         <div className="page-title-right">
         
         </div>
         <h4 className="page-title">Support</h4>
       </div>
     </div>
   </div>

   { notifyDele === null ? null : notifyDele}
   <div className="row">
           <div className="col-12">
             <div className="card-box">
               <div className="row">
                 <div className="col-lg-8">
                   <form className="form-inline">
                    
                     <div className="form-group mx-sm-3 ">
                      
                       <select className="custom-select " id="status-select" style={{width:150}} onChange={(e)=> setYear(e.target.value)}>
                         <option value=''>Year </option>
                           { yearsCatalog.map(y=>(
                               <option key={y.id} value={y.yr}>{ y.yr }</option>
                           ))}
                       </select>

                     
                       <button type="button" style={{marginLeft:10}} onClick={()=>getDataDetails()}  className="btn btn-primary waves-effect waves-light"><i className="mdi mdi-eye mr-1" /> View support Data</button>
                      
                       { loader !== false ?
                         <div class="d-flex" style={{marginLeft:10}}>
                                         <div class="spinner-border" role="status"></div>
                                     </div>
                                     : null }
                     </div>
                   </form>
                 </div>
                 <div className="col-lg-4">
                   <div className="text-lg-right mt-3 mt-lg-0">
                     
                     <button type="button" className="btn btn-success waves-effect waves-light" data-toggle="modal" data-target="#centermodal" data-backdrop="static"><i className="mdi mdi-plus-circle mr-1" /> Create Support Data</button>



                   </div>



                
                 </div>{/* end col*/}

                 <div className="col-lg-12">
                   <br></br>
                 { alertUser === null ? null : alertUser }
                   </div>
                
              
               </div> {/* end row */}
             </div> {/* end card-box */}
           
           
           </div> {/* end col*/}
         </div>
                         { supportData === null ? null :
         <div className="row">

             <div className="col-12">

               { addNewSupport !== false ? 
            
                   
            <div className="card">
            <div className="card-body">
            <button type="button" className="btn btn-secondary waves-effect pull-right" onClick={()=> setAddNewSupport(false)} style={{float:'right', clear:'both'}}>Back</button>

            <h4 className="header-title">Add Document</h4>
                 
                       <br></br>
                     { notifyAdd !== null ? notifyAdd : null }
       
       
                 <div className="row">
                   <div className="col-md-12">
                     <div className="form-group no-margin">
                       <label htmlFor="field-7" className="control-label">Name of document</label>
                       <input type="text" className="form-control" id="field-7" onChange={(e)=> setNameDoc(e.target.value)} />
                     </div>
                   </div>
                 </div>
               
           
                 <div className="row">
                   <div className="col-md-12">
                     <div className="form-group no-margin">
                       <label htmlFor="field-7" className="control-label">Upload Document</label>
                       <input type="file" className="form-control" id="field-7" onChange={(e)=> setHandleFile(e.target.files[0])} />
                     </div>
                   </div>
                 </div>
               
       
           
              
             
                
                 { loaderDat === false ?
                 <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=> AddDocument()} >Submit</button>
                 :
                 <button class="btn btn-primary" type="button" disabled>
                                                               <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                               Adding...
                                                           </button> 
       }
            
                </div>
                </div>
       
          :
             
           

             <div className="card">
                                      <div className="card-body">
                                        { openEditFile === false ? 
                                      <div className="mt-3">
     <h5 className="mb-3">{ mytit === null ? 'Support Data' : 'All document in '+mytit }</h5>
         { status !== false ? <button onClick={()=> setStatus(false)}  className="btn btn-blue waves-effect btn-sm waves-light">Back </button> : null}
     <div className="table-responsive">

                   
           { status === false ? 

                         supportData && <MaterialReactTable
                              columns={columns}
                              data={supportData}
                              enableRowActions
                              renderRowActionMenuItems={({ row }) => [
                                <MenuItem key="edit" onClick={() => viewDocument(row.original.id,row.original.name) }>
                                   <ListItemIcon>
                               <Visibility fontSize='small' />
                        </ListItemIcon>
                                  View
                                </MenuItem>,

                              <MenuItem key="edit" onClick={() =>updateDat(row.original.id) }>
                              <ListItemIcon>
                              <Add fontSize='small' />
                              </ListItemIcon>
                              Add Doc
                              </MenuItem>,

                            <MenuItem key="edit" onClick={() => changeName(row.original.id,row.original.name,row.original.yearid) }>
                            <ListItemIcon>
                            <Edit fontSize='small' />
                            </ListItemIcon>
                            Edit
                            </MenuItem>,

                                <MenuItem key="delete" onClick={() => removeSupport(row.original.id) }>
                                   <ListItemIcon>
                               <Delete fontSize='small' />
                              </ListItemIcon>
                                  Delete
                                </MenuItem>,
                              ]}
                              /> 
                           
     
         :

               allDoc === null ? notiDoc :
       
       
       <table className="table table-centered table-nowrap mb-0">
         <thead className="thead-light">
           <tr>
             <th className="border-0">Document Title</th>
             <th className="border-0">Size</th>
             <th className="border-0">Doc type </th>
             <th className="border-0">Date Modify </th>
             <th className="border-0" style={{width: '80px'}}>Action</th>
           </tr>
         </thead>
         <tbody>
               { allDoc && allDoc.map(({details,file_type,date_modify,filesize,id},index)=>{
                   return(
                     <tr key={index}>
                         <td>{ details }</td>
                         <td>{ filesize }</td>
                         <td>{ file_type }</td>
                         <td>{ date_modify }</td>
                         <td>   <button onClick={()=>taskDocument(id,'prev',details)}  className="btn btn-blue waves-effect btn-sm waves-light" type="button"> <i className="mdi mdi-eye" /> Preview Doc</button>
                         &nbsp;&nbsp;&nbsp;
                         <button  onClick={()=> taskDocument(id,'edit',details)} className="btn btn-info waves-effect btn-sm waves-light" type="button" data-toggle="modal" data-target="#updatedocument" data-backdrop="static"> <i className="mdi mdi-pencil" /> Edit Doc Title</button>
                         &nbsp;&nbsp;&nbsp;
                         <button onClick={()=>taskDocument(id,'delete',details)}  className="btn btn-danger waves-effect btn-sm waves-light" type="button">  Delete</button>
                  </td>
                     </tr>
                   )
               })}

           </tbody>
       </table>
     

             }


     </div>
                                      </div> : 
                                       edittemplate()
                                      }
                              
                                      </div> 
                                  </div> 

}

             </div>


         </div>
        
        }

     
             
        <div className="modal fade" id="updatedocument" tabIndex={-1} role="dialog" aria-hidden="true">
     <div className="modal-dialog modal-dialog-centered">
       <div className="modal-content">
         <div className="modal-header">
           <h4 className="modal-title" id="myCenterModalLabel">Add Document</h4>
           <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
         </div>
         <div className="modal-body p-4">

           { notiUpdate !== null ? notiUpdate : null }


       <div className="row">
         <div className="col-md-12">
           <div className="form-group no-margin">
             <label htmlFor="field-7" className="control-label">Name of document</label>
             <input type="text" className="form-control" id="field-7" defaultValue={docuTitle} onChange={(e)=> setUpdateNameDoc(e.target.value)} />
           </div>
         </div>
       </div>
     
 
       <div className="row">
         <div className="col-md-12">
           <div className="form-group no-margin">
             <label htmlFor="field-7" className="control-label">Upload Document</label><br />
             <small>Please leave blank if you don't want to update the document</small>
             <input type="file" className="form-control" id="field-7" onChange={(e)=> setUpdateFile(e.target.files[0])} />
           </div>
         </div>
       </div>
     

     </div>
     <div className="modal-footer">
       <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
       { loaderDate === false ?
       <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=> UpdateAddDocument()} >Submit</button>
       :
       <button class="btn btn-primary" type="button" disabled>
                                                     <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                     Adding...
                                                 </button> 
}
     </div>
       </div>{/* /.modal-content */}
     </div>{/* /.modal-dialog */}
   </div>{/* /.modal */}

             
         <div className="modal fade" id="centermodal" tabIndex={-1} role="dialog" aria-hidden="true">
     <div className="modal-dialog modal-dialog-centered">
       <div className="modal-content">
         <div className="modal-header">
           <h4 className="modal-title" id="myCenterModalLabel">Add New Support Data</h4>
           <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
         </div>
         <div className="modal-body p-4">

           { notifyAdd !== null ? notifyAdd : null }

      

       <div className="row">
         <div className="col-md-12">
           <div className="form-group">
             <label htmlFor="field-3" className="control-label">Select Year</label>
             <select className="custom-select " id="status-select" onChange={(e)=>setYear(e.target.value)}>
             { yearsCatalog.map(y=>(
                               <option key={y.id} value={y.yr}>{ y.yr }</option>
                           ))}
                       </select>
           </div>
         </div>
       </div>
             
      
       <div className="row">
         <div className="col-md-12">
           <div className="form-group no-margin">
             <label htmlFor="field-7" className="control-label">Data title folder</label>
             <input type="text" className="form-control" id="field-7" placeholder="Your title" multiple onChange={(e)=> setTitle(e.target.value)}  />
           </div>
         </div>
       </div>
     

     </div>
     <div className="modal-footer">
       <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
       { loaderData === false ?
       <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=>AddData()} >Submit</button>
       :
       <button class="btn btn-primary" type="button" disabled>
                                                     <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                     Loading...
                                                 </button> 
}
     </div>
       </div>{/* /.modal-content */}
     </div>{/* /.modal-dialog */}
   </div>{/* /.modal */}

               
                 </div>
               </div>
           </div>
         
     </div>
 )
}

export default Support
