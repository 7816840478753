import React,{useMemo, useEffect, useState} from 'react'
import axios from 'axios'
import {useHistory} from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { AccountCircle, Delete, Edit } from '@mui/icons-material';
import {handleLoad, NotifyUsers, CaptureRating } from '../auth/ErrorHandler'
import Head from '../Nav/Head';

function CaptureQuestion() {
     const [loading, setLoading] = useState(false);
     const [stud, setStud] = useState([]);
     const [studData, setStudData] = useState();
     const [viewCapture, setViewCapture] = useState(false)
     const [hideshow, setHideShow] = useState(false);
     const [subject, setSubject] = useState([]);
     const [loadBut, setLoadBut] = useState(false);
     const [cate, setCate] = useState('');
     const [sub, setSub] = useState('');
     const [year, setYear] = useState('');
     const [term, setTerm] = useState('');
     const [notify, setNotify] = useState(null);
     const [captureData, setCaptureData] = useState(null);
     const [score, setScore] = useState('');
     const [notis, setNotis] = useState(null);
     const [currScore, setCurrScore] = useState(null);
     const [assCate, setAssCate] = useState([]);

     const NotifyMe = (type,message) =>{
        const datas = {type,message}
         return (
            <NotifyUsers datas={datas} />
         )
    }

    const scoreRate = (type,score) =>{
          const datas = {type,score}
          return(
            <>
            <CaptureRating mdatas={datas} />
            </>
          )
    }

    const ACat = (datas,tm) =>{
      let ms;
       switch(tm){
          case "1":
            ms = datas.term1
          break;
          case "2":
            ms = datas.term2
          break;
          case "3":
            ms = datas.term3
            break;
          case "4":
            ms = datas.term4
            break;
       }

                let sd = ms.split(',');
               setAssCate(sd);

}


const getAssessmentCate = (tm) => {
        setTerm(tm)
      if(!tm.trim()){}else{
      axios.post('/get-assess-cate',{tm},{
         headers:{'Content-Type' : 'application/json'}
      }).then((res)=>{
            if(res.data.termData === 'empty'){
              setAssCate([]);
            }else
            {
                 ACat(res.data.termData[0],tm)
              // console.log(res.data.termData[0].term1)
            
            }
          
      }).catch((err)=>{
         console.log(err)
      })
    }
}


    const handleCaptureScore = () =>{
            if(!score.trim()){
               setNotis(NotifyMe('danger','Please select the score rating'))
            }else
            {
                  setCurrScore(score)
                  setLoadBut(true)
                 let did = captureData.downloadID;
                 let studID = studData.stud_userid;
                 let grade = studData.grade;
                const dat = {score,did,term,grade,studID}
                axios.post('/api-adminCaptureUsers',dat,{
                  headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                      setTimeout(()=>{
                        setLoadBut(false)
                        setNotis(NotifyMe(res.data.color,res.data.message));
                      },1000)
                    
                }).catch((err)=>{
                    console.log(err)
                })
            }
    }

     const handleViewCapture = () => {
                setNotify(null)
            if(!cate.trim() || !sub.trim() || !year.trim() || !term.trim()){
                    setNotify(NotifyMe('danger','Please make sure your select all the category'))
            }else
            {
                 setLoadBut(true)
                  let studID = studData.stud_userid;
                  let grade = studData.grade;
                   let type = 'capture';
                  const datas = {cate,sub,year,studID,grade,term,type}
                  axios.post('/api-adminfetchcapture',datas,{
                     headers:{'Content-Type':'application/json'}
                  }).then((res)=>{
                            setTimeout(()=>{
                                  if(res.data.status === 'allow'){
                                      
                                        setCaptureData(res.data.captureData);
                                  }else
                                  {
                                        setCaptureData(null)
                                      setNotify(NotifyMe(res.data.color,res.data.message))
                                  }
                                 setLoadBut(false)
                            },1000)
                  }).catch((err)=>{
                        console.log(err)
                  })
            }
     }


     const CaptureStudent = () => {

         return (
            <>
              <div className="col-lg-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-lg-12">
                         { notify !== null ? notify : null }
                      <form className="form-inline">
                       
                        <div className="form-group mx-sm-3 ">


                        <select className="custom-select " onChange={(e)=> setYear(e.target.value)} id="status-select" >
                          <option value=''>Year</option>
                             
                          <option value={2020}>2020</option>
                          <option value={2021}>2021</option>
                          <option value={2022}>2022</option>
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                          <option value={2031}>2031</option>
                          </select>

                        <select className="custom-select " id="status-select" onChange={(e)=> setSub(e.target.value)} style={{marginLeft:20}}>
                          <option value=''>Subject</option>
                          { subject.length > 0 ? subject && subject.map((s)=>{
                              return (
                                 <option value={s.subject_name+'=>'+s.subject_id}>{s.subject_name}</option>
                              )
                          }) : <option disabled>Not Available</option>}
                          </select>



                        <select className="custom-select" onChange={(e)=> getAssessmentCate(e.target.value)} id="status-select" style={{marginLeft:20}} >
                            <option value=''> Term</option>
                            <option value={1}> Term 1</option>
                            <option value={2}> Term 2</option>
                            <option value={3}> Term 3</option>
                            <option value={4}> Term 4</option>
                         
                          </select>

                        <select className="custom-select form-control" onChange={(e)=> setCate(e.target.value)} id="status-select" style={{marginLeft:20}}>
                            <option value=''> Category</option>
                                           { assCate.length > 0 ? assCate && assCate.map((c,i)=>{
                                                            return(
                                                              <option key={i} value={c}>{c}</option>
                                                            )
                                                     }) : <option disabled>Not available</option> }
                                                  
                         
                          </select>





                        
                            { loadBut === false ? 
                          <button type="button" style={{marginLeft:10}} onClick={()=> handleViewCapture()} className="btn btn-primary waves-effect waves-light" ><i className="mdi mdi-eye mr-1" /> view to capture</button>
                                : 
                                <button type="button" style={{marginLeft:10}} className="btn btn-primary waves-effect waves-light" disabled><i className="mdi mdi-eye mr-1" /> Please wait...</button>
                            }
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-2">
                      <div className="text-lg-right mt-3 mt-lg-0">
                        
                        
                      </div>


                   
                    </div>
                  </div> 
                </div> 
              </div> 

              
              <div className='col-lg-12'>
                { captureData !== null ?
                <div className='card'>
                    <div className='card-body'>
                        <h5>{ studData.names }</h5>
                        <hr />
                     
                    <div className="table-responsive">
                    <table className="table mb-0">
                                 <thead className="thead-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Subject Name</th>
                                                <th>Grade</th>
                                                <th>Term</th>
                                                <th>Score</th>
                                                <th>Score Rating</th>
                                                <th>Score Percentage</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <td>1</td>
                                                    <td>{captureData.subjectName}</td>
                                                    <td>{captureData.grade}</td>
                                                    <td>{captureData.term}</td>
                                                    <td>{ currScore === null ? captureData.score :  currScore }</td>
                                                    <td>{ currScore === null ? scoreRate('rate',captureData.score) :  scoreRate('rate',currScore) }</td>
                                                    <td>{ currScore === null ? scoreRate('percent',captureData.score) :  scoreRate('percent',currScore) }</td>
                                                    <td>
                                                   
                                                      <button className='btn btn-xs btn-blue waves-effect waves-light' data-toggle="modal" data-target="#centermodal">
                                                        { captureData.cap_status === 'new' ? 
                                                        'Capture Assessment' : 'Update Capture' }
                                                        
                                                        </button>
                                                
                                                      </td>
                                                </tr>
                                            </tbody>
                        </table>

                        <div className="modal fade" id="centermodal" tabindex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title" id="myCenterModalLabel">Capture</h4>
                                                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <h5>Capture { studData.names } Assessment</h5>

                                                        <div className='row'>
                                                            <div className='col-lg-12'>
                                                                { notis === null ? null : notis }
                                                               <select className='form-control custom-select mb-4' onChange={(e)=>setScore(e.target.value)}>
                                                                 { captureData.score === null ? <option defaultValue={''}>Select Score</option> : 
                                                                 <option defaultValue={captureData.score}>{captureData.score}</option>}
                                                               <option defaultValue={1}>1</option>
                                                            <option defaultValue={2}>2</option>
                                                            <option defaultValue={3}>3</option>
                                                            <option defaultValue={4}>4</option>
                                                            <option defaultValue={5}>5</option>
                                                            <option defaultValue={6}>6</option>
                                                            <option defaultValue={7}>7</option>
                                                               </select>

                                                               <div className='text-center'>

                                                            { loadBut === false ?
                                                         <button onClick={()=> handleCaptureScore()} className='btn btn-sm btn-blue'>Capture</button>
                                                         :    <button className='btn btn-sm btn-blue' disabled>Please wait...</button>
                                                            }
                                                        
                                                         </div>
                                                              </div>
                                                          </div>
                                                       
                                                   
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                     </div>
                  
                    </div>
                </div> : null }

              </div>
              </>
         
         )

     }
  
     const viewStudent = (studID) =>{ 
        axios.get('/get-admin-student/'+studID,{
            headers:{'Content-Type':'application/json'}
       }).then((res)=>{
               setTimeout(()=>{
                    setStudData(res.data.stud[0])
                    setSubject(res.data.subData);
                     setViewCapture(true)
                     setHideShow(true)
               },500)
       }).catch((err)=>{
           console.log(err)
       })
     }

     const columns = useMemo(
        () => [
          {
            accessorKey: 'names', //access nested data with dot notation
            header: 'Full Name',
            size: 150,
          },
          {
            accessorKey: 'term',
            header: 'Current Term',
            size: 150,
          },
          {
            accessorKey: 'grade',
            header: 'Grade',
            size: 150,
          },
          {
            accessorKey: 'academic', //normal accessorKey
            header: 'Academic Year',
            size: 200,
          },
          {
            accessorKey: 'centre',
            header: 'Centre',
            size: 150,
          },
          {
            accessorKey: 'profile',
            header: 'Profile Status',
            size: 150,
          },
          {
            accessorKey: 'due_date',
            header: 'Due Date',
            size: 150,
          },
        ],
        [],
      );
  

    useEffect(()=>{
        axios.get('/get-admin-student/all',{
             headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                setTimeout(()=>{
                     setStud(res.data.stud)
                     setLoading(true)
                },1000)
        }).catch((err)=>{
            console.log(err)
        })

    },[])
  return (
    <div id="wrapper">
    <Head />
    <div className="content-page">
 <div className="content">
 <div className="container-fluid">
    { loading === false ? <p>Loading Content. Please wait...</p> :

                        <>
                         <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                 
                   
                 
                  </div>
                </div>
               
              </form>
            </div>
            { viewCapture === false ?
            <h4 className="page-title">Select the Student you want to capture</h4>
            :  <h4 className="page-title">Capture Assessment for { studData.names }</h4> }
          </div>
        </div>
                 </div>
                    <div className='row'>
                            { viewCapture === false ? null : 
                              CaptureStudent()
                            }
                        
                            { hideshow === false ?
                        <div className='col-lg-12'>
                    <MaterialReactTable columns={columns} data={stud}
                    enableRowActions
                    renderRowActionMenuItems={({ row }) => [
                    <MenuItem key="edit" onClick={() => viewStudent(row.original.studID)}>
                        <ListItemIcon>
                    <AccountCircle fontSize='small' />
                    </ListItemIcon>
                        View to Capture
                    </MenuItem>,
                   
                    ]}
                    /></div> : null }
                    
                    </div></> }
    
    


    </div>
    </div>
    </div>
    </div>
  )
}

export default CaptureQuestion