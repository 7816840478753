import React,{useState, useEffect} from 'react'
import axios from 'axios'
import Head from '../Nav/Head'

function Dashboard() {

    const [stud, setStud] = useState([]);
    const [centre, setCentre] = useState([]);
    const [parent, setParent] = useState([]);
    const [tutor, setTutor] = useState([]);
    const [currTerm, setCurrTerm] = useState([]);
    const [reload, setReload] = useState(false);
    const [notify, setNotify] = useState(null);
    const [allUser, setAllUser] = useState([]);
    const [nUser, setNUser] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [mNotis, setMNotis] = useState(null);
    const [nload, setNload] = useState(false);
    const [bankName, setBankName] = useState('');
    const [accName, setAccName] = useState('');
    const [accNumber, setAccNumber] = useState('');
    const [branchCode, setBranchCode] = useState('');
    const [bid, setBid] = useState();
    const [lodbnk, setLodBnk] = useState(false);
    const [notifyBnk, setNotifyBnk] = useState(null)


    const UpdateAdminBank = () =>{
              if(!bankName.trim() || !accName.trim() || !accNumber.trim()){
                   setNotifyBnk(<div className='alert alert-danger text-center'>Please check if some input are empty</div>)
              }else{
       
            let datas = {bid, branchCode, accName, accNumber, bankName}
              setLodBnk(true);
          axios.post('/api-update-adminaccountdatas',datas,{
             headers:{'Content-Type':'application/json'}
          }).then((res)=>{
                setTimeout(()=>{
                  setLodBnk(false)
                  setNotifyBnk(<div className={`alert alert-${res.data.color} text-center`}>{res.data.message}</div>)
                 
                },1000);
               
          }).catch(err=>{

          });

        }
    }

    const sendNotis = () => {
            setMNotis(null)
          if(!nUser.trim() || !title.trim() || !message.trim()){
                setMNotis(<div className='alert alert-danger text-center'>Please check if some form input are empty</div>)
          }else
          {
              setNload(true)
             axios.post('/api-admin-send-notis',{userid:nUser,title,message},{
               headers:{'Content-Type':'application/json'}
             }).then((res)=>{
                setTimeout(()=>{
                  setMNotis(<div className={`alert alert-${res.data.color} text-center`}>{res.data.message}</div>)
                  setNload(false)
                },1000)
             
             }).catch((err)=>{
                console.log(err)
             })
          }
    }


     const getCurrentTerm = (term) =>{
        if(term === ''){
          setNotify(<div className={`alert alert-danger text-center`}>Please select current default term</div>)
        }else{
            setNotify(null)
            setReload(true);
          axios.post('/api-updateterm-admin',{term},{
             headers:{'Content-Type':'application/json'}
          }).then((res)=>{
              setTimeout(()=>{
                   setNotify(<div className={`alert alert-${res.data.color} text-center`}>{res.data.message}</div>)
                  setReload(false)
              },1000)
          }).catch(err=>{
              console.log(err)
          })
        }
     }

   useEffect(()=>{

        axios.get('/api-admin-fetchnumbers',{
           headers:{'Content-Type':'application/json'}
        }).then((res)=>{
              setStud(res.data.student)
              setCentre(res.data.centre)
              setParent(res.data.parent)
              setTutor(res.data.tutor)
              setCurrTerm(res.data.cterm[0].term);
              setAllUser(res.data.alluser)
                 
                 if(res.data.banks !== 'empty'){

                  let bank = res.data.banks[0];
          
                      setBankName(bank.bank_name)
                      setAccName(bank.account_name)
                      setBranchCode(bank.branch_code)
                      setAccNumber(bank.account_number)
                      setBid(bank.admin_id)

                 }else
                 {

                  setBankName('')
                  setAccName('')
                  setBranchCode('')
                  setAccNumber('')
                  setBid('')

                 }
              
        }).catch((err)=>{
            console.log(err)
        })
   },[reload])

  return (
    <div id="wrapper">
       <Head />
       <div className="content-page">
    <div className="content">
    <div className="container-fluid">


      <div className='row'>

      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                 
                   
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Dashboard</h4>
          </div>
        </div>
           </div>


           


      </div>



      <div className="row">
                            <div className="col-md-6 col-xl-3">
                                <div className="card-box">
                                    <i className="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i>
                                    <h4 className="mt-0 font-16">Total Parent</h4>
                                    <h2 className="text-primary my-3 text-center"><span data-plugin="counterup">{ parent.length }</span></h2>
                                    <p className="text-muted mb-0">Total income: $22506 <span className="float-right"><i className="fa fa-caret-up text-success mr-1"></i>10.25%</span></p>
                                </div>
                            </div>

                            <div className="col-md-6 col-xl-3">
                                <div className="card-box">
                                    <i className="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i>
                                    <h4 className="mt-0 font-16">Total Tutor</h4>
                                    <h2 className="text-primary my-3 text-center"><span data-plugin="counterup">{ tutor.length }</span></h2>
                                    <p className="text-muted mb-0">Total sales: 2398 <span className="float-right"><i className="fa fa-caret-down text-danger mr-1"></i>7.85%</span></p>
                                </div>
                            </div>

                            <div className="col-md-6 col-xl-3">
                                <div className="card-box">
                                    <i className="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i>
                                    <h4 className="mt-0 font-16">Total Student</h4>
                                    <h2 className="text-primary my-3 text-center"><span data-plugin="counterup">{ stud.length }</span></h2>
                                    <p className="text-muted mb-0">Total users: 121 M <span className="float-right"><i className="fa fa-caret-up text-success mr-1"></i>3.64%</span></p>
                                </div>
                            </div>

                            <div className="col-md-6 col-xl-3">
                                <div className="card-box">
                                    <i className="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i>
                                    <h4 className="mt-0 font-16">Total Centre</h4>
                                    <h2 className="text-primary my-3 text-center"><span data-plugin="counterup">{ centre.length }</span></h2>
                                    <p className="text-muted mb-0">Total revenue: $1.2 M <span className="float-right"><i className="fa fa-caret-up text-success mr-1"></i>17.48%</span></p>
                                </div>
                            </div>
        </div>

        <div className='row'>

          <div className='col-xl-6'>
          <div className='card'>
              <div className='card-body'>
              <h4>Update Current Term</h4>
              { currTerm.length > 0 ? 
                <table className='table bg-blue text-white'>
                  <tbody>
                    <th>Default Current Term</th>
                    <td> Term {currTerm}</td>
                  </tbody>
                </table> : <div className='alert alert-info text-center'>PLease update current term </div> }

                { notify }

                <div className='mt-2'>
                  <label>Update current Term</label>
                  <select className='custom-select' onChange={(e)=> getCurrentTerm(e.target.value)}>
                    <option value={''}>Select Current Term</option>
                    <option value={1}>Term 1</option>
                    <option value={2}>Term 2</option>
                    <option value={3}>Term 3</option>
                    <option value={4}>Term 4</option>
                    
                  </select>
                  { reload !== false ? <i className='text-danger mt-1'>Updating... Please wait</i> : null }
                </div>
                
              </div>
            </div>

            <div className='card'>
              <div className='card-body'>
              <h4>Send notification</h4>
                      {mNotis}
                 <div className='mt-2 mb-2'>
                  <label>Select Users</label>
                  <select className='custom-select' onChange={(e)=> setNUser(e.target.value)} >
                    <option value={''}>Select Users</option>
                    { allUser.length > 0 ? allUser &&
                     allUser.map((u,i)=>{
                          return(
                              <option key={i} value={u.userid}>{u.names +' - '+u.user_type}</option>
                          )
                    }) : <option disabled>No Users</option> }
                    
                  </select>
                </div>

                <div className='mb-2'>
                  <label>Title</label>
                  <input type='text' className='form-control' onChange={(e)=> setTitle(e.target.value)} placeholder='title' />
                </div>

                <div className='mb-3'>
                   <label>Message here</label>
                  <textarea type='text' className='form-control' placeholder='Message' onChange={(e)=> setMessage(e.target.value)} ></textarea>
                </div>
                <div className='text-center'>
                { nload === false ?
                <button className='btn btn-blue' onClick={()=> sendNotis()}>Send Notification</button>
                :
                <i className='text-danger'>Sending. Please wait...</i>
                  }
                </div>
                
                
              </div>
            </div>
          </div>
            
 
          <div className='col-xl-6'>
          <div className='card'>
              <div className='card-body'>
              <h4>Update Bank Account</h4>
                      {notifyBnk}
                 <div className='mt-2 mb-2'>
                  <label>Bank Name</label>
                  <input type='text' className='form-control' onChange={(e)=> setBankName(e.target.value)} defaultValue={bankName} />
                </div>

                <div className='mb-2'>
                  <label>Account Name</label>
                  <input type='text' className='form-control' onChange={(e)=> setAccName(e.target.value)} defaultValue={accName}  />
                </div>

                <div className='mb-3'>
                   <label>Account Number</label>
                   <input type='text' className='form-control' onChange={(e)=> setAccNumber(e.target.value)} defaultValue={accNumber}  />
               
                </div>

                <div className='mb-3'>
                   <label>Bank Branch Code</label>
                   <input type='text' className='form-control' onChange={(e)=> setBranchCode(e.target.value)} defaultValue={branchCode} />
               
                </div>

                <div className='text-center'>
                { lodbnk === false ?
                <button className='btn btn-blue' onClick={()=> UpdateAdminBank()}>Update Bank Account</button>
                :
                <i className='text-danger'>Updating. Please wait...</i>
                  }
                </div>
                
                
              </div>
            </div>
          </div>

          
            
        </div>


      </div>
    </div> 
  </div>
</div>
  )
}

export default Dashboard