import React,{useState, useEffect, useMemo} from 'react'
import axios from 'axios'
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { List } from '@mui/icons-material';
import Head from '../Nav/Head';
import {HandleLoad, NotifyUsers } from '../auth/ErrorHandler'


function GenerateInvoice() {
     const [userData, setUserData] = useState([]);
     const [gStatus, setGStatus] = useState(false);
     const [pid, setPid] = useState();
     const [loading, setLoading] = useState(false);
     const [stud, setStud] = useState([]);
     const [sid, setSid] = useState();
     const [duration, setDuration] = useState();
     const [showLoad, setShowLoad] = useState(false)
     const [pName, setPName] = useState();
     const [showDuration, setShowDuration] = useState(false);
     const [invoiceType, setInvoiceType] = useState('');
     const [dispForm, setDispForm] = useState(false);
     const [notify, setNotify] = useState(null);
     const [totalFee, setTotalFee] = useState();
     const [amtPay, setAmtPay] = useState('');
     const [purpose, setPurpose] = useState('');
     const [yrApply, setYrApply] = useState('');
     const [inOperation, setInOperation] = useState('');
     const [subDuration, setSubDuration] = useState('');
     const [loadBut, setLoadBut] = useState(false)

     const validDate = (type) => {
      let valid = true;

         switch(type){
           case 'Registration':
            if(!amtPay.trim() || !purpose.trim() || !yrApply.trim() || !inOperation.trim() || !subDuration.trim()){
              valid = false
              }
            break;
            case 'Additional-subject':
                if(!amtPay.trim() || !purpose.trim() || !inOperation.trim()){
                      valid = false
                }
              break;
              default:
                 valid = false
         }
        
         return valid;
}
   
     const NotifyMe = (type,message) =>{
        const datas = {type,message}
         return (
            <NotifyUsers datas={datas} />
         )
    }

    const CheckInvoiceType = (ivtype) =>{
                  setInvoiceType(ivtype)
              if(ivtype === 'Registration'){
                   setShowDuration(true)
                   setAmtPay(totalFee)
              }else
              {
                setShowDuration(false);
                setAmtPay('')
              }
    }



    const handleGenerateInvoice = () =>{
            setNotify(null)
            if(!invoiceType.trim()){
                setNotify(NotifyMe('danger','Please select invoice type'))
            }else
            {
            
              if(!validDate(invoiceType)){
                setNotify(NotifyMe('danger','Please make sure all form input are not empty'))
              }else
              {
                setShowLoad(false);
                setLoadBut(true)
                  let datas = {invoiceType,amtPay,purpose,yrApply,inOperation,subDuration,sid,pid}
                axios.post('/api-generate-invoice',datas,{
                   headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                      setTimeout(()=>{
                        setShowLoad(false)
                        setLoadBut(false)
                        setNotify(NotifyMe(res.data.color,res.data.message))
                      },1000)
                     
                }).catch((err)=>{
                    console.log(err)
                })

              }
               
                 
            }
    }


     const getPID = (id,names) =>{
           setPid(id)
            setPName(names)
          axios.get('api-invoiceadmin-student/'+id,{
             headers:{'Content-Type':'application/json'}
          }).then((res)=>{
               setTimeout(()=>{
                
                   setStud(res.data.studData);
                   setGStatus(true);
                  
               },500)
          }).catch(err=>{
              console.log(err)
          })
     }

     const columns = useMemo(
      () => [
        {
          accessorKey: 'names', //access nested data with dot notation
          header: 'Full Name',
          size: 150,
        },
        {
          accessorKey: 'email',
          header: 'Email',
          size: 150,
        },
        {
          accessorKey: 'phone', //normal accessorKey
          header: 'Phone',
          size: 200,
        },
        {
          accessorKey: 'numStud',
          header: 'Number of Learner',
          size: 150,
        },
        {
          accessorKey: 'userid',
          header: 'User ID',
          size: 150,
        },
      ],
      [],
    );

    const handlePrice = (sid,grade,fee) =>{
           setSid(sid);
            setShowLoad(true);
            setTotalFee(fee)
           axios.get('/api-adminfetch-termsgrade/'+grade,{
             headers:{'Content-Type':'application/json'}
           }).then((res)=>{
                setTimeout(()=>{
                  
                    setDuration(res.data.feeDatas[0]);
                    setShowLoad(false);
                    setDispForm(true)
                    
                },1000)
           }).catch((err)=>{
                console.log(err)
           })
            
    }


    useEffect(()=>{
            axios.get('/api-getall-users/parent',{
                headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                    setUserData(res.data.udatas);
                    setLoading(true)
                
            }).catch((err)=>{
                 console.log(err)
            })

    },[])

  return (
  
    <div id="wrapper">
    <Head />
    <div className="content-page">
 <div className="content">
 <div className="container-fluid">

 <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                 
                   
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Select the user you want to generate invoice</h4>
          </div>
        </div>
                 </div>



               <div className='row'>

                    { gStatus !== false ?
                     <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                    <div className='row'>
                                    <div className='col-lg-12'>

                                      <button className='btn btn-blue mb-4' onClick={()=> setGStatus(false)}>Back to all users</button>
                                       
                                      </div>
                                        <div className='col-lg-1'>
                                            </div>
                                            <div className='col-lg-10'>

                                                <div className='card border border-success' style={{ padding:10 }}>

                                                  { notify !== null ? notify : null }
                                              
                                                <div className="card-header bg-transparent border-success">
                                        <h5 className="my-0 text-danger">Generating Invoice to {pName}</h5>
                                        <div style={{ float:'right',marginTop:-40 }}>
                                                { showLoad === false ? null : <HandleLoad datas={`text-danger`} />} 
                                                </div>
                                           </div>

                                            <div className="table-responsive">
                                          { stud.length > 0 ?  <table className="table table-striped mb-0">
                                            <thead>
                                            <tr className="">
                                                <th>#</th>
                                                <th>Learners Name</th>
                                                <th>Grade</th>
                                                <th>Gender</th>
                                                <th>Money prervously paid</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                { stud && stud.map((s,i)=>{
                                                      return(
                                                         <tr key={i}>
                                                            <td>{i+1}</td>
                                                            <td>{s.names}</td>
                                                            <td>{s.grade}</td>
                                                            <td>{s.gender}</td>
                                                            <td>{s.total_fee}</td>
                                                            <td>
                                                              
                                                         
                                                        <input type="radio" name="radio" id={`radio${i}`} onClick={()=>handlePrice(s.stud_userid,s.grade,s.total_fee)} value="option4" />
                                                     
                                                              
                                                              </td>
                                                         </tr>
                                                      )
                                                }) }
                                            
                                            </tbody>

                                            </table>: 
                                                NotifyMe('info','This parent has no learner')
                                                }
                                                { dispForm }
                                            { dispForm !== false ? 
                                            <>

                                                <div className='row mt-2'>
                                                  <div className='col-lg-12 mb-3'>
                                                   <label>Invoice Type</label>
                                                  <select className='custom-select' onChange={(e)=> CheckInvoiceType(e.target.value) }>
                                                    <option value={''}>Select Option</option>
                                                    <option value={'Registration'}>Registration</option>
                                                    <option value={'Additional-subject'}>Addition of Subject</option>
                                                  </select>
                                                  </div>

                                                 
                                                  <div className='col-lg-12'>
                                                   <label>Purpose of this invoice</label>
                                                   <textarea className='form-control' onChange={(e)=> setPurpose(e.target.value)} placeholder='Purpose of this invoice'></textarea>
                                                  </div>

                                                </div>


                                                { showDuration === false ? null :
                                                <div className='mt-3'>
                                                <h4>Select Price {subDuration}</h4>
                                                <table className="table table-striped mb-0">
                                                    <tbody>
                                                      <tr>
                                                        <th>Termly</th>
                                                        <td>{ duration.term }</td>
                                                        <td><input type="radio" name="radio" id={`radio1`} value="option1" onClick={()=> setSubDuration('Termly')} />
                                                     </td>
                                                      </tr>
                                                      <tr>
                                                        <th>Yearly</th>
                                                        <td>{ duration.year }</td>

                                                        <td><input type="radio" name="radio" id={`radio2`} value="option2" onClick={()=> setSubDuration('Yearly')} />
                                                     </td>
                                                      </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                                }
                                          <div className='row mt-4'>
                                            <div className='col-lg-12 mb-3'>
                                                <label>Total Amount To pay</label>
                                                <input type='text' className='form-control' onChange={(e)=> setAmtPay(e.target.value)} defaultValue={amtPay} />
                                                <small className='text-danger'>Note: If you're generating invoice for a user, you can adjust the amount to fit the user requirement</small>
                                              </div>
                                                { showDuration === false ? null :
                                              <div className='col-lg-12'>
                                                <label>Select Academic Year</label>
                                              <select type='text' className='custom-select' onChange={(e)=>setYrApply(e.target.value)}>
                                                 <option value={''}>Select Option</option>
                                                 <option value={2022}>2022</option>
                                                 <option value={2023}>2023</option>
                                                 <option value={2024}>2024</option>
                                                 <option value={2025}>2025</option>
                                                 <option value={2026}>2026</option>
                                                 <option value={2027}>2027</option>
                                                 <option value={2028}>2028</option>
                                                 <option value={2029}>2029</option>
                                                 <option value={2030}>2030</option>
                                                 <option value={2031}>2031</option>
                                                 <option value={2032}>2032</option>
                                                </select>
                                                </div> }
                                            </div> 

                                            <div className='row mt-2'>
                                           
                                              <div className='col-lg-12 mb-4'>
                                                <label>I want to</label>
                                              <select type='text' className='custom-select' onChange={(e)=> setInOperation(e.target.value)}>
                                                 <option value={''}>Select Option</option>
                                                 <option value={'debit'}>Debit {pName} from his/her wallet</option>
                                                 <option value={'allow-to-pay'}>Allow {pName} to pay for this invoice</option>
                                                 <option value={'already-paid'}>{pName} has been debited already but no invoice</option>
                                                </select>
                                                </div>


                                                <div className='col-lg-12 text-center mt-4 mb-4'>
                                                   { loadBut === false ?
                                                    <button className='btn btn-blue' onClick={()=>handleGenerateInvoice()}>Generate Invoice</button>
                                                    :  <button className='btn btn-blue' disabled>Please wait...</button> }
                                                  </div>
                                            </div>
                                           
                                            </> : null }
                                              

                                                </div>
                                                </div>

                                            </div>
                                     </div>
                                </div>
                            </div>
                     </div> 
                     
                     : 

                  <div className='col-lg-12'>

                {loading === false ? <p>Loading Content. Please wait...</p> : <MaterialReactTable columns={columns} data={userData}
                enableRowActions
                renderRowActionMenuItems={({ row }) => [
                  <MenuItem key="edit" onClick={() => getPID(row.original.userid,row.original.names)}>
                     <ListItemIcon>
                 <List fontSize='small' />
          </ListItemIcon>
                    Generate
                  </MenuItem>
                ]}
                /> }

                    </div>
                        }


                  </div>
               </div>
             </div>
         </div>
    </div>
       
            
  )
}

export default GenerateInvoice