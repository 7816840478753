import React,{useEffect, useState, useMemo, useRef} from 'react'
import { useHistory } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { List, Delete } from '@mui/icons-material';
import axios from 'axios'
import {PDFExport, savePDF} from '@progress/kendo-react-pdf';
import {handleLoad, NotifyUsers } from '../auth/ErrorHandler'
import Head from '../Nav/Head'


function Invoice() {

     const [paidInvoice, setPaidInvoice] = useState([]);
     const [unPaidInvoice, setUnPaidInvoice] = useState([]);
     const [loading, setLoading] = useState(false);
     const [viewInt, setViewInt] = useState(false);
     const [invoiceData, setInvoiceData] = useState();
     const [notify, setNotify] = useState();
     const [payStatus, setPayStatus] = useState('');
     const pdfExportResult = useRef(null);

    const downloadPrint = (type) =>{
          if(type === 'print'){
              window.print();
          }else
          {
            pdfExportResult.current.save();
          }
    }

     const ProcessPayment = () => {
            let vvid = invoiceData.invoice_id;
            let sid = invoiceData.stud_userid;
            let pid = invoiceData.parent_id;
            let amt = invoiceData.amount;

            if(!payStatus.trim()){
                    setNotify(NotifyMe('danger','Please select method of payment'))
            }else
            {
                   axios.post('/api-adminupdate-userinvoice',{payStatus,vvid,sid,pid,amt},{
                     headers:{'Content-Type':'application/json'}
                   }).then((res)=>{
                        setNotify(NotifyMe(res.data.color,res.data.message))
                   }).catch((err)=>{
                        console.log(err)
                   })

            }
     }


     const NotifyMe = (type,message) =>{
        const datas = {type,message}
         return (
            <NotifyUsers datas={datas} />
         )
    }



     const getInvoiceData = (vid) => {

        axios.get('/api-adminuser-invoice/'+vid,{
            headers:{'Content-Type':'application/json'}
         }).then((res)=>{
               setTimeout(()=>{
                    setInvoiceData(res.data.vData);
                 setViewInt(true)
                 
               },1000);
            
         }).catch((err)=>{
               console.log(err)
         })
             
     }


     const paid = useMemo(
        () => [
          {
            accessorKey: 'invoice_id', //access nested data with dot notation
            header: 'Invoice ID',
            size: 150,
          },
          {
            accessorKey: 'parent_id',
            header: 'Parent ID',
            size: 150,
          },
          {
            accessorKey: 'amount', //normal accessorKey
            header: 'Amount (R)',
            size: 200,
          },
          {
            accessorKey: 'invoice_type',
            header: 'Price',
            size: 150,
          },
          {
            accessorKey: 'date_created',
            header: 'Term',
            size: 150,
          },
        ],
        [],
      );

      const unpaid = useMemo(
        () => [
          {
            accessorKey: 'invoice_id', //access nested data with dot notation
            header: 'Invoice ID',
            size: 150,
          },
          {
            accessorKey: 'parent_id',
            header: 'Parent ID',
            size: 150,
          },
          {
            accessorKey: 'amount', //normal accessorKey
            header: 'Amount (R)',
            size: 200,
          },
          {
            accessorKey: 'invoice_type',
            header: 'Price',
            size: 150,
          },
          {
            accessorKey: 'date_created',
            header: 'Term',
            size: 150,
          }, 
        ],
        [],
      );

     useEffect(()=>{
          axios.get('/api-adminuser-invoice/all',{
             headers:{'Content-Type':'application/json'}
          }).then((res)=>{
                setTimeout(()=>{
                     setLoading(true)
                    setPaidInvoice(res.data.paid);
                    setUnPaidInvoice(res.data.unpaid);
                },1000);
             
          }).catch((err)=>{
                console.log(err)
          })
     },[])
     

  return (
    <div id="wrapper">
    <Head />
                         <div className="content-page">
                    <div className="content">
                <div className="container-fluid mb-4">

                <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                  
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Invoice</h4>
          </div>
        </div>
                     </div>
                        { loading === false ? <p>Content Loading. Please wait...</p> :
                     <div className='row'>

                    { viewInt === false ?
                     <div className="col-xl-12">
                        <div className="card-box">
                            <h4 className="header-title mb-4">Tabs Vertical Left</h4>

                            <div className="row">

                                    <div className="col-sm-3">
                                            <div className="nav flex-column nav-pills nav-pills-tab" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                <a className="nav-link active show mb-1" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home"
                                                    aria-selected="true">
                                                    Paid Invoice</a>
                                                <a className="nav-link mb-1" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile"
                                                    aria-selected="false">
                                                    UnPaid Invoice</a>
                                             
                                             
                                            </div>
                                        </div>

                                        <div className="col-sm-9">
                                            <div className="tab-content pt-0">

                                            <div className="tab-pane fade active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                    <h4>Paid Invoice</h4>
                                                    <MaterialReactTable columns={paid} data={paidInvoice}
                                                    enableRowActions
                                                    renderRowActionMenuItems={({ row }) => [
                                                    <MenuItem key="edit" onClick={() => getInvoiceData(row.original.invoice_id) }>
                                                        <ListItemIcon>
                                                    <List fontSize='small' />
                                            </ListItemIcon>
                                                        View
                                                    </MenuItem>,
                                                    <MenuItem key="delete" onClick={() => console.log('ready')}>
                                                        <ListItemIcon>
                                                    <Delete fontSize='small' />
                                                    </ListItemIcon>
                                                        Delete
                                                    </MenuItem>,
                                                    ]}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                    <h4>UnPaid Invoice</h4>
                                                    <MaterialReactTable columns={unpaid} data={unPaidInvoice}
                                                                enableRowActions
                                                                renderRowActionMenuItems={({ row }) => [
                                                                <MenuItem key="edit" onClick={() => getInvoiceData(row.original.invoice_id) }>
                                                                    <ListItemIcon>
                                                                <List fontSize='small' />
                                                        </ListItemIcon>
                                                                    View
                                                                </MenuItem>,
                                                                <MenuItem key="delete" onClick={() => console.log('ready')}>
                                                                    <ListItemIcon>
                                                                <Delete fontSize='small' />
                                                                </ListItemIcon>
                                                                    Delete
                                                                </MenuItem>,
                                                                ]}
                                                                />
                                                </div>
                                              

                                             </div>
                                          </div>


                             </div>



                        


                         </div>
                       </div>
                       :
                       <div className="col-lg-12">
                       <div className="card">
                           <div className="card-body">

                             { invoiceData !== false ?
                               <div>
                        <PDFExport ref={pdfExportResult} margin="1cm" paperSize="auto">
                               <div className="invoice-title">
                                   <h4 className="text-start font-size-16" style={{ float:'right' }}>Order # { invoiceData.invoice_id }</h4>
                                   <div className="mb-4">
                                       <img src="/assets/images/logo.png" alt="logo" height="50"/>
                                   </div>
                               </div>
                               <hr />
                               <div className="row">
                                   <div className="col-sm-6">
                                     <h5>Inssue to</h5>
                                     <address>
                                       { invoiceData.pName },<br />
                                       { invoiceData.pEmail},<br />
                                       { invoiceData.pPhone},<br />
                                       { invoiceData.pAddress }.
                                     </address>
                                  
                                       
                                   </div>
                                   <div className="col-sm-6">
                                      { invoiceData.payment_status === 'Paid' ? 
                                        <h1 className='text-success' style={{ fontWeight:'bolder', float:'right' }}>PAID</h1> :
                                      
                                        <h1 className='text-danger' style={{ fontWeight:'bolder', float:'right' }}>UNPAID</h1>
                                     }
                                     
                                   </div>
                               </div>
                               <div className="row">
                                   <div className="col-sm-6 mt-3">


                                   <address>
                                           <strong>Date Created:</strong><br />
                                           { invoiceData.date_created }<br /><br />
                                       </address>
                                     
                                   
                                   </div>
                                   <div className="col-sm-6 mt-3">

                                    

                                      
                                        <h4>Account Details</h4>
                                        <hr></hr>
                                        <table className="table table-bordered border-success table-nowrap">
                                          <tr>
                                            <th>Bank Name</th>
                                            <td>{invoiceData.bname}</td>
                                          </tr>
                                          <tr>
                                            <th>Account Name</th>
                                            <td>{invoiceData.aname}</td>
                                          </tr>
                                          <tr>
                                            <th>Account Number</th>
                                            <td>{invoiceData.anumber}</td>
                                          </tr>
                                          <tr>
                                            <th>Branch Code</th>
                                            <td>{invoiceData.bcode}</td>
                                          </tr>
                                        </table>
                    
                                      

                                   </div>
                                   <div className="col-sm-12 mt-3">
                                        { notify !== null ? notify : null }
                                     </div>
                               </div>
                               <div className="py-2 mt-3">
                                   <h3 className="font-size-15 font-weight-bold">Order summary</h3>
                               </div>
                               <div className="table-responsive">
                                   <table className="table table-nowrap">
                                       <thead>
                                           <tr>
                                               <th style={{width: 70}}>S/N</th>
                                               <th>Purpose</th>
                                               <th className="text-end">Price</th>
                                           </tr>
                                       </thead>
                                       <tbody>
                                           <tr>
                                               <td>01</td>
                                               <td>{ invoiceData.purpose }</td>
                                               <td className="text-end">R { invoiceData.amount }</td>
                                           </tr>
                                           
                                           
                                           <tr>
                                               <td colSpan="2" className="text-end">Sub Total</td>
                                               <td className="text-end">R { invoiceData.amount }</td>
                                           </tr>
                                          
                                           <tr>
                                               <td colSpan="2" className="border-0 text-end">
                                                   <strong>Total</strong></td>
                                               <td className="border-0 text-end"><h4 className="m-0">R { invoiceData.amount }</h4></td>
                                           </tr>
                                       </tbody>
                                   </table>
                               </div>
                               </PDFExport>
                               <div className="d-print-none">
                                   <div className="float-end">
                                   <button onClick={()=>downloadPrint('print')} className="btn btn-blue waves-effect waves-light me-1"><i className="fa fa-print"></i> Print</button>
                                   <button onClick={()=>downloadPrint('download')}  className="btn btn-success waves-effect waves-light me-1" style={{ marginLeft:10 }}><i className="fa fa-print"></i> Download</button>
                                     
                                         { invoiceData.payment_status === 'Paid' ? null :
                                            <div style={{ float:'right', width:400 }} className='text-center'>
                                          
                                            <select className='custom-select mb-3' onChange={(e)=> setPayStatus(e.target.value)}>
                                            <option value={''}>Select Payment Option</option>
                                                <option value={'Paid'}>User Already Paid</option>
                                                <option value={'Deduct'}>Debit user wallet</option>
                                            </select>
                                            <button onClick={()=> ProcessPayment()} className="btn btn-success w-md waves-effect waves-light">Pay Now</button>
                                            </div>
                                         }
                                   </div>
                               </div>
                               </div> : 

                                 null
                                
                             }
                           </div>
                       </div>
                   </div>
                        
                        
                        }


                     </div> }

                    </div>
                 </div>
             </div>
         </div>
  )
}

export default Invoice