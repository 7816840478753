import React,{useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios'
import {NotifyUsers, SuspendUsers, HandleUserProfile, HandleWallet, ResetUserPass} from '../auth/ErrorHandler';
import Head from '../Nav/Head';

function ViewTutor() {
      const {uid} = useParams();
      const [tData, setTData] = useState();
      const [centre, setCentre] = useState([]);
      const [loading, setLoading] = useState(false);
      const [trans, setTrans] = useState([]);
      const [withd, setWithd] = useState([]);
      const [wallet, setWallet] = useState();
      const [pid, setPid] = useState();
      const [mStatus, setMStatus] = useState();
      const [regionData, setRegionData] = useState([]);
      const [centreName, setCentreName] = useState('')
      const [centreEmail, setCentreEmail] = useState('')
      const [centrePhone, setCentrePhone] = useState('')
      const [centreRegion, setCentreRegion] = useState('')
      const [centreAddress, setCentreAddress] = useState('')
      const [load, setLoad] = useState(false);
      const [alat, setAlat] = useState(null);
      const [lodSub, setLodSub] = useState(false);
      const [mCid, setMCid]= useState();

        let mtype = 'tutor';
      const susData = {mtype,pid,mStatus};
      const parentData = {pData:tData}
      const history = useHistory();

      const noti = (type,message) =>{
        let data = {type,message}
       return (
         <>
          <NotifyUsers datas={data} />
         </>
       )
  }

  const userWallet = () => {
    let ps = {pid}
return(

<HandleWallet datas={ps} />
)

}

const validDate = () =>{
    let valid = true;
     if(!centreEmail.trim() || !centreName.trim() || !centrePhone.trim()
      || !centreAddress.trim() || !centreRegion.trim()){
            valid = false;
      }

       return valid;
}

const handleCentreUpdate = () =>{
     setAlat(null)
        if(!validDate()){
            setAlat(<div className='alert alert-danger text-center'>Please make sure all form input are not empty</div>)
        }else
        {
          let cid = null;
           let tid = tData.userid;
            let name = tData.names;
            let type = 'Add-user';
          let datas = {cid,centreEmail,centreName,centrePhone,centreAddress,centreRegion,type,tid,name}
                setLoad(true)
             axios.post('/api-admin-create-add-centre',datas,{
              headers:{'Content-Type':'application/json'}
             }).then((res)=>{
                    setTimeout(()=>{
                      setAlat(<div className={`alert alert-${res.data.color}`}>{res.data.message}</div>)
                      setLoad(false)
                    },1000)
             }).catch((err)=>{
                console.log(err)
             })

        }
      
}

const removeLearnerSubjectAdm = (type) =>{
    
    const datas = {sub_id:null,grade:null,stud_id:mCid,type}
    setLodSub(true)
axios.post('/api-removelearner-admin-data',datas,{
  headers:{'Content-Type':'application/json'}
}).then((res)=>{
    if(res.data.status === 'success'){
        setLodSub(false)
    }
}).catch((err)=>{
 console.log(err)
})
}
      

    useEffect(()=> {
         axios.get('/api-getusers-data/'+uid+'/tutor',{
                headers:{'Content-Length':'application/json'}
         }).then((res)=>{
                setTimeout(()=>{
                     setTData(res.data.udatas[0]);
                     setCentre(res.data.cent);
                     setTrans(res.data.trans);
                     setWithd(res.data.withd);
                     setWallet(res.data.wallet[0]);
                     setPid(res.data.udatas[0].userid)
                     setMStatus(res.data.udatas[0].status)
                     setRegionData(res.data.region)

                    setLoading(true)
                },1000)
         }).catch((err)=>{
                console.log(err)
         })
         
    },[lodSub])
  return (
    <div id="wrapper">
    <Head />
                         <div className="content-page">
                    <div className="content">
                <div className="container-fluid mb-4">

                    { loading === false ? <p>Loading Content. Please wait...</p>
                    :
                    <>
                <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                  <button type="button" className="btn btn-warning waves-effect btn-sm waves-light" data-toggle="modal" data-target="#centerUserProfile" data-backdrop="static"> Add Centre</button>
                     &nbsp;&nbsp;

                     <button type="button" className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#centerwallet" data-backdrop="static"> <i className="mdi mdi-wallet-plus" /> Fund / Deduct From Wallet</button>
                     &nbsp;&nbsp;
                        {
                        <SuspendUsers datas={susData} />
                        }
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Tutor Dashboard</h4>
          </div>
        </div>
                     </div>


                <div class="row mt-2">
                            <div class="col-md-6 col-xl-4">
                                <div class="card-box">
                                    <i class="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i>
                                    <h4 class="mt-0 font-16">Wallet Balance</h4>
                                    <h2 class="text-primary my-3 text-center">R<span data-plugin="counterup">{ wallet.balance === null ? '0.00' : wallet.balance }</span></h2>
                                    <p class="text-muted mb-0">Total income: $22506 <span class="float-right"><i class="fa fa-caret-up text-success mr-1"></i>10.25%</span></p>
                                </div>
                            </div>

                            <div class="col-md-6 col-xl-4">
                                <div class="card-box">
                                    <i class="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i>
                                    <h4 class="mt-0 font-16">Total Centre</h4>
                                    <h2 class="text-primary my-3 text-center"><span data-plugin="counterup">{ centre.length }</span></h2>
                                    <p class="text-muted mb-0">Total sales: 2398 <span class="float-right"><i class="fa fa-caret-down text-danger mr-1"></i>7.85%</span></p>
                                </div>
                            </div>

                            <div class="col-md-6 col-xl-4">
                                <div class="card-box">
                                    <i class="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i>
                                    <h4 class="mt-0 font-16">Total withdrawal</h4>
                                    <h2 class="text-primary my-3 text-center"><span data-plugin="counterup">{ withd.length }</span></h2>
                                    <p class="text-muted mb-0">Total users: 121 M <span class="float-right"><i class="fa fa-caret-up text-success mr-1"></i>3.64%</span></p>
                                </div>
                            </div>

                          
                        </div>

                     <div className='row'>

                     <div class="modal fade" id="centerUserProfile" tabIndex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title" id="myCenterModalLabel">Centre Profile</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
              </div>
              <div class="modal-body">
  
                     <div className='row'>
                     
                 
  
                      <div className='col-xl-12 mb-3'>
                        
                      <label>Name</label>
                         <input type='text' className='form-control' onChange={(e)=> setCentreName(e.target.value)} defaultValue={centreName} />
                      </div>


                      <div className='col-xl-6 mb-3'>
                        
                        <label>Phone</label>
                           <input type='text' className='form-control' onChange={(e)=> setCentrePhone(e.target.value)}  defaultValue={centrePhone} />
                        </div>

                      <div className='col-xl-6 mb-3'>
                        
                        <label>Email</label>
                           <input type='text' className='form-control' onChange={(e)=> setCentreEmail(e.target.value)} defaultValue={centreEmail} />
                        </div>

                        <div className='col-xl-12 mb-3'>
                        
                        <label>Region</label>
                           <select type='text' value={centreRegion} onChange={(e)=> setCentreRegion(e.target.value)} className='form-control' >
                            { regionData && regionData.map((g,i)=>{
                                 return(
                                   <option key={i} value={g.location}>{g.location}</option>
                                 )
                            })}
                            </select>
                        </div>
  
                    
  
                      <div className='col-xl-12 mb-3'>
                          <label>Address</label>
                         <textarea className='form-control' onChange={(e)=> setCentreAddress(e.target.value)} defaultValue={centreAddress} ></textarea>
                      </div>

                      <div className="col-xl-12 text-center">
                           { alat }
                           </div>
  
                        <div className="col-xl-12 text-center">

                            {load !== false ? <p className='text-danger'>Updating centre. Please wait...</p> : null}

                            { load === false ?
                          <button className="btn btn-blue" onClick={()=>handleCentreUpdate()}>Update User Profile</button>
                                :
                                <button className="btn btn-blue" disabled>Please wait...</button>
                            }
                        </div>
  
  
                     </div>
  
                </div>
          </div>
      </div>
  </div>


                     <HandleUserProfile datas={parentData} />
                     {  userWallet() }
                     <ResetUserPass datas={parentData} />

                     <div className="col-lg-4 col-xl-4">
                                <div className="card-box text-center">
                                    <img src="../assets/images/users/user-1.jpg" className="rounded-circle avatar-lg img-thumbnail"
                                        alt="profile" />

                                    <h4 className="mb-0">{ tData.names }</h4>
                                    <p className="text-muted">TUTOR</p>

                                    <button type="button" className="btn btn-success btn-xs waves-effect mb-2 waves-light" data-toggle="modal" data-target="#centerUserProfile" data-backdrop="static">Edit Information</button>
                                    <button type="button" className="btn btn-danger btn-xs waves-effect mb-2 waves-light" data-toggle="modal" data-target="#resetPass" data-backdrop="static">Reset User Password</button>

                                    <div className="text-left mt-3">
                                        <h4 className="font-13 text-uppercase">About Me :</h4>
                                      
                                        <div class="table-responsive">
                                          <table class="table mb-0">
                                             <tr>
                                                <th>Email</th>
                                                <td>{tData.email}</td>
                                             </tr>
                                             <tr>
                                                <th>Phone</th>
                                                <td>{tData.phone}</td>
                                             </tr>
                                             <tr>
                                                <th>Location</th>
                                                <td>{tData.address}</td>
                                             </tr>
                                             <tr>
                                                <th>Temporary Password</th>
                                                <td className='bg-danger text-white'>{ tData.temporary }</td>
                                            </tr>
                                             <tr>
                                                <th>Registeration Date</th>
                                                <td>{tData.date_reg}</td>
                                             </tr>
                                            </table>
                                        </div>
                                 
                                    </div>

                                  
                                </div> 

                             

                            </div> 



                            <div className="col-lg-8 col-xl-8">


                            <div className="card">
                                    <div className="card-body">
                                        
                                    <div className="row mb-2">
                                            <div className="col-sm-4">
                                               <h4>Centres</h4>
                                            </div>

                                            <div className="col-sm-8">
                                               
                                            </div>

                                        </div>


                                        <div id="success-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                                            <div class="modal-dialog modal-sm">
                                                <div class="modal-content modal-filled bg-danger">
                                                    <div class="modal-body p-4">
                                                        <div class="text-center">
                                                        <i class="dripicons-wrong h1 text-white"></i>
                                                            <h4 class="mt-2 text-white">Oop Snap!</h4>
                                                            <p class="mt-3 text-white">You about to delete centre, all details about this centre will be lost </p>
                                                           
                                                            <button type="button" class="btn btn-light" data-dismiss="modal" onClick={()=> removeLearnerSubjectAdm('centre')}>Delete</button>
                                                            
                                                         
                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="table-responsive">
                                        { centre.length > 0 ?
                                        <table className="table table-centered table-nowrap table-hover mb-0">
                                                <thead>
                                                <tr>
                                                        <th>Names</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>Centre ID</th>
                                                        <th>Created Date</th>
                                                        <th style={{width: 82}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { centre && centre.map((c,i)=>{
                                                          return(
                                                            <tr key={i}>
                                                            <td className="table-user">
                                                                <b>{ c.centre_name }</b>
                                                            </td>
                                                            <td>
                                                            { c.email }
                                                            </td>
                                                            <td>
                                                            { c.phone }
                                                            </td>
                                                            <td>
                                                            { c.centre_id }
                                                            </td>
                                                            <td>
                                                            { c.date_reg }
                                                            </td>
                                                            <td>
                                                                <div onClick={()=> history.push('/centres/'+c.centre_id)} className="action-icon" style={{ cursor:'pointer' }} title='view'> <i className="mdi mdi-eye"></i></div>
                                                                &nbsp;&nbsp;
                                                                <div className="action-icon" data-toggle="modal" data-target="#success-alert-modal" onClick={()=> setMCid(c.centre_id)} style={{ cursor:'pointer' }}> <i className="mdi mdi-delete"></i></div>
                                                            </td>
                                                        </tr>
                                                          )
                                                    })}
                                                  
                                                    </tbody>
                                                  
                                            </table> : 

                                            noti('danger','This tutor has no centre')
                                        }
                                          

                                          </div>


                                </div>
                            </div>


                            </div>


                     </div>
                     </> }

                        </div>
                        </div>
                    </div>
                    </div>

  )
}

export default ViewTutor