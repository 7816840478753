import React,{useState} from 'react'
import {handleLoad, NotifyUsers } from './ErrorHandler';
import axios from 'axios';


function Authenticate({auth}) {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [notify, setNotify] = useState(null);
    const [loading, setLoading] = useState(false)

  
    const NotifyMe = (type,message) =>{
        const datas = {type,message}
         return (
            <NotifyUsers datas={datas} />
         )
    }

    const loadMe = (type) =>{
        const datas = {type}
        return (
           <handleLoad datas={datas} />
        )
    }

    const handleAdminAuth = () => {
             if(email === '' || pass === ''){
                  setNotify(NotifyMe('danger','Please provide your email and password'))
             }else
             {
                  setLoading(true);

                 axios.post('/authenticate-admin',{email,pass},{
                            headers:{'Content-Type':'application/json'}
                 }).then((res)=>{
                        setTimeout(()=>{
                            setLoading(false)
                            if(res.data.status !== 'allow'){
                                setNotify(NotifyMe(res.data.color,res.data.message))
                        }else
                        {
                             window.location ="/";
                        }
                        },1000)
                      
                 }).catch((err)=>{
                        console.log(err)
                 })
             }
    }
  
  return (
    <div>
    
 <div className="account-pages mt-5 mb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card bg-pattern">

                            <div className="card-body p-4">
                                
                                <div className="text-center w-75 m-auto">
                                    <div className="auth-logo">
                                        <div className="logo logo-dark text-center">
                                            <span className="logo-lg">
                                                <img src="../assets/images/logo.png" alt="" height="60" />
                                            </span>
                                        </div>
                    
                                        <div className="logo logo-light text-center">
                                            <span className="logo-lg">
                                                <img src="../assets/images/logo-light.png" alt="" height="22" />
                                            </span>
                                        </div>
                                    </div>
                                    <p className="text-muted mb-4 mt-3">Enter your email address and password to access admin panel.</p>
                                </div>

                          
                                     { notify !== null ? notify : null}
                                    <div className="form-group mb-3">
                                        <label for="emailaddress">Email address</label>
                                        <input className="form-control" type="email" onChange={(e)=>setEmail(e.target.value)} id="emailaddress" required="" placeholder="Enter your email" />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label for="password">Password</label>
                                        <div className="input-group input-group-merge">
                                            <input type="password" id="password" onChange={(e)=>setPass(e.target.value)} className="form-control" placeholder="Enter your password" />
                                           
                                        </div>
                                    </div>

            

                                    <div className="form-group mb-0 text-center">
                                         { loading !== false ? loadMe('success') : null }
                                        <button onClick={()=>handleAdminAuth()} className="btn btn-primary btn-block" type="button"> Log In </button>
                                    </div>

                             

                            

                            </div>
                        </div>
                   
                     

                    </div>
                </div>
           
            </div>
         
        </div>
   


        <footer className="footer footer-alt text-black-50">
            2015 - {new Date().getFullYear()} &copy;
        </footer>
    </div>
  )
}

export default Authenticate