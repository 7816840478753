import React,{useEffect, useState} from 'react'
import axios from 'axios'
import Head from '../Nav/Head'

function Settings(){
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [conPass, setConPass] = useState('');
    const [status, setStatus] = useState(null);
    const [adminID, setAdminID] = useState();
     

    const resetPast = () => {
        let useData = {email:email,pass:pass,userid:adminID}
        if(email === '' || pass === '' || conPass === ''){
               setStatus(<div class='alert alert-info text-center'>Please all form field must be fill</div>);
      
            }else
        {
              if(pass != conPass){
                setStatus(<div class='alert alert-info text-center'>Password did not match</div>);
      
              }else{
                axios.post('/reset-pass',useData,{
                    headers: { 'Content-Type': 'application/json'}
                  }).then((res)=>{
                    setStatus(<div class='alert alert-info text-center'>{res.data.mstatus}</div>);
                  })
              }
            

        }
}

useEffect(()=>{
    axios.get('/admin-authenticate-check',{
        headers: { 'Content-Type': 'application/json'}
    }).then((res)=>{
            setEmail(res.data.adminData[0].email);
            setAdminID(res.data.adminData[0].id);
    })
},[])

return (
<div id="wrapper">
    <Head />
<div className="content-page">
<div className="content">


<div className="container-fluid">

 
    <div className="row">
        <div class="col-12">
            <div class="page-title-box">
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item">Admin Login</li>
                        <li class="breadcrumb-item">Settings</li>
                       
                    </ol>
                </div>
                <h4 class="page-title">Admin Login</h4>
            </div>
        </div>
    </div>

    <div className="row">
    <div className="col-lg-3">
        </div>
    <div className="col-lg-6">
<div className="card">
  <div className="card-body">
    <h4 className="mb-3 header-title">Update Admin Login</h4>
    <form>
       
        { status === null ? null : status }
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">Login Email</label>
        <input type="email" className="form-control" id="exampleInputEmail1" defaultValue={email} onChange={(e)=> setEmail(e.target.value)} aria-describedby="emailHelp" placeholder="Enter email" />
    
      </div>
      <div className="form-group">
        <label htmlFor="exampleInputPassword1">Login Password</label>
        <input type="password" className="form-control" id="exampleInputPassword1" onChange={(e)=> setPass(e.target.value)} placeholder="Password" />
      </div>

      <div className="form-group">
        <label htmlFor="exampleInputPassword1">Confirm Password</label>
        <input type="password" className="form-control" id="exampleInputPassword1" onChange={(e)=> setConPass(e.target.value)} placeholder="Confirm Password" />
      </div>
     
      <button type="button" onClick={()=> resetPast()} className="btn btn-primary waves-effect waves-light">Update Admin</button>
    </form>
  </div> {/* end card-body*/}
</div> {/* end card*/}
</div>

<div className="col-lg-3">
        </div>
{/* end col */}

        </div>


    </div>
</div>
</div>
</div>
   
)
}

export default Settings