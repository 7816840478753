import React,{useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SuspendUsers, AccountStatus, HandleCentres, HandleCompleteFees } from '../auth/ErrorHandler';
import axios from 'axios'
import Head from '../Nav/Head';

function ViewLearner() {
      const {sid} = useParams();
      const [loading, setLoading] = useState(false);
      const [stud, setStud] = useState();
      const [subject, setSubject] = useState([]);
      const [centre, setCentre] = useState();
      const [names, setName] = useState('');
      const [dob, setDob] = useState('');
      const [gender, setGender] = useState('');
      const [race, setRace] = useState('');
      const [region, setRegion] = useState('');
      const [grade, setGrade] = useState('');
      const [homeLang, setHomeLang] = useState('');
      const [otherLang, setOtherLang] = useState('');
      const [idtype, setIdType] = useState('');
      const [idNum, setIdNum] = useState('');
     const [address, setAddress] = useState('');
      const [loadbut, setLoadBut] = useState(false)
      const [notis, setNotis] = useState(null);
      const [regs, setRegs] = useState([]);
      const [pstatus, setPstatus] = useState();
      const [sStatus, setSStatus] = useState();
      const [addNewSubject, setAddNewSubject] = useState(false);
      const [notifer, setNotifer] = useState(null);
      const [moreSubject, setMoreSubject] = useState([]);
      const [extraCart, setExtraCart] = useState([]);
      const [lodSub, setLodSub] = useState(false);
      const [extSub, setExtSub] = useState('');
      const [alet, setAlet] = useState(null);
      const [msid, setMSid] = useState();
      const [parentID, setParentID] = useState();
      const [cent, setCent] = useState([]);


      
      const ms = {studID:sid,pid:parentID}

      let mtype = 'student';
       let pid = sid;
       let mStatus = pstatus;
      const susData = {mtype,pid,mStatus:sStatus};
       const usData = {type:'learner',id:sid,mStatus}

     


       const fetchCentres = () =>{
         let stCID = stud.centre_id;
        const datas = {centres:cent,stid:sid,scentID:stCID}
       return (
        <div className="modal fade" id="scrollable-modal" tabIndex="-1" role="dialog"
        aria-labelledby="scrollableModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="scrollableModalTitle">Available Centres</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                  { cent.length === 0 ? <div className='alert alert-blue text-center'>No centre available</div> :
                  <div className='row'>
                        <HandleCentres datas={datas}/>

                        </div>
                     }
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  
                </div>
            </div>
        </div>
    </div>
       )
   }


       const checkIfSubjectExist = (subID) =>{
               
        if(subject.find((item) => item.subject_id === subID)){
            return true;
        } 
               
          
    }

    const handleAddSubject = (sid,price,names,cate) =>{
             
        const newItem = {
             id:sid,
             price:price,
             names:names,
             cate:cate
        }
      
        setExtraCart((prevItems)=>[...prevItems, newItem])
      
      }
      

    const checkIfSubjectExistInCart = (id) =>{
        if(extraCart.find((item) => item.id === id)){
            return true;
        }
    }

    const removeSubjectFromCart = (sid) =>{
    
        const updatedCartItems = extraCart.filter((item) => item.id !== sid);
    
                setExtraCart(updatedCartItems);
    }
    


    const checkPrice = (price) => {
        if(price === null || price === ''){
              return 0;
        }else{
              return price;
        }
    }

       
       const addMoreSubject = () => {
            return(
                <>
               

                { moreSubject.length > 0 ? 
              

                <table className="table table-centered table-nowrap table-hover mb-0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Subject Name</th>
                            <th>Category</th>
                            <th>Subject Price</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                       
                            { moreSubject && moreSubject.map((s,i)=>{
                                  return (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{ s.names }</td>
                                        <td>{ s.cate }</td>
                                        <td>{ checkPrice(s.price) }</td>
                                        <td>

                                                                 { 
                                                                  checkIfSubjectExist(s.subject_id) === true ? <i className='mdi mdi-check-bold text-success'></i> :
                                                                 
                                                                 checkIfSubjectExistInCart(s.subject_id) === true ? 
                                                                                   <button onClick={() => removeSubjectFromCart(s.subject_id)}  type="button" className="btn btn-danger btn-sm btn-rounded">
                                                                                   <i className="bx bx-trash font-size-16 align-middle me-2"></i>  Remove
                                                                                   </button>
                                                                                   :

                                                                                   <button onClick={() => handleAddSubject(s.subject_id, checkPrice(s.price), s.names, s.cate)} type="button" className="btn btn-primary btn-sm btn-rounded">
                                                                                   <i className="bx bx-plus align-middle me-1"></i>  Add Subject
                                                                                   </button>

                                                                                 
                                                                        }

                                            </td>
                                    </tr>
                                  )
                            })}
                           
                      
                    </tbody>

                </table>

              : notifer }
                </>
            )
       }


       const getMoreSubject = (grd) =>{

        axios.get('/get-subject/'+grd,{
             headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                if(res.data.subjectData !== 'empty'){
                      setNotifer(null)
                      setMoreSubject(res.data.subjectData);
                }else
                {
                     setMoreSubject([])
                    setNotifer(<div className='alert alert-danger text-center'>We are unable to retrieve all subject for Grade {grd}</div>)
                }
        })
  

  }


      const validate = () =>{
           let valid = true;

             if(!names.trim() || !dob.trim() || !grade.trim() || !region.trim() 
             || !race.trim() || !address.trim() || !otherLang.trim() || !homeLang.trim() 
            || !idNum.trim() || !idtype.trim() || !gender.trim()){
                  valid = false;
            }


            return valid;
      }


      const updateProfile = () => {
             if(!validate()){
                    setNotis(<div className='alert alert-danger text-center'>Please make sure all form input are not empty</div>);
                     console.log(names,dob,grade,region,
                        race,address,otherLang,homeLang,idNum,idtype,gender,sid)
             }
             else
             {
                  setLoadBut(true)
                 let sid = stud.stud_userid;
                let mdatas = {names,dob,grade,region,
                    race,address,otherLang,homeLang,idNum,idtype,gender,sid}

                    axios.post('/api-adminupdate-learner-profile',mdatas,{
                         headers:{'Content-Type':'application/json'}
                    }).then((res)=>{
                         setTimeout(()=>{
                                setLoadBut(false)
                                setNotis(<div className={`alert alert-${res.data.color}`}>{res.data.message}</div>)
                         },1000)
                    }).catch(err=>{
                          console.log(err)
                    })

             }
            
      }


      const studDetails = () =>{
        return (
          <div className='row'>
            
          <div className='col-xl-12'>
              <div className='mb-3'>
              <label>Names</label>
              <input type='text' className='form-control' onChange={(e)=>setName(e.target.value)} defaultValue={names} />
              </div>
  
          </div>

          <div className='col-xl-12'>
              <div className='mb-3'>
              <label>Grade</label>
              <select type='text' className='custom-select' value={grade} onChange={(e)=>setGrade(e.target.value)} >
                   <option value={1}>1</option>
                   <option value={2}>2</option>
                   <option value={3}>3</option>
                   <option value={4}>4</option>
                   <option value={5}>5</option>
                   <option value={6}>6</option>
                   <option value={7}>7</option>
                   <option value={8}>8</option>
                   <option value={9}>9</option>
                   <option value={10}>10</option>
                   <option value={11}>11</option>
                   <option value={12}>12</option>

                  </select>
                  <small className='text-danger'>Learner fee will be change when you change learner grade</small>
              </div>
              
  
          </div>
  
          <div className='col-xl-6'>
              <div className='mb-3'>
              <label>Gender</label>
              <select type='text' value={gender} className='custom-select' onChange={(e)=>setGender(e.target.value)} >
                  <option value={''}>Select option</option>
                  <option value={'Male'}>Male</option>
                  <option value={'Female'}>Female</option>
                  </select>
              </div>
  
          </div>
  
  
  
          <div className='col-xl-6'>
              <div className='mb-3'>
              <label>Date of Birth</label>
              <input type='date' className='form-control' onChange={(e)=>setDob(e.target.value)} defaultValue={dob}/>
              </div>
  
          </div>
  
  
          <div className='col-xl-6'>
              <div className='mb-3'>
              <label>Race</label>
              <select type='text' value={race} className='custom-select' onChange={(e)=>setRace(e.target.value)}>
                  <option value={''}>Choose Option</option>
                   <option value={'Black African'}>Black African</option>
                  <option value={'Colored'}>Colored</option>
                  <option value={'White'}>White</option>
                  <option value={'Indian / Asia'}>Indian / Asia</option>
                   </select>
              </div>
  
          </div>
  
          <div className='col-xl-6'>
              <div className='mb-3'>
              <label>Region</label>
              <select type='text' value={region} className='custom-select' onChange={(e)=>setRegion(e.target.value)}>
              <option value={''}>Choose</option>
              { regs.length > 0 ? regs && regs.map((r,i)=>{
                   return(
                      <option key={i} value={r.location}>{r.location}</option>
                   )
              }) : <option disabled>Not availabe</option>}
                 
              </select>
              </div>
  
          </div>
  
  
  
          <div className='col-xl-6'>
              <div className='mb-3'>
              <label>Home Language</label>
              <input type='text' className='form-control' defaultValue={homeLang} onChange={(e)=>setHomeLang(e.target.value)} />
              </div>
  
          </div>
  
          <div className='col-xl-6'>
              <div className='mb-3'>
              <label>Other Language</label>
              <input type='text' className='form-control' defaultValue={otherLang} onChange={(e)=>setOtherLang(e.target.value)} />
              </div>
  
          </div>
  
          <div className='col-xl-6'>
              <div className='mb-3'>
              <label>Learner ID Type</label>
              <select type='text' className='custom-select' value={idtype} onChange={(e)=>setIdType(e.target.value)}>
                  <option value={''}>Select option</option>
                  <option value={'Student ID Number'}>Student ID</option>
                   <option value={'Passport ID'}>Passport ID</option>
                  </select>
              </div>
  
          </div>
  
          <div className='col-xl-6'>
              <div className='mb-3'>
              <label>Learner ID Number</label>
              <input type='text' className='form-control' value={idNum} onChange={(e)=>setIdNum(e.target.value)} />
              </div>
  
          </div>
  
          <div className='col-xl-12'>
              <div className='mb-3'>
              <label>Address</label>
              <textarea type='text' className='form-control' onChange={(e)=>setAddress(e.target.value)} defaultValue={address}></textarea>
              </div>
  
          </div>

          { notis === null ? null : <div className='col-xl-12'>{notis} </div>}
  
          <div className='col-xl-12 text-center'>
               { loadbut === false ?
              <button className='btn btn-blue' onClick={()=> updateProfile()}>Update Profile</button>
              :
              <button className='btn btn-blue' disabled>Please wait...</button>
               }
          </div>
  
  
       </div>
        )
   }
  


      const addStudentData = () =>{
       
        return(
         
          <div className="modal fade" id="centermodal" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title" id="myCenterModalLabel">Add Learner</h4>
                          <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                      </div>
                      <div className="modal-body">
                                 
  
                              { studDetails() }
  
                        </div>
                  </div>
              </div>
          </div>
        )
   }
  
     
   const calculateAmountSubject = () => {
                let total =0;
                if(extraCart.length > 0){
                    for (let i=0; i<extraCart.length; i++){
                          total = total + parseInt(extraCart[i].price)
                    }

                }

                 return total;
   }


   const handleAdminSubjectAddeds = () =>{
                setAlet(null)
            if(!extSub.trim()){
                setAlet(<div className='alert alert-danger text-center mt-2'>Please select mode of payment</div>)
            }else{
                setLodSub(true)
        let pid = stud.parent_id;
        let datas = {extraCart,sid,pid,extSub};
         axios.post('/api-add-extrasubject-admin',datas,{
            headers:{'Content-Type':'application/json'}
         }).then((res)=>{
                setTimeout(()=>{
                     setAlet(<div className={`alert alert-${res.data.color} mt-2`}>{res.data.message}</div>)
                    setLodSub(false)
                },1000)
         }).catch(err=>{
               console.log(err)
         })
        }
   }

     const removeLearnerSubjectAdm = (type) =>{
                let grd = stud.grade;
                const datas = {sub_id:msid,grade:grd,stud_id:sid,type}
                setLodSub(true)
           axios.post('/api-removelearner-admin-data',datas,{
              headers:{'Content-Type':'application/json'}
           }).then((res)=>{
                if(res.data.status === 'success'){
                    setLodSub(false)
                }
           }).catch((err)=>{
             console.log(err)
           })
     }
      

    useEffect(()=> {
       
         axios.get('/api-getusers-data/'+sid+'/student',{
                headers:{'Content-Type':'application/json'}
         }).then((res)=>{
                    let sData = res.data.udatas[0];
                setStud(res.data.udatas[0]);
                setSubject(res.data.subject);
                setRegion(sData.region)
              setLoading(true)
              setCentre(res.data.cent);
              setRegs(res.data.region);
              setAddress(sData.address);
              setName(sData.names);
              setDob(sData.dob);
              setRace(sData.race);
              setHomeLang(sData.home_lang);
              setOtherLang(sData.other_lang)
              setIdType(sData.id_type);
              setIdNum(sData.stud_id_no);
              setGender(sData.gender);
              setGrade(sData.grade);
               setPstatus(sData.profile_status)
               setSStatus(sData.status);
               getMoreSubject(sData.grade);
               setCent(res.data.mcent);
               setParentID(sData.parent_id)
               

         }).catch((err)=>{
                console.log(err)
         })
         
    },[lodSub])
  return (
    <div id="wrapper">
    <Head />
                         <div className="content-page">
                    <div className="content">
                <div className="container-fluid mb-4">

                { loading === false ? <p>Loading Content. Please wait...</p> :

                <>

                <div className="row">
                    { addStudentData() }
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                            <AccountStatus datas={usData} />
                            <SuspendUsers datas={susData} />
                        
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Student Dashboard</h4>
          </div>
        </div>
                 </div>
                    { fetchCentres() }

                 <div className="row">
                            <div className="col-md-6 col-xl-3">
                                <div className="widget-rounded-circle card-box">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="avatar-lg rounded-circle bg-soft-primary border-primary border">
                                                <i className="fe-book font-22 avatar-title text-primary"></i>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="text-right">
                                                <h3 className="mt-1"><span data-plugin="counterup" style={{ fontSize:20 }}>{ subject.length }</span></h3>
                                                <p className="text-muted mb-1 text-truncate">Total Subject</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>

                            <div className="col-md-6 col-xl-3">
                                <div className="widget-rounded-circle card-box">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="avatar-lg rounded-circle bg-soft-success border-success border">
                                                <i className="fe-shopping-cart font-22 avatar-title text-success"></i>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="text-right">
                                                <h3 className="text-dark mt-1"><span data-plugin="counterup" style={{ fontSize:14 }}>{ stud.due_date === null ? 'N/A' : stud.due_date }</span></h3>
                                                <p className="text-muted mb-1 text-truncate">Due Date</p>
                                            </div>
                                        </div>
                                    </div> 
                                </div> 
                            </div> 

                            <div className="col-md-6 col-xl-3">
                                <div className="widget-rounded-circle card-box">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="avatar-lg rounded-circle bg-soft-info border-info border">
                                                <i className="fe-bar-chart-line- font-22 avatar-title text-info"></i>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="text-right">
                                                <h3 className="text-dark mt-1"><span data-plugin="counterup" style={{ fontSize:20 }}>R {stud.term_fee}</span></h3>
                                               { stud.payment_status !== 'Paid' ?
                                                <b className=" mb-1 text-danger">Unpaid Fee</b>
                                                :
                                                <b className=" mb-1 text-truncate" style={{ color:'darkgreen' }}>Fee Paid</b>
                                                    }
                                            </div>
                                        </div>
                                    </div> 
                                </div> 
                            </div> 

                            <div className="col-md-6 col-xl-3">
                                <div className="widget-rounded-circle card-box">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="avatar-lg rounded-circle bg-soft-warning border-warning border">
                                                <i className="fe-eye font-22 avatar-title text-warning"></i>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="text-right">
                                                <h3 className="text-dark mt-1"><span data-plugin="counterup" style={{ fontSize:20 }}>{ centre === 'empty' ? 'N/A' : centre[0].centre_name }</span></h3>
                                                
                                                 { centre === 'empty' ? <button className='btn btn-xs btn-blue' data-toggle="modal" data-target="#scrollable-modal">Add to centre</button> : 
                                                <p className="text-muted mb-1 text-truncate">Current Centre</p> }
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                        </div>

                     <div className='row'>

                     <div className="col-lg-4 col-xl-4">
                                <div className="card-box text-center">
                                    <img src="../assets/images/users/user-1.jpg" className="rounded-circle avatar-lg img-thumbnail"
                                        alt="profile" />

                                    <h4 className="mb-0">{ stud.names }</h4>
                                    <p className="text-muted">STUDENT</p>

                                    <div className='row'>
                                  
                                        <div className='col-lg-4'>
                                                <h4>{stud.grade}</h4>
                                                <p>Grade</p>
                                        </div>

                                        <div className='col-lg-4'>
                                        <h4>{stud.profile_status !== 'Active' ? 'Pending' : stud.profile_status }</h4>
                                                <p>Profile Status</p>
                                            
                                        </div>

                                        <div className='col-lg-4'>
                                        <h4>{stud.academic_year}</h4>
                                                <p>Academic Year</p>
                                            
                                        </div>

                                        <div className='col-lg-12'>
                                        <hr />
                                        </div>
                                    </div>

                                    <button type="button" className="btn btn-success btn-xs waves-effect mb-2 waves-light" data-toggle="modal" data-target="#centermodal">Edit Information</button>
                                  

                                    <div className="text-left mt-3">
                                        <h4 className="font-13 text-uppercase">About Me :</h4>

                                        <div className="table-responsive">
                                          <table className="table mb-0">
                                            <tbody>
                                                <tr>
                                                <th>Gender</th>
                                                <td>{stud.gender}</td>
                                                </tr>

                                                <tr>
                                                <th>Date of Birth</th>
                                                <td>{stud.dob}</td>
                                                </tr>

                                                <tr>
                                                <th>Race</th>
                                                <td>{stud.race}</td>
                                                </tr>

                                                <tr>
                                                <th>Region</th>
                                                <td>{stud.region}</td>
                                                </tr>

                                                <tr>
                                                <th>Home Lang</th>
                                                <td>{stud.home_lang}</td>
                                                </tr>

                                                <tr>
                                                <th>Other Lang</th>
                                                <td>{stud.other_lang}</td>
                                                </tr>
                                              
                                                <tr>
                                                <th>ID Type</th>
                                                <td>{stud.id_type}</td>
                                                </tr>
                                                <tr>
                                                <th>{stud.id_type}</th>
                                                <td>{stud.stud_id_no}</td>
                                                </tr>

                                                <tr>
                                                <th>Address</th>
                                                <td>{stud.address}</td>
                                                </tr>
                                            </tbody>

                                            </table>

                                        </div>
                                     
                                      
                                    </div>

                                
                                </div> 

                             

                            </div> 

                            <div class="modal fade" id="centerRegistration" tabIndex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="myCenterModalLabel">Add Learner</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body">
                               

                            { <HandleCompleteFees datas={ms} /> }

                      </div>
                </div>
            </div>
             </div>

                            <div className="col-lg-8 col-xl-8">
                            
                              { stud.grade === null ? 


                              
                              <div className='card'>
                                <div className='card-body'>
                                      <div className='alert alert-danger'>
                                        This learner account is incomplete. Kindly click on<br /> the button below to complete learner profile
                                      </div>

                                         <div className='text-center'>
                                            <button className='btn btn-blue' data-toggle="modal" data-target="#centerRegistration">Complete Registration</button>
                                        </div>
                                    </div>
                              </div> : 

                            <div className="card">
                                    <div className="card-body">


                                        { addNewSubject !== false ?
                                         <>
                                                <div className="row mb-2">
                                            <div className="col-sm-4">
                                                <h4>Learner Subjects</h4>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="text-sm-right">
                                                  
                                                    <button type="button" className="btn btn-danger waves-effect waves-light mb-2" onClick={()=> setAddNewSubject(false)}>Back to Student Subject</button>
                                                </div>
                                            </div>

                                            

                                        </div>

                                        { addMoreSubject() }
                                        { extraCart.length > 0 ?
                                        <div className='row mt-3'>

                                            <div className='col-xl-6'></div>

                                            <div class="col-xl-6">
                                                <div class="border p-3 mt-4 mt-lg-0 rounded">
                                                    <h4 class="header-title mb-3">Order Summary</h4>

                                                    <div class="table-responsive">
                                                        <table class="table mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Grand Total :</td>
                                                                    <td>R { calculateAmountSubject() }</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Discount : </td>
                                                                    <td>0.00</td>
                                                                </tr>
                                                              
                                                                <tr>
                                                                    <td>Estimated Tax : </td>
                                                                    <td>R 0.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Total :</th>
                                                                    <th>R { calculateAmountSubject() }</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                 
                                                </div>
                           

                                                <div class="input-group mt-3">

                                               { lodSub !== false ? <p className='text-danger'>Processing. Please wait...</p> : null}
                  
                                                    <select type="text" class="form-control form-control-light" onChange={(e)=> setExtSub(e.target.value)}
                                                        aria-label="Recipient's username">
                                                     <option value={''}>Select Mode of Payment</option>
                                                     <option value={'debit'}>Debit learner parent wallet</option>
                                                     <option value={'allow'}>Allow learner parent to pay</option>
                                                     <option value={'already'}>Already paid for the subject</option>
                                                     </select>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-light" onClick={()=> handleAdminSubjectAddeds()} type="button">Apply</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div> : null }

                                        { alet !== null ? alet : null}
                                         
                                         </>
                                        
                                        :

                                        <>

                                    <div className="row mb-2">
                                            <div className="col-sm-4">
                                                <h4>Learner Subjects</h4>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="text-sm-right">
                                                  
                                                    <button type="button" className="btn btn-danger waves-effect waves-light mb-2" onClick={()=> setAddNewSubject(true)}>Add New Subject</button>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="table-responsive">

                                     
                                        <div id="success-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                                            <div class="modal-dialog modal-sm">
                                                <div class="modal-content modal-filled bg-danger">
                                                    <div class="modal-body p-4">
                                                        <div class="text-center">
                                                        <i class="dripicons-wrong h1 text-white"></i>
                                                            <h4 class="mt-2 text-white">Oop Snap!</h4>
                                                            <p class="mt-3 text-white">You about to delete Centre. all details concerning the centre will be lost </p>
                                                           
                                                            <button type="button" class="btn btn-light" data-dismiss="modal" onClick={()=> removeLearnerSubjectAdm('subject')}>Delete</button>
                                                            
                                                         
                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <table className="table table-centered table-nowrap table-hover mb-0">
                                                <thead>
                                                <tr>
                                                        <th>Subject Name</th>
                                                        <th>Subject ID</th>
                                                        <th>Price</th>
                                                        <th>Status</th>
                                                        <th>Category</th>
                                                        <th style={{width: 82}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { subject && subject.map((s, i)=>{
                                                         return (
                                                            <tr key={i}>
                                                            <td className="table-user">
                                                                 { s.subject_name }
                                                            </td>
                                                            <td>
                                                              { s.subject_id }
                                                            </td>
                                                            <td>
                                                                {s.price }
                                                            </td>
                                                            <td>
                                                               {s.status}
                                                            </td>
                                                            <td>
                                                               { s.date_added }
                                                            </td>
                                                            <td>
                                                              
                                                                <div className="action-icon" data-toggle="modal" data-target="#success-alert-modal" onClick={()=> setMSid(s.subject_id)} style={{ cursor:'pointer' }}> <i className="mdi mdi-delete"></i></div>

                                                            </td>
                                                        </tr>

                                                         )
                                                    })}
                                                   
                                                    </tbody>
                                                  
                                            </table>
                                          

                                          </div>

                                          </> 
                                          
                                          }


                                </div>
                            </div>
                                }


                            </div>


                     </div>

                     </> }
                        </div>
                        </div>
                    </div>
                    </div>
  )
}

export default ViewLearner