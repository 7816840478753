import React,{useState} from "react";
import axios from 'axios'
import $ from 'jquery';


function AccountStatus({datas}){
     const [mstatus, setMStatus] = useState();

     const updateUserStatus = (status) =>{
         let id = datas.id;
         let type = datas.type
        axios.post('/api-updatelearn-centre-status',{id,type,status},{
           headers:{'Content-Type':'application/json'}
        }).then((res)=>{
             if(res.data.mstate === 'success'){
              setMStatus(status)
             }
             
        }).catch((err)=>{
            console.log(err)
        })
     }


     const UserStatus = (status) =>{
      switch(status){
         case 'Active':
          if(mstatus === 'Active'){
            return(
              <button type="button" onClick={()=> updateUserStatus('Deactive')} className="btn btn-danger waves-effect btn-sm waves-light" style={{ marginRight:10 }}>Deactivare Account</button>
     
            )
           }else
            if(mstatus === 'Deactive'){

              return(
                <button type="button" onClick={()=> updateUserStatus('Active')} className="btn btn-success waves-effect btn-sm waves-light" style={{ marginRight:10 }}>Activate Account</button>
       
              )

            }else{
                return(
                  <button type="button" onClick={()=> updateUserStatus('Deactive')} className="btn btn-danger waves-effect btn-sm waves-light" style={{ marginRight:10 }}>Deactivate Account</button>
         
                )
            }
          break;
          case 'Deactive':

             if(mstatus === 'Deactive'){
              return(
                <button type="button" onClick={()=> updateUserStatus('Active')} className="btn btn-success waves-effect btn-sm waves-light" style={{ marginRight:10 }}>Activate Account</button>
       
              )
             }else
              if(mstatus === 'Active'){

                return(
                  <button type="button" onClick={()=> updateUserStatus('Deactive')} className="btn btn-danger waves-effect btn-sm waves-light" style={{ marginRight:10 }}>Deactivate Account</button>
         
                )

              }else{
                  return(
                    <button type="button" onClick={()=> updateUserStatus('Active')} className="btn btn-success waves-effect btn-sm waves-light" style={{ marginRight:10 }}>Activate Account</button>
           
                  )
              }
            
          
          break;
          default:
            if(mstatus === 'Active'){
              return(
                <button type="button" onClick={()=> updateUserStatus('Deactive')} className="btn btn-danger waves-effect btn-sm waves-light" style={{ marginRight:10 }}>Deactivate Account</button>
       
              )
             }else
              if(mstatus === 'Deactive'){

                return(
                  <button type="button" onClick={()=> updateUserStatus('Active')} className="btn btn-success waves-effect btn-sm waves-light" style={{ marginRight:10 }}>Activate Account</button>
         
                )

              }else{
                  return(
                    <button type="button" onClick={()=> updateUserStatus('Active')} className="btn btn-success waves-effect btn-sm waves-light" style={{ marginRight:10 }}>Activate Account</button>
           
                  )
              }
      }
}



    return (
      <>
      { UserStatus(datas.mStatus) }
      </>
    )
}


function ResetUserPass({datas}){

  const [password, setPassword] = useState('')
  const [notis, setNotis] = useState(null);
  const [load, setLoad] = useState(false);
  
    const randomNumberInRange = () => {
        let pass = 'TMP-'+Math.floor(5000 + Math.random() * 900000);

        setPassword(pass)
    };

    const generatePass = () => {
          setNotis(null)
            if(password === ''){
              setNotis(<div className={`alert alert-danger text-center`}>Please generate password</div>)
            }else
            {
          let uid = datas.pData.userid;
          let email = datas.pData.email;
           setLoad(true)
          axios.post('/api-admingenerate-pass',{password,uid,email},{
             headers:{'Content-Type':'application/json'}
          }).then((res)=>{
              setTimeout(()=>{
                setLoad(false)
                setNotis(<div className={`alert alert-${res.data.color} text-center`}>{res.data.message}</div>)
              },1000)
              
          }).catch((err)=>{
              console.log(err)
          })
        }
    }
  
     return(
      <div class="modal fade" id="resetPass" tabIndex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title" id="myCenterModalLabel">Generate Password</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
              </div>
              <div class="modal-body">
  
                     <div className='row'>
                      { notis === null ? null : <div className="col-xl-12 mb3">{notis}</div> }
  
                    
  
                      <div className='col-xl-7 mb-3'>
                          
                         <input type='text' className='form-control' onChange={(e)=> setPassword(e.target.value)} value={password}/></div>

                          <div className='col-xl-5 mb-3'> <button type="button" onClick={()=> randomNumberInRange()} className="btn btn-blue">Generate Password</button>
                      </div>
  
                    
  
                      <div className='col-xl-12 text-center'>
                            { load === false ?
                          <button type="button" onClick={()=> generatePass()} className='btn btn-blue'>Reset</button>
                           :
                           <button type="button" className='btn btn-blue' disabled>Please wait...</button>
                        
                            }
                       </div>
  
  
                     </div>
  
                </div>
          </div>
      </div>
  </div>
     )
      
}


function SuspendUsers({datas}){
    const [mstatus, setMstatus] = useState('')

    const updateUserStatus = (status) => {
            axios.get(`/api-adminpredictuseraccount/${datas.pid}/${status}/${datas.mtype}`,{
               headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                  if(res.data.status === 'success'){
                        setMstatus(status);
                  }
            }).catch((err)=>{
                console.log(err)
            })
    }

      const checkStatus = (status) =>{
            switch(status){
               case 'Suspend':
                if(mstatus === 'Suspend'){
                  return(
                    <button type="button" onClick={()=> updateUserStatus('Unsuspend')} className="btn btn-success waves-effect btn-sm waves-light">Unsuspend Account</button>
           
                  )
                 }else
                  if(mstatus === 'Unsuspend'){

                    return(
                      <button type="button" onClick={()=> updateUserStatus('Suspend')} className="btn btn-danger waves-effect btn-sm waves-light">Suspend Account</button>
             
                    )

                  }else{
                      return(
                        <button type="button" onClick={()=> updateUserStatus('Unsuspend')} className="btn btn-success waves-effect btn-sm waves-light">Unsuspend Account</button>
               
                      )
                  }
                break;
                case 'Unsuspend':

                   if(mstatus === 'Suspend'){
                    return(
                      <button type="button" onClick={()=> updateUserStatus('Unsuspend')} className="btn btn-success waves-effect btn-sm waves-light">Unsuspend Account</button>
             
                    )
                   }else
                    if(mstatus === 'Unsuspend'){

                      return(
                        <button type="button" onClick={()=> updateUserStatus('Suspend')} className="btn btn-danger waves-effect btn-sm waves-light">Suspend Account</button>
               
                      )

                    }else{
                        return(
                          <button type="button" onClick={()=> updateUserStatus('Suspend')} className="btn btn-danger waves-effect btn-sm waves-light">Suspend Account</button>
                 
                        )
                    }
                  
                
                break;
                default:
                  if(mstatus === 'Suspend'){
                    return(
                      <button type="button" onClick={()=> updateUserStatus('Unsuspend')} className="btn btn-success waves-effect btn-sm waves-light">Unsuspend Account</button>
             
                    )
                   }else
                    if(mstatus === 'Unsuspend'){

                      return(
                        <button type="button" onClick={()=> updateUserStatus('Suspend')} className="btn btn-danger waves-effect btn-sm waves-light">Suspend Account</button>
               
                      )

                    }else{
                        return(
                          <button type="button" onClick={()=> updateUserStatus('Suspend')} className="btn btn-danger waves-effect btn-sm waves-light">Suspend Account</button>
                 
                        )
                    }
            }
      }

  return (
     <>
     {checkStatus(datas.mStatus)}    
     </>
  )
}


function HandleCompleteFees({datas}){
   const [showSub, setShowSub] = useState(false)
   const [grades, setGrades] = useState();
   const [term, setTerm] = useState('');
   const [notis, setNotis] = useState(null);
   const [gPrice, setGPrice] = useState([])
   const [subDatas, setSubDatas] = useState([])
   const [load, setLoad] = useState(false);
   const [year, setYear] = useState();
   const [durationPrice, setDurationPrice] = useState(0);
   const [cartItems, setCartItems] = useState([])
   const [methodPay, setMethodPay] = useState('');
   const [durationTime, setDurationTime] = useState();
   const [loader, setLoader] = useState(false);


 const handleProcessDatas = () => {
            setNotis(null)

       if(cartItems.length === 0){
         setNotis(<div className="alert alert-danger text-center">Please kindly select student subject</div>)
       }else
       {
         if(!methodPay.trim()){
          setNotis(<div className="alert alert-danger text-center">Please kindly method of payment</div>)
         }else
         {
             setLoader(true)
             let total = calculateCart('all');
             let sid = datas.studID;
             let pid = datas.pid;
              let sub_price = calculateCart('sub');
            let udatas ={methodPay,total,durationPrice,durationTime,year,cartItems,grades,term,sid,pid,sub_price}

                axios.post('/api-adminprocess-student-subothers',udatas,{
                   headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                    setTimeout(()=>{
                      setNotis(<div className={`alert alert-${res.data.color} text-center`}>{res.data.message}</div>)
                      setLoader(false) 
                    },1000)
                    
                }).catch(err=>{
                    console.log(err)
                })
         }
       }

 }

   const checkPrice = (price) => {
    if(price === null || price === ''){
          return 0;
    }else{
          return price;
    }
}

const calculateCart = (type) => {
  let total = 0;

   if(cartItems.length > 0){
    cartItems.map((s) => {
      total = total + parseInt(s.price);
})
   }else
   {
      total = 0;
   }

   let mtotal;

    if(type === 'sub'){
          mtotal = total;
    }else
    if(type === 'duration'){
          mtotal = parseInt(durationPrice);
      }else
      {
       mtotal = parseInt(durationPrice) + total;
      }


  return mtotal;
   

}




   const removeSubjectFromCart = (sid) =>{
    
    const updatedCartItems = cartItems.filter((item) => item.id !== sid);

            setCartItems(updatedCartItems);
}


const checkIfSubjectExistInCart = (sid) =>{
  if(cartItems.find((item) => item.id === sid)){
      return true;
  }
}


const handleAddSubject = (sid,price,names,cate) =>{
             
  const newItem = {
       id:sid,
       price:price,
       names:names,
       cate:cate
  }

  setCartItems((prevItems)=>[...prevItems, newItem])

}


   const getDuration = (ey) =>{
              setShowSub(false)
            if(!ey.trim()){
              setNotis(<div className="alert alert-danger text-center">Please select duration fee</div>)
               
            }else
              if(gPrice.length === 0){
                setNotis(<div className="alert alert-danger text-center">No price fee the selected duration</div>)
           
              }else
            {
              setLoad(true)
               setTimeout(()=>{
                setLoad(false)
                setShowSub(true)
                if(ey === 'Yearly'){
                 setDurationPrice(gPrice[0].year)
                 setDurationTime('Yearly')
             }else
             {
                 setDurationPrice(gPrice[0].term)
                 setDurationTime('Termly')
             }
                 
               },1000)
            }
          
   }

   const fetchSubjectGrade = (yr) => {
                setNotis(null)
               
              
                if(!term.trim() || !grades.trim() || !yr.trim()){
                    setNotis(<div className="alert alert-danger text-center">Please select grade and current term</div>)
               
                  }else{
            axios.get('/api-fetchadminsubject/'+grades+'/'+term+'/'+yr,{
                headers:{'Content-Type':'application/json'}
            }).then((res)=>{
               
             
                  if(res.data.status === 'error'){
                    setNotis(<div className={`alert alert-${res.data.color} text-center`}>{res.data.message}</div>)
                    setSubDatas([])
                  
                    
                }else
                 if(res.data.gprice === 'empty'){
                  setNotis(<div className='alert alert-danger text-center'>No duration price for the select grade and terms</div>)
                   setYear('')
                 }else

                {
                    setNotis(null)
                    setSubDatas(res.data.subject);
                   setGPrice(res.data.gprice);
                   setYear(yr)
                 

                   
                 
                }
               
             

            }).catch((err)=>{
                console.log(err)
            })

                }
   }



  const subdataOthers = () => {
    return(
        <div className='row'>
       
            

             { load === false ? null : <div className="col-xl-12"><i className="text-danger mb-2">Loading  datas. Please wait...</i></div>}

        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Grade</label>
            <select type='text' className='custom-select' onChange={(e)=> setGrades(e.target.value)}>
                <option value={''}>Select grade</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
                </select>
            </div>

        </div>


        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Select Current Term</label>
            <select type='text' className='custom-select' onChange={(e)=> setTerm(e.target.value)} >
                <option value={''}>Choose</option>
                <option value={1}>Term 1</option>
                <option value={2}>Term 2</option>
                <option value={3}>Term 3</option>
                <option value={4}>Term 4</option>
               
                </select>
            </div>

        </div>

        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Academic Year</label>
            <select type='text' className='custom-select' value={year} onChange={(e)=> fetchSubjectGrade(e.target.value)} >
                <option value={''}>Chosse</option>
                <option value={2022}>2022</option>
                <option value={2023}>2023</option>
                <option value={2024}>2024</option>
                <option value={2025}>2025</option>
                <option value={2026}>2026</option>
                <option value={2027}>2027</option>
                <option value={2028}>2028</option>
                <option value={2029}>2029</option>
                <option value={2030}>2030</option>
                <option value={2031}>2031</option>
                 </select>
            </div>

        </div>

        <div className='col-xl-6'>
            <div className='mb-3'>
            <label>Select Duration Fee</label>
            <select type='text' className='custom-select'  onChange={(e) => getDuration(e.target.value)} >
                <option value={''}>Choose</option>
                <option value={'Yearly'}>Yearly</option>
                <option value={'Termly'}>Termly</option>
           </select>
            </div>

        </div>
      
        { showSub === false ? null :
         <>

     

        <div className='col-xl-12'>
             <h5>Add subject for grade </h5>

             <div className="table-responsive">

                { subDatas.length === 0 ? <div className="alert alert-danger text-center">No subject available for the selected grade and year </div>
                :
                     <table className="table mb-0">

                              <thead className="thead-dark">
                                    <tr>
                                        <th>#</th>
                                        <th> Name</th>
                                        <th>Category</th>
                                        <th>Price</th>
                                        <th>Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                  { subDatas && subDatas.map((s,i)=>{
                                      return(
                                         <tr key={i}>
                                            <td>{i+1}</td>
                                           <td>{s.names}</td>
                                           <td>{s.cate}</td>
                                           <td>{checkPrice(s.price)}</td>
                                           <td className="text-end">
                                           { checkIfSubjectExistInCart(s.subject_id) === true ? 
                                                                                   <button onClick={() => removeSubjectFromCart(s.subject_id)}  type="button" className="btn btn-danger btn-sm btn-rounded">
                                                                                   Remove
                                                                                   </button>
                                                                                   :

                                                                                   <button onClick={() => handleAddSubject(s.subject_id, checkPrice(s.price), s.names, s.cate)} type="button" className="btn btn-primary btn-sm btn-rounded">
                                                                                  Add Subject
                                                                                   </button>

                                                                                 
                                                                        }
                                           </td>
                                         </tr>
                                      )
                                  })}
                                  
                                 </tbody>

                     </table> }

                        
             </div>

        </div>


    


        <div className='col-xl-12 mt-4'>
            <div className='mb-3'>
            <label>Method of payment</label>
            <select type='text' className='custom-select' onChange={(e)=> setMethodPay(e.target.value)} >
                <option value={''}>Choose</option>
                <option value={'Generate-unpaid'}>Generate unpaid invoice for learner parent to pay</option>
                <option value={'Generate-debit'}>Generate paid invoice and debit parent wallet</option>

           </select>
            </div>

        </div>

        <div className="col-xl-12">

        <table className="table table-nowrap">
                                               <thead>
                                                   <tr>
                                                       <th style={{width: 70}}></th>
                                                       <th>Details</th>
                                                       <th className="text-end">Price</th>
                                                   </tr>
                                               </thead>
                                               <tbody>
                                                   <tr>
                                                       <td></td>
                                                       <td>{ cartItems.length > 1 ? <div>  { cartItems.length } Subjects</div> : <div>  { cartItems.length } Subject </div> } </td>
                                                       <td className="text-end">R { calculateCart('sub') }</td>
                                                   </tr>

                                              
                                                   <tr>
                                                       <td></td>
                                                       <td>{ durationTime }</td>
                                                       <td className="text-end">R  { calculateCart('duration') }</td>
                                                   </tr>
                                                        
                                                        

                                                 
                                                   <tr>
                                                       <td colspan="2" className="text-end">Sub Total</td>
                                                       <td className="text-end">R { calculateCart('all') }</td>
                                                   </tr>
                                                  
                                                   <tr>
                                                       <td colspan="2" className="border-0 text-end">
                                                           <strong>Total</strong></td>
                                                       <td className="border-0 text-end"><h4 className="m-0">R { calculateCart('all') }</h4></td>
                                                   </tr>
                                               </tbody>
                                           </table>

        </div>

        <div className='col-xl-12'>
        { notis !== null ? notis : null}
        </div>

        <div className='col-xl-12 mt-4 text-center'>
             { loader === false ?
            <button className='btn btn-blue' onClick={ ()=> handleProcessDatas()}>Submit</button>
            :   <button className='btn btn-blue' disabled>Please wait...</button>
             }
        </div>
        </> }


     </div>
    )
}

 return (
     <>
     { subdataOthers() }
     </>
 )
    
}


function HandleCentres({datas}){
    const [nice, setNice] = useState();

    const moks = (i,dur) => {
      if(dur === 1){
      $("#add-"+i).prop('disabled',true).html('Please wait...');
      }else
       if(dur === 2){
        $("#add-"+i).prop('disabled',false).html('Add Student');
       }
      {
        $("#add-"+i).prop('disabled',false).html('Student Added');
      }
}

const handleAddStudent = (cid,tid,i,cname) =>{
         moks(i,1)
         let sid = datas.stid;
      axios.post('/api-changestudcentre',{sid,cid,tid,cname},{
          headers:{'Content-Type':'application/json'}
      }).then((res)=>{
          setTimeout(()=>{
              if(res.data.status === 'error'){
                moks(i,2)
              }else{
                moks(i,4)
              }
          },2000)
          setNice(res.data)
      }).catch((err)=>{
          console.log(err)
      })
}
 
     return(
        <>

       { datas.centres.length > 0 ? datas.centres && datas.centres.map((c,i)=>{
            return (
    <div className="col-xl-12" key={i}>
      <div className="widget-rounded-circle card-box">
           <div className="row align-items-center">
               <div className="col-auto">
                  <div className="avatar-lg mr-1 rounded-circle bg-soft-pink border-pink border">
                                                <i className="fe-users font-22 avatar-title text-pink"></i>
                                            </div>
               </div>
               <div className="col">
                   <h5 className="mb-1 mt-2">{ c.centre_name }</h5>
                   <p className="mb-2 text-muted">{ c.region }</p>
               </div>
               <div className="col">
                  { datas.scentID === c.centre_id ? <button className="btn btn-xs btn-success" disabled>Added already</button> :
                   <button className="btn btn-xs btn-blue" id={`add-${i}`} onClick={() => handleAddStudent(c.centre_id,c.tutor_id,i,c.centre_name)}>Add Student</button>
                  }
               </div>
           </div>
       </div> 
   </div>
            )
        }) : null
       } </>
     )
}


function HandleUserProfile({datas}){

   const [names, setNames] = useState(datas.pData.names);
   const [phone, setPhone] = useState(datas.pData.phone);
   const [whatsapp, setWhatsappID] = useState(datas.pData.whatsappID);
   const [address, setAddress] = useState(datas.pData.address);
   const [notis, setNotis] = useState(null);
   const [load, setLoad] = useState(false)


   const validate = () =>{
       let valid = true;
         
         if(!names.trim() || !phone.trim() || !whatsapp.trim() || !whatsapp.trim()){
             valid = false;
         }

          return valid;
   }

   const handleSubmit = () =>{
      if(!validate()){
          setNotis(<div className="alert alert-danger text-center">Make sure form input are not empty</div>)
      }else
      {
            setLoad(true)
            let uid = datas.pData.userid
            let udatas = {names,uid,phone,address,whatsapp}
             axios.post('/api-admin-updateuser-datas',udatas,{
               headers:{'Content-Type':'application/json'}
             }).then((res)=>{
                  setTimeout(()=>{
                    setLoad(false)
                      setNotis(<div className={`alert alert-${res.data.color} text-center`}>{ res.data.message }</div>)
                  },1000)
             }).catch((err)=>{
                console.log(err)
             })
      }
   }

   // console.log(datas.pData.names)

     return (
      <div class="modal fade" id="centerUserProfile" tabIndex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title" id="myCenterModalLabel">Update Profile</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
              </div>
              <div class="modal-body">
  
                     <div className='row'>
                     
                      { notis === null ? null :  <div className='col-xl-12 mb-3'>{notis}</div>}
  
                      <div className='col-xl-12 mb-3'>
                        
                      <label>Name</label>
                         <input type='text' className='form-control' onChange={(e)=> setNames(e.target.value)} defaultValue={names} />
                      </div>


                      <div className='col-xl-6 mb-3'>
                        
                        <label>Phone</label>
                           <input type='text' className='form-control' onChange={(e)=> setPhone(e.target.value)} defaultValue={phone}  />
                        </div>

                      <div className='col-xl-6 mb-3'>
                        
                        <label>Whatsapp</label>
                           <input type='text' className='form-control' onChange={(e)=> setWhatsappID(e.target.value)} defaultValue={whatsapp}  />
                        </div>
  
                    
  
                      <div className='col-xl-12 mb-3'>
                          <label>Address</label>
                         <textarea className='form-control' onChange={(e)=> setAddress(e.target.value)} >{address}</textarea>
                      </div>
  
                        <div className="col-xl-12 text-center">
                           { load === false ? 
                          <button className="btn btn-blue" onClick={()=> handleSubmit()}>Update User Profile</button>
                          :
                          <button className="btn btn-blue" disabled>Please wait...</button>
                           }
                        </div>
  
  
                     </div>
  
                </div>
          </div>
      </div>
  </div>
     )

}


function HandleWallet({datas}){
        const [amt, setAmt] = useState('');
        const [purpose, setPurpose] = useState('');
        const [type, setType] = useState('');
        const [notis, setNotis] = useState(null);
        const [load, setLoad] = useState(false)

      const handleWalletProcess = () => {
          setNotis(null)
         if(!amt.trim() || !purpose.trim() || !type.trim()){
              setNotis(<div className="alert alert-info text-center">Please make sure form input are not empty</div>)
         }else
         {
          let uid = datas.pid
            setLoad(true)
          const send ={amt,purpose,uid,type}
           axios.post('/api-admin-process-wallettrans',send,{

           }).then((res)=>{
              setTimeout(()=>{
                 setLoad(false)
                setNotis(<div className={`alert alert-${res.data.color} text-center`}>{res.data.message}</div>)
              },1000)
           }).catch((err)=>{
              console.log(err)
           })
           

         }
          
      }
   return (
    <div class="modal fade" id="centerwallet" tabIndex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myCenterModalLabel">Add / Deduct Wallet</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">

                   <div className='row'>
                    { notis === null ? null : <div className="col-xl-12 mb3">{notis}</div> }

                    <div className='col-xl-12 mb-3'>
                      
                    
                        <select className='custom-select' onChange={(e)=> setType(e.target.value)}>
                            <option value={''}>Choose option</option>
                            <option value={'debit'}>Debit Fund</option>
                            <option value={'add'}>Add Fund</option>
                        </select>
                    </div>

                    <div className='col-xl-12 mb-3'>
                        <label>Amount</label>
                       <input type='text' className='form-control' onChange={(e)=> setAmt(e.target.value)} />
                    </div>

                    <div className='col-xl-12 mb-3'>
                        <label>Purpose</label>
                       <textarea className='form-control' onChange={(e)=> setPurpose(e.target.value)} ></textarea>
                    </div>

                    <div className='col-xl-12 text-center'>
                        { load === false ?
                        <button className='btn btn-blue' onClick={()=> handleWalletProcess() }>Submit</button>
                        :
                        <button className='btn btn-blue' disabled>Please wait...</button>
                        }
                     </div>


                   </div>

              </div>
        </div>
    </div>
</div>
   )
}

function HandleLoad({datas}){
    
return (
<>
 <div className={`spinner-border m-2 ${datas.type}`} role="status">
    <span className="sr-only">Loading...</span>
 </div>
</>
)

}

function CaptureRating({mdatas}){

    const getScoreRating = () =>{

        let rate;
       if(mdatas.type === 'percent'){
             
           switch(mdatas.score){
               case '7':
                     rate = '80% - 100%'
                 break;
               case '6':
                 rate = '70% - 79%'
                 break;
               case '5':
                 rate = '60% - 69%'
                 break;
                case '4':
                 rate = '50% - 59%'
                break;
                case '3':
                 rate = '40% - 49%'
                 break;
                 case '2':
                   rate = '30% - 39%'
                   break;
                case '1':
                 rate = '0% - 29%'
                  break;
                  default:
                   rate = 'N/A'
           }
       
          
       }else{
       switch(mdatas.score){
         case '7':
               rate = 'A+'
           break;
         case '6':
           rate = 'A'
           break;
         case '5':
           rate = 'B'
           break;
          case '4':
           rate = 'C'
          break;
          case '3':
           rate = 'D'
           break;
           case '2':
             rate = 'E'
             break;
          case '1':
           rate = 'F'
            break;
            default:
             rate = 'N/A'
       }
       
       
       }
       
       return (
        <>
        {rate}
        </>
       )
       
    
       
       }


        return (
            <>
            { getScoreRating() }
            </>
        )
       

}


  

function NotifyUsers({datas}){
        
      const handleErr = () => {
                    let eros ="";
                switch(datas.type){
                     case 'danger':
                          eros = <div className="alert alert-danger text-center">{datas.message} </div>
                        break;
                    case 'success':
                        eros = <div className="alert alert-success text-center">{datas.message} </div>
                        break;
                    case 'info':
                        eros = <div className="alert alert-info text-center">{datas.message} </div>
                        break;
                        default:
                }

                 return (
                    <>
                    {eros}
                    </>
                 )
      }
    

        return (
            <>
            { handleErr()}
            </>
        )
}

export {HandleLoad, NotifyUsers,
   CaptureRating, HandleCentres, HandleCompleteFees,
    HandleWallet, SuspendUsers, HandleUserProfile, ResetUserPass, AccountStatus}