import React,{useState, useEffect, useMemo} from 'react'
import axios from 'axios'
import {useHistory} from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { AccountCircle, Delete } from '@mui/icons-material';
import Head from '../Nav/Head';



function Student() {
     const [userData, setUserData] = useState([]);
     const [uid, setUid] = useState();
     const [reload, setReload] = useState(false);
     const history = useHistory();


     const columns = useMemo(
      () => [
        {
          accessorKey: 'names', //access nested data with dot notation
          header: 'Full Name',
          size: 150,
        },
        {
          accessorKey: 'grade',
          header: 'Grade',
          size: 150,
        },
        {
          accessorKey: 'gender', //normal accessorKey
          header: 'Gender',
          size: 200,
        },
        {
          accessorKey: 'academic_year',
          header: 'Academic Year',
          size: 150,
        },
        {
          accessorKey: 'stud_userid',
          header: 'User ID',
          size: 150,
        },

        {
          accessorKey: 'reg_prog',
          header: 'Registration Status',
          size: 150,
        },
      ],
      [],
    );

    const showWarning =() =>{
      return(
        <div id="success-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-sm">
            <div class="modal-content modal-filled bg-danger">
                <div class="modal-body p-4">
                    <div class="text-center">
                    <i class="dripicons-wrong h1 text-white"></i>
                        <h4 class="mt-2 text-white">Oop Snap!</h4>
                        <p class="mt-3 text-white">You about to delete Learner, all details about this learner will be lost. </p>
                       
                        <button type="button" onClick={()=> removeUser('student')} class="btn btn-light" data-dismiss="modal">Delete</button>
                        
                     
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
      )
   }


   const removeUser = (usr) =>{
    const datas = {usr,uid}
    axios.post('/api-removeuser-platform',datas,{
       headers:{'Content-Type':'application/json'}
    }).then((res)=>{
        if(res.data.status === 'success'){
           setReload(true)
        }
    }).catch((err)=>{
        console.log(err)
    })
}

const deleteUsers = (id) =>{
      setUid(id)
    document.getElementById('showmodal').click()
    
}



    useEffect(()=>{
            axios.get('/api-getall-users/student',{
                headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                    setUserData(res.data.udatas);
                
            }).catch((err)=>{
                 console.log(err)
            })

    },[reload])

  return (
  
    <div id="wrapper">
    <Head />
    <div className="content-page">
 <div className="content">
 <div className="container-fluid">

 <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                 
                   
                 
                  </div>
                </div>
               
              </form>
            </div> 
            <h4 className="page-title">All Student</h4>
          </div>
        </div>
                 </div>

                 { showWarning() }

               <div className='row'>
                  <div className='col-lg-12'>

                { <MaterialReactTable columns={columns} data={userData}
                enableRowActions
                renderRowActionMenuItems={({ row }) => [
                  <MenuItem key="edit" onClick={() => history.push('/student/'+row.original.stud_userid)}>
                     <ListItemIcon>
                 <AccountCircle fontSize='small' />
          </ListItemIcon>
                    View
                  </MenuItem>,
                  <MenuItem key="delete" onClick={() => deleteUsers(row.original.stud_userid)}>
                     <ListItemIcon>
                 <Delete fontSize='small' />
                </ListItemIcon>
                    Delete
                  </MenuItem>,
                ]}
                /> }
               <div id='showmodal' data-toggle="modal" data-target="#success-alert-modal" style={{ display:'none' }}></div>
                    </div>
                  </div>
               </div>
             </div>
         </div>
    </div>
       
            
  )
}

export default Student