import React,{useMemo, useEffect, useState} from 'react'
import axios from 'axios'
import {useHistory} from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { AccountCircle, Delete, Edit } from '@mui/icons-material';
import {handleLoad, NotifyUsers, CaptureRating } from '../auth/ErrorHandler'
import Head from '../Nav/Head';

function ViewQuestions() {
     const [loading, setLoading] = useState(false);
     const [stud, setStud] = useState([]);
     const [studData, setStudData] = useState();
     const [viewCapture, setViewCapture] = useState(false)
     const [hideshow, setHideShow] = useState(false);
     const [subject, setSubject] = useState([]);
     const [loadBut, setLoadBut] = useState(false);
     const [cate, setCate] = useState('');
     const [sub, setSub] = useState('');
     const [year, setYear] = useState('');
     const [term, setTerm] = useState('');
     const [notify, setNotify] = useState(null);
     const [captureData, setCaptureData] = useState([]);
  

     const NotifyMe = (type,message) =>{
        const datas = {type,message}
         return (
            <NotifyUsers datas={datas} />
         )
    }

    const scoreRate = (type,score) =>{
          const datas = {type,score}
          return(
            <>
            <CaptureRating mdatas={datas} />
            </>
          )
    }


     const handleViewCapture = () => {
                setNotify(null)
            if(!year.trim() || !term.trim()){
                    setNotify(NotifyMe('danger','Please make sure your select all the category'))
            }else
            {
                 setLoadBut(true)
                  let studID = studData.stud_userid;
                  let grade = studData.grade;
                  let type = 'view';
                  const datas = {cate:'none',sub:'none',year,studID,grade,term,type}
                  axios.post('/api-adminfetchcapture',datas,{
                     headers:{'Content-Type':'application/json'}
                  }).then((res)=>{
                            setTimeout(()=>{
                                  if(res.data.status === 'allow'){
                                       
                                        setCaptureData(res.data.captureData);
                                  }else
                                  {
                                        setCaptureData([])
                                      setNotify(NotifyMe(res.data.color,res.data.message))
                                  }
                                 setLoadBut(false)
                            },1000)
                  }).catch((err)=>{
                        console.log(err)
                  })
            }
     }


     const CaptureStudent = () => {

         return (
            <>
              <div className="col-lg-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-lg-12">
                         { notify !== null ? notify : null }
                      <form className="form-inline">
                       
                        <div className="form-group mx-sm-3 ">

                       

                        <select className="custom-select" onChange={(e)=> setTerm(e.target.value)} id="status-select" style={{marginLeft:20}} >
                            <option value=''> Term</option>
                            <option value={1}> Term 1</option>
                            <option value={2}> Term 2</option>
                            <option value={3}> Term 3</option>
                            <option value={4}> Term 4</option>
                         
                          </select>

                        <select className="custom-select " onChange={(e)=> setYear(e.target.value)} id="status-select" style={{marginLeft:20}} >
                          <option value=''>Year</option>
                             
                          <option value={2020}>2020</option>
                          <option value={2021}>2021</option>
                          <option value={2022}>2022</option>
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                          <option value={2031}>2031</option>
                          </select>

                        
                            { loadBut === false ? 
                          <button type="button" style={{marginLeft:10}} onClick={()=> handleViewCapture()} className="btn btn-primary waves-effect waves-light" ><i className="mdi mdi-eye mr-1" /> view to capture</button>
                                : 
                                <button type="button" style={{marginLeft:10}} className="btn btn-primary waves-effect waves-light" disabled><i className="mdi mdi-eye mr-1" /> Please wait...</button>
                            }
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-2">
                      <div className="text-lg-right mt-3 mt-lg-0">
                        
                        
                      </div>


                   
                    </div>
                  </div> 
                </div> 
              </div> 

              
              <div className='col-lg-12'>
                { captureData.length > 0 ?
                <div className='card'>
                    <div className='card-body'>
                        <h5>Assessment View of { studData.names }</h5>
                        <hr />
                     
                    <div className="table-responsive">
                    <table className="table mb-0">
                                 <thead className="thead-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Subject Name</th>
                                                <th>Grade</th>
                                                <th>Term</th>
                                                <th>Score</th>
                                                <th>Score Rating</th>
                                                <th>Score Percentage</th>
                                             
                                            </tr>
                                            </thead>
                                            <tbody>

                                                { captureData && captureData.map((c,i)=>{
                                                     return (
                                                        <tr key={i}>
                                                        <td>{1+i}</td>
                                                            <td>{c.subjectName}</td>
                                                            <td>{c.grade}</td>
                                                            <td>{c.term}</td>
                                                            <td>{ c.score }</td>
                                                            <td>{ c.score}</td>
                                                            <td>{ scoreRate('percent',c.score)}</td>
                                                          
                                                        </tr>
                                                     )
                                                })}
                                              
                                            </tbody>
                        </table>

                  

                     </div>
                  
                    </div>
                </div> : null }

              </div>
              </>
         
         )

     }
  
     const viewStudent = (studID) =>{ 
        axios.get('/get-admin-student/'+studID,{
            headers:{'Content-Type':'application/json'}
       }).then((res)=>{
               setTimeout(()=>{
                    setStudData(res.data.stud[0])
                    setSubject(res.data.subData);
                     setViewCapture(true)
                     setHideShow(true)
               },500)
       }).catch((err)=>{
           console.log(err)
       })
     }

     const columns = useMemo(
        () => [
          {
            accessorKey: 'names', //access nested data with dot notation
            header: 'Full Name',
            size: 150,
          },
          {
            accessorKey: 'term',
            header: 'Current Term',
            size: 150,
          },
          {
            accessorKey: 'grade',
            header: 'Grade',
            size: 150,
          },
          {
            accessorKey: 'academic', //normal accessorKey
            header: 'Academic Year',
            size: 200,
          },
          {
            accessorKey: 'centre',
            header: 'Centre',
            size: 150,
          },
          {
            accessorKey: 'profile',
            header: 'Profile Status',
            size: 150,
          },
          {
            accessorKey: 'due_date',
            header: 'Due Date',
            size: 150,
          },
        ],
        [],
      );
  

    useEffect(()=>{
        axios.get('/get-admin-student/all',{
             headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                setTimeout(()=>{
                     setStud(res.data.stud)
                     setLoading(true)
                },1000)
        }).catch((err)=>{
            console.log(err)
        })

    },[])
  return (
    <div id="wrapper">
    <Head />
    <div className="content-page">
 <div className="content">
 <div className="container-fluid">
    { loading === false ? <p>Loading Content. Please wait...</p> :

                        <>
                         <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                 
                   
                 
                  </div>
                </div>
               
              </form>
            </div>
            { viewCapture === false ?
            <h4 className="page-title">Select Student</h4>
            :  <h4 className="page-title">View Assessment of { studData.names }</h4> }
          </div>
        </div>
                 </div>
                    <div className='row'>
                            { viewCapture === false ? null : 
                              CaptureStudent()
                            }
                        
                            { hideshow === false ?
                        <div className='col-lg-12'>
                    <MaterialReactTable columns={columns} data={stud}
                    enableRowActions
                    renderRowActionMenuItems={({ row }) => [
                    <MenuItem key="edit" onClick={() => viewStudent(row.original.studID)}>
                        <ListItemIcon>
                    <AccountCircle fontSize='small' />
                    </ListItemIcon>
                        View to Capture
                    </MenuItem>,
                   
                    ]}
                    /></div> : null }
                    
                    </div></> }
    
    


    </div>
    </div>
    </div>
    </div>
  )
}

export default ViewQuestions