import React,{useEffect, useState} from 'react'
import axios from 'axios'
import {  BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Authenticate from './component/auth/Authenticate';
import ProtectRout from './component/auth/ProtectRout';
import Dashboard from './component/usersdata/Dashboard'
import Student from './component/usersdata/Student'
import Parent from './component/usersdata/Parent';
import Tutor from './component/usersdata/Tutors';
import ViewParent from './component/usersdata/ViewParent'
import Head from './component/Nav/Head';
import ViewLearner from './component/usersdata/ViewLearner';
import ViewTutor from './component/usersdata/ViewTutor';
import ViewCentre from './component/usersdata/ViewCentre';
import Subject from './component/others/Subjects';
import UploadQuestion from './component/academic/UploadQuestion';
import CaptureQuestion from './component/academic/CaptureQuestion';
import ViewQuestion from './component/academic/ViewQuestions';
import TermFinal from './component/academic/TermFinal';
import Fee from './component/others/Fee';
import Invoice from './component/others/Invoice';
import GenerateInvoice from './component/others/GenerateInvoice';
import Support from './component/others/Support';
import Request from './component/others/Request';
import Settings from './component/others/Settings';

const live_server = 'https://horion.co.za';
const local_server = 'http://localhost:3001'; 

axios.defaults.baseURL = live_server;
axios.defaults.withCredentials = true;


function App() {

  const [auth, setAuth] = useState(false)


  const controlBodyAdmin = () =>{
          return(
      
            <Router>
        
              <Switch>
                 <ProtectRout exact path='/' Component={Dashboard} isAuth={auth}/>
                 <ProtectRout exact path='/dashboard' Component={Dashboard} isAuth={auth}/>
                 <ProtectRout exact path='/student' Component={Student} isAuth={auth} />
                 <ProtectRout exact path='/parents' Component={Parent} isAuth={auth} />
                 <ProtectRout exact path='/tutors' Component={Tutor} isAuth={auth} />
                 <ProtectRout exact path='/tutors/:uid' Component={ViewTutor} isAuth={auth} />
                 <ProtectRout exact path='/parents/:uid' Component={ViewParent} isAuth={auth} />
                 <ProtectRout exact path='/student/:sid' Component={ViewLearner} isAuth={auth} />
                 <ProtectRout exact path='/centres/:cid' Component={ViewCentre} isAuth={auth} />
                 <ProtectRout exact path='/subject' Component={Subject} isAuth={auth} />
                 <ProtectRout exact path='/questionupl' Component={UploadQuestion} isAuth={auth} />
                 <ProtectRout exact path='/capture' Component={CaptureQuestion} isAuth={auth} />
                 <ProtectRout exact path='/assessment-summary' Component={ViewQuestion} isAuth={auth} />
                 <ProtectRout exact path='/term-final' Component={TermFinal} isAuth={auth} />
                 <ProtectRout exact path='/fee-setup' Component={Fee} isAuth={auth} />
                 <ProtectRout exact path='/all-invoice' Component={Invoice} isAuth={auth} />
                 <ProtectRout exact path='/generate-invoice' Component={GenerateInvoice} isAuth={auth} />
                 <ProtectRout exact path='/support' Component={Support} isAuth={auth} />
                 <ProtectRout exact path='/request' Component={Request} isAuth={auth} />
                 <ProtectRout exact path='/settings' Component={Settings} isAuth={auth} />

              </Switch>
            </Router>
          
          )
  }

  const authenticateAdmin = () =>{
          return (
            
            <Router>
              <Switch>
                 <Route exact path='/' component={Authenticate} />
                 <Route exact path='/auth' component={Authenticate} />
              </Switch>
            </Router>
            
          )
  }

  const detectAdmin = () => {
         if(auth){
            return(
                <>
              
                 { controlBodyAdmin() }
                </>
            )
         }else
         {
             return(
               <>
               { authenticateAdmin() }
               </>
             ) 
         }
  }

  useEffect(()=>{
     axios.get('/api-adminauth-check',{
      headers:{'Content-Type':'application/json'}
     }).then((res)=>{
          if(res.data.status === 'allow'){
                setAuth(true)
          }else
          {
              setAuth(false)
          }
     }).catch(err =>{
       console.log(err)
     })
  },[auth])

  return (
    <div>
       { detectAdmin() }
    </div>
  );
}

export default App;
