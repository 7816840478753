import React,{useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios'
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { AccountCircle, Delete } from '@mui/icons-material';
import { NotifyUsers, SuspendUsers, AccountStatus } from '../auth/ErrorHandler';
import Head from '../Nav/Head';

function ViewCentre() {
      const {cid} = useParams();
      const [loading, setLoading] = useState(false);
      const [centre, setCentre] = useState();
      const [stud, setStud] = useState([]);
      const [sStatus, setSStatus] = useState();
      const [regionData, setRegionData] = useState([]);
      const [centreName, setCentreName] = useState('')
      const [centreEmail, setCentreEmail] = useState('')
      const [centrePhone, setCentrePhone] = useState('')
      const [centreRegion, setCentreRegion] = useState('')
      const [centreAddress, setCentreAddress] = useState('')
      const [load, setLoad] = useState(false);
      const [alat, setAlat] = useState(null);
      const history = useHistory();
   
      const usData = {type:'centre',id:cid,mStatus:sStatus}


      const noti = (type,message) =>{
        let data = {type,message}
       return (
         <>
          <NotifyUsers datas={data} />
         </>
       )
  }


      const columns = useMemo(
        () => [
          {
            accessorKey: 'names', //access nested data with dot notation
            header: 'Full Name',
            size: 150,
          },
          {
            accessorKey: 'current_term',
            header: 'Current Term',
            size: 150,
          },
          {
            accessorKey: 'grade', //normal accessorKey
            header: 'Grade',
            size: 200,
          },
          {
            accessorKey: 'academic_year',
            header: 'Academic Year',
            size: 150,
          },
          {
            accessorKey: 'profile_status',
            header: 'Profile Status',
            size: 150,
          },
        ],
        [],
      );
  
      const validDate = () =>{
          let valid = true;
           if(!centreEmail.trim() || !centreName.trim() || !centrePhone.trim()
            || !centreAddress.trim() || !centreRegion.trim()){
                  valid = false;
            }

             return valid;
      }

      const handleCentreUpdate = () =>{
           setAlat(null)
              if(!validDate()){
                  setAlat(<div className='alert alert-danger text-center'>Please make sure all form input are not empty</div>)
              }else
              {
                let cid = centre.centre_id;
                 let tid = null;
                  let name = null;
                  let type = 'Edit';
                let datas = {cid,centreEmail,centreName,centrePhone,centreAddress,centreRegion,type,tid,name}
                      setLoad(true)
                   axios.post('/api-admin-create-add-centre',datas,{
                    headers:{'Content-Type':'application/json'}
                   }).then((res)=>{
                          setTimeout(()=>{
                            setAlat(<div className={`alert alert-${res.data.color}`}>{res.data.message}</div>)
                            setLoad(false)
                          },1000)
                   }).catch((err)=>{
                      console.log(err)
                   })

              }
            
      }
     
      

    useEffect(()=> {
         axios.get('/api-getusers-data/'+cid+'/centre',{
                headers:{'Content-Type':'application/json'}
         }).then((res)=>{
             setTimeout(()=>{
                  let mc = res.data.udatas[0];
                 setCentre(res.data.udatas[0]);
                 setStud(res.data.stud);
                 setSStatus(res.data.udatas[0].status);
                 setRegionData(res.data.region);
                 setCentreName(mc.centre_name)
                 setCentrePhone(mc.phone)
                 setCentreEmail(mc.email)
                 setCentreRegion(mc.region)
                 setCentreAddress(mc.address)

                 setLoading(true)
             },1000)

         }).catch((err)=>{
                console.log(err)
         })
         
    },[])
  return (
    <div id="wrapper">
    <Head />
                         <div className="content-page">
                    <div className="content">
                <div className="container-fluid mb-4">


                    { loading === false ? <p>Loading Content. Please wait...</p> : 
                     <>
                <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">

                  <AccountStatus datas={usData} />
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Centre Dashboard</h4>
          </div>
        </div>
                         </div>


        <div class="modal fade" id="centerUserProfile" tabIndex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title" id="myCenterModalLabel">Centre Profile</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
              </div>
              <div class="modal-body">
  
                     <div className='row'>
                     
                 
  
                      <div className='col-xl-12 mb-3'>
                        
                      <label>Name</label>
                         <input type='text' className='form-control' onChange={(e)=> setCentreName(e.target.value)} defaultValue={centreName} />
                      </div>


                      <div className='col-xl-6 mb-3'>
                        
                        <label>Phone</label>
                           <input type='text' className='form-control' onChange={(e)=> setCentrePhone(e.target.value)}  defaultValue={centrePhone} />
                        </div>

                      <div className='col-xl-6 mb-3'>
                        
                        <label>Email</label>
                           <input type='text' className='form-control' onChange={(e)=> setCentreEmail(e.target.value)} defaultValue={centreEmail} />
                        </div>

                        <div className='col-xl-12 mb-3'>
                        
                        <label>Region</label>
                           <select type='text' value={centreRegion} onChange={(e)=> setCentreRegion(e.target.value)} className='form-control' >
                            { regionData && regionData.map((g,i)=>{
                                 return(
                                   <option key={i} value={g.location}>{g.location}</option>
                                 )
                            })}
                            </select>
                        </div>
  
                    
  
                      <div className='col-xl-12 mb-3'>
                          <label>Address</label>
                         <textarea className='form-control' onChange={(e)=> setCentreAddress(e.target.value)} defaultValue={centreAddress} ></textarea>
                      </div>

                      <div className="col-xl-12 text-center">
                           { alat }
                           </div>
  
                        <div className="col-xl-12 text-center">

                            {load !== false ? <p className='text-danger'>Updating centre. Please wait...</p> : null}

                            { load === false ?
                          <button className="btn btn-blue" onClick={()=>handleCentreUpdate()}>Update User Profile</button>
                                :
                                <button className="btn btn-blue" disabled>Please wait...</button>
                            }
                        </div>
  
  
                     </div>
  
                </div>
          </div>
      </div>
  </div>


              

                     <div className='row'>

                     <div className="col-lg-4 col-xl-4">
                                <div className="card-box text-center">
                                    <img src="../assets/images/users/user-1.jpg" className="rounded-circle avatar-lg img-thumbnail"
                                        alt="profile" />

                                    <h4 className="mb-0">{ centre.centre_name }</h4>
                                    <p className="text-muted">Centre</p>

                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <h4>{stud.length}</h4>
                                             <p>Number of Student</p>
                                        </div>
                                        <div className='col-lg-6'>
                                        <h4>{centre.status !== null ? 'Active' : 'Not Active' }</h4>
                                             <p>Centre Status</p>
                                        </div>
                                        <div className='col-lg-12'>
                                            <hr />
                                        </div>
                                    </div>
                                    

                                    <button type="button" className="btn btn-success btn-xs waves-effect mb-2 waves-light" data-toggle="modal" data-target="#centerUserProfile" data-backdrop="static">Edit information</button>
                                 
                                    <div className="text-left mt-3">
                                        <h4 className="font-13 text-uppercase">About Me :</h4>
                                     
                                        <div class="table-responsive">
                                          <table class="table mb-0">
                                            <tbody>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>{ centre.email }</td>
                                                </tr>
                                                <tr>
                                                    <th>Phone</th>
                                                    <td>{ centre.phone }</td>
                                                </tr>
                                                <tr>
                                                    <th>Region</th>
                                                    <td>{ centre.region }</td>
                                                </tr>
                                                <tr>
                                                    <th>Location</th>
                                                    <td>{ centre.address }</td>
                                                </tr>
                                                <tr>
                                                    <th>Date Created</th>
                                                    <td>{ centre.date_reg }</td>
                                                </tr>
                                            </tbody>

                                            </table>

                                        </div>
                                   </div>

                                  
                                </div> 

                             

                            </div> 



                            <div className="col-lg-8 col-xl-8">


                            <div className="card">
                                    <div className="card-body">
                                        
                                    <div className="row mb-2">
                                            <div className="col-sm-4">
                                               <h5>Students</h5>
                                            </div>

                                            <div className="col-sm-8">
                                             
                                            </div>

                                        </div>

                                      

                                        { 
                                          stud.length > 0 ?
                                        <MaterialReactTable columns={columns} data={stud}
                                                enableRowActions
                                                renderRowActionMenuItems={({ row }) => [
                                                <MenuItem key="edit" onClick={() => history.push('/student/'+row.original.stud_userid)}>
                                                    <ListItemIcon>
                                                <AccountCircle fontSize='small' />
                                        </ListItemIcon>
                                                    View
                                                </MenuItem>,
                                                <MenuItem key="delete" onClick={({ row }) => console.info(row.orginal.stud_userid)}>
                                                    <ListItemIcon>
                                                <Delete fontSize='small' />
                                                </ListItemIcon>
                                                    Delete
                                                </MenuItem>,
                                                ]}
                                                     /> : noti('danger','This centre has no student') }
                                          

                                        

                                     

                                </div>
                            </div>


                            </div>


                     </div></> }

                        </div>
                        </div>
                    </div>
                    </div>
  )
}

export default ViewCentre