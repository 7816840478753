import React,{useMemo, useEffect, useState} from 'react'
import axios from 'axios'
import {useHistory} from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { AccountCircle, Delete, Edit } from '@mui/icons-material';
import {handleLoad, NotifyUsers, CaptureRating } from '../auth/ErrorHandler'
import Result from '../others/Result';
import Head from '../Nav/Head';

function TermFinal() {
     const [loading, setLoading] = useState(false);
     const [stud, setStud] = useState([]);
     const [studData, setStudData] = useState();
     const [viewCapture, setViewCapture] = useState(false)
     const [hideshow, setHideShow] = useState(false);
     const [subject, setSubject] = useState([]);
     const [loadBut, setLoadBut] = useState(false);
     const [year, setYear] = useState('');
     const [term, setTerm] = useState('');
     const [notify, setNotify] = useState(null);
     const [getReport, setGetReport] = useState([]);
     const [centDetails, setCentDetails] = useState(null);
     const [viewResult, setViewResult] = useState(false);
     const [comment, setComment] = useState('');
  
     let tm_Split = term.split(',');
     let lenTerm = tm_Split.length;

     const NotifyMe = (type,message) =>{
        const datas = {type,message}
         return (
            <NotifyUsers datas={datas} />
         )
    }

    const getScoreRating = (type,score) =>{
          const datas = {type,score}
          return(
            <>
            <CaptureRating mdatas={datas} />
            </>
          )
    }

    const letGetTerms = (tms,r) =>{
      let mscore;
    switch(tms){
        case '1':
       
          
             mscore = r.term1;
           
          break;
         case '2':
          mscore = r.term2;
           break;
          case '3':
            mscore = r.term3;
            
        break;
        case '4':
          mscore = r.term4;
          
      break;
        default:
          mscore ="N/A";
    }

    let mst 
    if(mscore === null || mscore ===''){
          mst = 'N/A';
      }else{
           mst = mscore;
      }
  return mst;
}


const checkDisplayTable = (r) => {
  let len = tm_Split.length;
   console.log(r.cate)
  
  if( term === 'all'){

    return(
      <tr key={r.id}>
     <td>{ r.subject }</td>
     <td style={{ textTransform:'capitalize' }}>{ r.cate }</td>
      <td className='text-center' style={{ fontWeight:'bolder' }}>{ r.term1 === null ? 'N/A' : r.term1 }</td>
      <td className='text-center' style={{ fontWeight:'bolder' }}>{ r.term2 === null ? 'N/A' : r.term2 }</td>
      <td className='text-center' style={{ fontWeight:'bolder' }}>{ r.term3 === null ? 'N/A' : r.term3 }</td>
      <td className='text-center' style={{ fontWeight:'bolder' }}>{ r.term4 === null ? 'N/A' : r.term4 }</td>
   
    
        </tr>
     )

  }else{
   switch(len){
       case 1:
            let tms = tm_Split[0];
        console.log(tms)
         return(
          <tr key={r.id}>
          <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.subject }</td>
          <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.cate }</td>
         <td className='text-center' style={{ fontWeight:'bolder' }} >{ letGetTerms(tms,r) }</td>
         <td className='text-center' style={{ fontWeight:'bolder' }}>{ getScoreRating('percent',letGetTerms(tms,r)) }</td>
         <td className='text-center' style={{ fontWeight:'bolder' }}>{ getScoreRating(null,letGetTerms(tms,r)) }</td>
      
        
            </tr>
         )
        break;
        case 2:
          return(
            <tr key={r.id}>
             <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.subject }</td>
             <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.cate }</td>
            <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[0],r) }</td>
            <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[1],r) }</td>
           
          
              </tr>
           )
        break;
        case 3:
          return(
            <tr key={r.id}>
         
         <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.subject }</td>
         <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.cate }</td>
            <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[0],r) }</td>
            <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[1],r) }</td>
            <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[2],r) }</td>
          
          
          
              </tr>
           )
        break;
        default:
          return(
            <tr key={r.id}>
           <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.subject }</td>
           <td style={{ textTransform:'capitalize', fontWeight:'bolder' }}>{ r.cate }</td>
            <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[0],r) }</td>
            <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[1],r) }</td>
            <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[2],r) }</td>
            <td className='text-center' style={{ fontWeight:'bolder' }}>{ letGetTerms(tm_Split[3],r) }</td>
          
              </tr>
           )
          }

   }
}



     const handleViewResult = () => {
                setNotify(null)
            if(!year.trim() || !term.trim()){
                    setNotify(NotifyMe('danger','Please make sure you select all the category'))
            }else
            {
                setLoadBut(true)
                setViewResult(false)
               const grade = studData.grade;
               const sid = studData.stud_userid;
               let datas = {terms:term,year:year,studGrade:grade,sid:sid}
              axios.post('/api-admintermfinal',datas,{
                headers:{'Content-Type':'application/json'}
              }).then((res)=>{
                  setTimeout(()=>{
                    setLoadBut(false)

                     setViewResult(true)
                     
                     if(res.data.cent !== 'empty'){
                            setCentDetails(res.data.cent)
                     }else
                     {
                      setCentDetails(null)
                     }

                    if(res.data.report !== 'empty'){
                      setGetReport(res.data.report);
                    }else
                    {
                      setGetReport(null)
                    }
                  },1000)
                 
              }).catch(err=>{
                  console.log(err)
              })
                
            }
     }


     const CaptureStudent = () => {

         return (
            <>
              <div className="col-lg-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-lg-12">
                         { notify !== null ? notify : null }
                      <form className="row">
                       
                      <div className='col-lg-1'></div>
                          
                      <div className='col-lg-4'>
                        <select className="custom-select" onChange={(e)=> setTerm(e.target.value)} id="status-select" style={{marginLeft:20}} >
                            <option value=''> Term</option>
                            <option value={1}> Term 1</option>
                            <option value={2}> Term 2</option>
                            <option value={3}> Term 3</option>
                            <option value={4}> Term 4</option>
                            <option value={'1,2'}> Term 1, 2</option>
                            <option value={'1,2,3'}> Term 1, 2, 3</option>
                            <option value={'2,3,4'}> Term 2, 3, 4</option>
                            <option value={'3,4'}> Term 3, 4</option>
                            <option value={'all'}>All Term</option>
                         
                          </select>

                          </div>
                          <div className='col-lg-4'>
                        <select className="custom-select " onChange={(e)=> setYear(e.target.value)} id="status-select" style={{marginLeft:20}} >
                          <option value=''>Year</option>
                             
                          <option value={2020}>2020</option>
                          <option value={2021}>2021</option>
                          <option value={2022}>2022</option>
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                          <option value={2031}>2031</option>
                          </select>
                          </div>

                          <div className='col-lg-2'>
                            { loadBut === false ? 
                          <button type="button" style={{marginLeft:10}} onClick={()=> handleViewResult()} className="btn btn-primary waves-effect waves-light" ><i className="mdi mdi-eye mr-1" /> View Reault</button>
                                : 
                                <button type="button" style={{marginLeft:10}} className="btn btn-primary waves-effect waves-light" disabled><i className="mdi mdi-eye mr-1" /> Please wait...</button>
                            }
                          </div>
                          <div className='col-lg-2'>
                            </div>

                          <div className='col-lg-6 mt-2'>
                              <textarea className='form-control' placeholder='Add comment (Optional)' onChange={(e)=> setComment(e.target.value)}></textarea>
                          </div>
                      </form>
                    </div>
                    <div className="col-lg-2">
                      <div className="text-lg-right mt-3 mt-lg-0">
                          
                        
                      </div>


                   
                    </div>
                  </div> 
                </div> 
              </div> 

              { viewResult === false ? null : viewMyReport() }
                
              </>
         
         )

     }


     const viewMyReport = () =>{

      let reports = {checkDisplayTable,terms:term,lenTerm,term,getReport,tm_Split,comment,centDetails,viewStud:studData,NotifyMe}
            return(
                <div>
                <Result report={reports} />
                </div>
            ) 
    }
  
     const viewStudent = (studID) =>{ 
        axios.get('/get-admin-student/'+studID,{
            headers:{'Content-Type':'application/json'}
       }).then((res)=>{
               setTimeout(()=>{
                    setStudData(res.data.stud[0])
                    setSubject(res.data.subData);
                     setViewCapture(true)
                     setHideShow(true)
               },500)
       }).catch((err)=>{
           console.log(err)
       })
     }

     const columns = useMemo(
        () => [
          {
            accessorKey: 'names', //access nested data with dot notation
            header: 'Full Name',
            size: 150,
          },
          {
            accessorKey: 'term',
            header: 'Current Term',
            size: 150,
          },
          {
            accessorKey: 'grade',
            header: 'Grade',
            size: 150,
          },
          {
            accessorKey: 'academic', //normal accessorKey
            header: 'Academic Year',
            size: 200,
          },
          {
            accessorKey: 'centre',
            header: 'Centre',
            size: 150,
          },
          {
            accessorKey: 'profile',
            header: 'Profile Status',
            size: 150,
          },
          {
            accessorKey: 'due_date',
            header: 'Due Date',
            size: 150,
          },
        ],
        [],
      );
  

    useEffect(()=>{
        axios.get('/get-admin-student/all',{
             headers:{'Content-Type':'application/json'}
        }).then((res)=>{
                setTimeout(()=>{
                     setStud(res.data.stud)
                     setLoading(true)
                },1000)
        }).catch((err)=>{
            console.log(err)
        })

    },[])
  return (
    <div id="wrapper">
    <Head />
    <div className="content-page">
 <div className="content">
 <div className="container-fluid">
    { loading === false ? <p>Loading Content. Please wait...</p> :

                        <>
                         <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                 
                   
                 
                  </div>
                </div>
               
              </form>
            </div>
            { viewCapture === false ?
            <h4 className="page-title">Select Student</h4>
            :  <h4 className="page-title">View Assessment of { studData.names }</h4> }
          </div>
        </div>
                 </div>
                    <div className='row'>
                            { viewCapture === false ? null : 
                              CaptureStudent()
                            }
                        
                            { hideshow === false ?
                        <div className='col-lg-12'>
                    <MaterialReactTable columns={columns} data={stud}
                    enableRowActions
                    renderRowActionMenuItems={({ row }) => [
                    <MenuItem key="edit" onClick={() => viewStudent(row.original.studID)}>
                        <ListItemIcon>
                    <AccountCircle fontSize='small' />
                    </ListItemIcon>
                        View Term & Final
                    </MenuItem>,
                   
                    ]}
                    /></div> : null }
                    
                    </div></> }
    
    


    </div>
    </div>
    </div>
    </div>
  )
}

export default TermFinal