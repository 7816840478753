import React, {useState, useEffect,useMemo} from 'react'
import { MaterialReactTable } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { Edit, Delete, Visibility, Add } from '@mui/icons-material';
import axios from 'axios'
import Head from '../Nav/Head'
import { HandleCentres } from '../auth/ErrorHandler'

function Request() {
       const [remo, setRemo] = useState([]);
       const [cent, setCent] = useState([]);
       const [withd, setWithD] = useState([]);
       const [viewWith, setViewWith] = useState(null);
       const [usr, setUsr] = useState();
       const [notis, setNotis] = useState(null)
       const [load, setLoad] = useState(false)
       const [viewLink, setViewLink] = useState(null);
       const [viewCentre, setViewCentre] = useState();
       const [cStatus, setCStatus] = useState('');
       const [clstatus, setClStatus] = useState();
       const [rid, setRid] = useState();
       const [viewRequest, setViewRequest] = useState(null);
       const [loadReq, setLoadReq] = useState(false);
       const [centres, setCentres] = useState([]);
       const [stid, setStid] = useState();
       const [scentID, setSCentID] = useState();

          
       
        const getAllcentres = () =>{
             axios.get('/api-adminfetchallcentre-datas',{
                headers:{'Content-Type':'application/json'}
             }).then((res)=>{
                  setCentres(res.data.centres)
             }).catch((err)=>{
               console.log(err)
             })
        }

       const fetchCentres = () =>{
            const datas = {centres,stid,scentID}
           return (
            <div className="modal fade" id="scrollable-modal" tabIndex="-1" role="dialog"
            aria-labelledby="scrollableModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="scrollableModalTitle">Available Centres</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                      { centres.length === 0 ? <div className='alert alert-blue text-center'>No centre available</div> :
                      <div className='row'>
                            <HandleCentres datas={datas}/>

                            </div>
                         }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      
                    </div>
                </div>
            </div>
        </div>
           )
       }


       const userRequestStatus = (status,id) =>{
                  setLoadReq(true)
                  setNotis(null)
              axios.post('/api-updaterequeststatus-admin',{status,id},{
                    headers:{'Content-Type':'application/json'}
              }).then((res)=>{
                        
                    setTimeout(()=>{
                        setNotis(res.data);
                        setLoadReq(false)
                    },1000)
              }).catch((err)=>{
                  console.log(err)
              })
       }


       const displayStud = (type,request,displayUser) =>{
               if(type === 'Parent' && request === 'removal'){
                    return (
                        <>
                             <h5 className="mb-3 mt-4 text-uppercase bg-light p-2"><i className="mdi mdi-account-circle mr-1"></i> Learner Information</h5>
                                  
                         <ul>
                      
                             <li>Name: {displayUser[0].names} </li>
                             <li>Student ID: {displayUser[0].stud_userid} </li>
                             <li>Grade: {displayUser[0].grade} </li>
                             <li>Gender: {displayUser[0].gender} </li>
                             <li>Academic Year: {displayUser[0].academic_year} </li>
                             <li>Due Date: {displayUser[0].due_date} </li>
                             <li>Profile Status: {displayUser[0].profile_status} </li>
                             
                           </ul>
                        </>
                    )
               }else
               if(type === 'Parent' && request === 'removal')
               {
                   return(

                    <>
                      <h5 className="mb-3 mt-4 text-uppercase bg-light p-2"><i className="mdi mdi-account-circle mr-1"></i> Centre Information</h5>
                                  
                     <ul>
                     <li>Name: {displayUser[0].centre_name} </li>
                     <li>Centre ID: {displayUser[0].centre_id} </li>
                     <li>Email: {displayUser[0].email} </li>
                     <li>Phone: {displayUser[0].phone} </li>
                     <li>Address: {displayUser[0].address} </li>
                     <li>Region: {displayUser[0].region} </li>
                     </ul>
                    </>

                   )
               }
       }


      const getUserRequest = (id) =>{
                axios.get('/api-getadmin-userrequest/'+id,{
                   headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                    // console.log(res.data.userRequest);
                     setViewRequest(res.data.userRequest)

                }).catch((err)=>{
                      console.log(err)
                })
      }

       const requestStatus = (type) =>{

                axios.post('/api-updaterequest-admin',{rid,type},{
                      headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                            setCStatus(type)
                        if(res.data.status === 'success'){
                             window.location=""
                        }else
                        {
                            setNotis(<div className='alert alert-info text-center'>{res.data.status}</div>)
                        }
                }).catch((err)=>{
                        console.log(err)
                })
       }


       const centreProfile = () => {
        return (
            <div className="card-box">
            <div className="media mb-3">
            <div className="avatar-lg mr-3 rounded-circle bg-soft-pink border-pink border">
                                                <i className="fe-users font-22 avatar-title text-pink"></i>
                                            </div>
                <div className="media-body">
                    <h4 className="mt-0 mb-1">{ viewCentre[0].centre_name }</h4>
                    <p className="text-muted">Centre ID: { viewCentre[0].centre_id } </p>
                    <p className="text-muted">Owner:  { viewCentre[0].owner }</p>
                </div>
            </div>

            <h5 className="mb-3 mt-4 text-uppercase bg-light p-2"><i className="mdi mdi-account-circle mr-1"></i> Personal Information</h5>
            <div className="">
             

                <h4 className="font-13 text-muted text-uppercase mb-1">Email :</h4>
                <p className="mb-3"> { viewCentre[0].email }</p>

                <h4 className="font-13 text-muted text-uppercase mb-1">Phone :</h4>
                <p className="mb-3">{ viewCentre[0].phone }</p>

                <h4 className="font-13 text-muted text-uppercase mb-1">Address :</h4>
                <p className="mb-3"> { viewCentre[0].address}</p>

                <h4 className="font-13 text-muted text-uppercase mb-1">Region :</h4>
                <p className="mb-0">{ viewCentre[0].region}</p>

            </div>

        </div>
        )
}


       const learnerProfile = () => {
                return (
                    <div className="card-box">
                    <div className="media mb-3">
                       
                                      <div className="avatar-lg mr-3 rounded-circle bg-soft-pink border-pink border">
                                                <i className="fe-users font-22 avatar-title text-pink"></i>
                                            </div>
                        <div className="media-body">
                            <h4 className="mt-0 mb-1">{ viewLink[0].names }</h4>
                            <p className="text-muted">Learner { viewLink[0].profile_status}</p>
                            <p className="text-muted">Learner ID: { viewLink[0].stud_userid }</p>
                           
                        </div>
                    </div>

                    <h5 className="mb-3 mt-4 text-uppercase bg-light p-2"><i className="mdi mdi-account-circle mr-1"></i> Personal Information</h5>
                    <div className="">
                       
                        <h4 className="font-13 text-muted text-uppercase mb-1">Grade :</h4>
                        <p className="mb-3"> {viewLink[0].grade }</p>

                        <h4 className="font-13 text-muted text-uppercase mb-1">Gender :</h4>
                        <p className="mb-3">{viewLink[0].gender }</p>
                    
                        <h4 className="font-13 text-muted text-uppercase mb-1"> Address :</h4>
                        <p className="mb-3"> { viewLink[0].address }</p>

                        <h4 className="font-13 text-muted text-uppercase mb-1">Region :</h4>
                        <p className="mb-0"> { viewLink[0].region }</p>

                    </div>

                </div>
                )
       }


       const viewRequestInfo = (id,sid,cid) => {
                    setRid(id)
                    setStid(sid)
                axios.post('/api-learnerlinkrequest-admin',{id,sid,cid},{
                      headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                    setTimeout(()=>{
                          setViewLink(res.data.stud);
                          setViewCentre(res.data.cent);
                          setSCentID(res.data.cent[0].centre_id)
                          setClStatus(res.data.cstatus);
                    },500)
                }).catch((err)=>{
                    console.log(err)
                })
       }

       const updateUserWithdrawalStatus = (status) =>{
             let id = viewWith.wid;
                if(status === ''){  setNotis(null)
                    setLoad(false) }
                else
                {
               setNotis(null)
               setLoad(true)
            axios.post('/api-updateapi',{status,id},{
                headers:{'Content-Type':'application/json'}
            }).then((res)=>{
                 setTimeout(()=>{
                     setLoad(false)
                     setNotis(<div className="alert alert-info text-center">{res.data.message}</div>)
                 },1000)
            }).catch((err)=>{
                  console.log(err)
            })
        }
       }


       const rCoulumns = useMemo(
        () => [
          {
            accessorKey: 'userid', //access nested data with dot notation
            header: 'User ID',
            size: 150,
          },
          {
            accessorKey: 'user_type',
            header: 'User Type',
            size: 150,
          },
          {
            accessorKey: 'ad_action', //normal accessorKey
            header: 'Request',
            size: 200,
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
          },
       
        ],
        [],
      );

       const wCoulumns = useMemo(
        () => [
          {
            accessorKey: 'name', //access nested data with dot notation
            header: 'Names',
            size: 150,
          },
          {
            accessorKey: 'amount',
            header: 'Amount',
            size: 150,
          },
          {
            accessorKey: 'request_date', //normal accessorKey
            header: 'Date Request',
            size: 200,
          },
          {
            accessorKey: 'userid',
            header: 'User ID',
            size: 150,
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
          },
        ],
        [],
      );


      const c_Columns = useMemo(
        () => [
          {
            accessorKey: 'stud_userid', //access nested data with dot notation
            header: 'Learner ID',
            size: 150,
          },
          {
            accessorKey: 'centre_name',
            header: 'Centre Name',
            size: 150,
          },
          {
            accessorKey: 'cid', //normal accessorKey
            header: 'Centre ID',
            size: 200,
          },
          {
            accessorKey: 'date_join',
            header: 'Date Request',
            size: 150,
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
          },
        ],
        [],
      );

      const withdrawDetails = (id,userid) => {
                axios.post('/api-getwithdrawal-request',{id,userid},{
                     headers:{'Content-Type':'application/json'}
                }).then((res)=>{
                        setTimeout(()=>{
                            setUsr(res.data.usr);
                            setViewWith(res.data.w);
                            
                        },500)
                }).catch((err)=>{
                    console.log(err)
                });
      }

    useEffect(()=>{
      getAllcentres();
         axios.get('/api-admingetallrequest',{
             headers:{'Content-Type':'application/json'}
         }).then((res)=>{

             setWithD(res.data.withd);
             setCent(res.data.centre_req);
             setRemo(res.data.rem)

         }).catch((err)=>{
            console.log(err)
         })
    },[])

    const mstatus = (stat) =>{
        switch(stat){
            case 'Progress':
                 return (
                  <span className="badge badge-warning">{ stat }</span>
                 )
              break;
              case 'Pending':
                return (
                  <span className="badge badge-info">{ stat }</span>
                 )
                break;
                case 'Reject':
                  return (
                    <span className="badge badge-danger">{ stat }</span>
                   )
                  break;

                case 'Complete':
                  return (
                    <span className="badge badge-success">{ stat }</span>
                   )
                  break;
        }
}  

  return (
    <div id="wrapper">
    <Head />
    <div className="content-page">
 <div className="content">
 <div className="container-fluid">

 <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                 
                   
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Request</h4>
          </div>
        </div>
                 </div>

    <div className='row'>
                     <div className="col-xl-12">
                                <div className="card-box">
                                   
        
                                    <ul className="nav nav-tabs nav-bordered nav-justified">
                                        <li className="nav-item">
                                            <a href="#home-b2" data-toggle="tab" aria-expanded="false" className="nav-link">
                                              Request <span className="badge badge-danger">{remo.length}</span>
                                            </a>
                                        </li>
                                     
                                        <li className="nav-item">
                                            <a href="#messages-b2" data-toggle="tab" aria-expanded="false" className="nav-link">
                                               Link Student <span className="badge badge-danger">{ cent.length }</span>
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="#withdrawal-b2" data-toggle="tab" aria-expanded="false" className="nav-link active">
                                               Withdrawal <span className="badge badge-danger">{ withd.length }</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane" id="home-b2">
                                          { remo.length > 0 ? 

                                          viewRequest !== null ? <>
                                              <div className='card border-success'>
                                                <div className='card-body'>
                                                   <div className='row'>
                                                    <div className='col-lg-12'>
                                                    <div className="mt-4">
                                                    <h5 className="font-18">A request from a user</h5>
                                                    <h5 className="font-18" style={{ float:'right', marginTop:-20, textTransform:'uppercase' }}>Status: { viewRequest.urequest[0].status !== 'Approved' ? <span className='text-danger'>Pending</span> : <span className='text-blue'>Approved</span> }</h5>
                                                    <hr />

                                              <div className="media mb-3 mt-1">
                                                <img className="d-flex mr-2 rounded-circle" src="../assets/images/users/user-2.jpg" alt="placeholder image" height="32" />
                                                <div className="media-body">
                                                    <small className="float-right"> { viewRequest.urequest[0].date_request } </small>
                                                    <h6 className="m-0 font-14"> { viewRequest.udatas[0].names }</h6>
                                                    <small className="text-muted">From { viewRequest.udatas[0].user_type }</small>
                                                </div>
                                             </div>

                                             <p>Hi Admin</p>
                                            <p>
                                              { viewRequest.urequest[0].reasons }
                                            </p>
                                            
                                             { displayStud(viewRequest.udatas[0].user_type,viewRequest.urequest[0].ad_action,viewRequest.stud)}
                                           

                                          
                                            <hr/>

                                            { viewRequest.urequest[0].status === 'Approved' ? null : viewRequest.urequest[0].ad_action === 'removal' ? 
                                            <div className="mt-5 mb-5">
                                                { notis !== null ? <><div className={`alert alert-${notis.color} text-center`}>{notis.message}</div></> : null}
                                              { loadReq !== false ? <p><i className='text-danger'>Processing your request. Please wait...</i></p> : null}
                                              
                                               <button className="btn btn-success mr-2" onClick={()=> userRequestStatus('Approved',viewRequest.urequest[0].id)}><i className="mdi mdi-reply mr-1"></i> Approve Request</button>
                                                <button className="btn btn-danger" onClick={()=> userRequestStatus('Disapproved',viewRequest.urequest[0].id)}>  Disapprove Request <i className="mdi mdi-forward ml-1"></i></button>
                                            
                                            </div> : null }




                                                      </div>
                                                    </div>
                                                   </div>
                                                </div>
                                              </div>
                                          
                                          </> :
                                          <MaterialReactTable columns={rCoulumns} data={remo}
                                          enableRowActions
                                          renderRowActionMenuItems={({ row }) => [
                                            <MenuItem key="edit" onClick={() => getUserRequest(row.original.id)}>
                                               <ListItemIcon>
                                           <Visibility fontSize='small' />
                                    </ListItemIcon>
                                              View
                                            </MenuItem>
                                          ]}
                                          /> : <div className='alert alert-info text-center'> No Request at this moment </div>

                                        }
                                        </div>
                                       
                                        <div className="tab-pane" id="messages-b2">
                                            

                                            { viewLink !== null ? <div className='row'>
                                                    <div className='col-lg-4'>

                                                        { learnerProfile() }

                                                        { fetchCentres() }

                                                     </div>

                                                    <div className='col-lg-4 text-center'>
                                                                { clstatus[0].status === 'Approved' ?
                                                            <div className='d-flex mt-4' style={{ marginLeft:30 }}>
                                                            <button className='btn btn-success' data-toggle="modal" data-target="#scrollable-modal">Change centre</button>
                                                            <button className='btn btn-danger' onClick={()=> requestStatus('delete')} style={{ marginLeft:10 }}>Remove Learner</button>
                                                            </div> : 
                                                                cStatus !== 'Approved' ?
                                                            <div className='d-flex mt-4' style={{ marginLeft:30 }}>
                                                            <button className='btn btn-success' onClick={()=>requestStatus('Approved')}>Approve Request</button>
                                                            <button className='btn btn-danger' onClick={()=> requestStatus('delete')} style={{ marginLeft:10 }}>Delete Request</button>
                                                            </div> :

                                                            <div className='d-flex mt-4' style={{ marginLeft:30 }}>
                                                            <button className='btn btn-success' data-toggle="modal" data-target="#scrollable-modal">Change centre</button>
                                                            <button className='btn btn-danger' onClick={()=>requestStatus('delete')} style={{ marginLeft:10 }}>Remove Learner</button>
                                                            </div>

                                                            }
                                                        </div>

                                                        <div className='col-lg-4'>
                                                            { centreProfile() }
                                                        </div>
                                            </div> :
                                            <>
                                            <h4>Learner Request</h4>
                                           { cent.length > 0 ? <MaterialReactTable columns={c_Columns} data={cent}
                                            
                               enableRowActions
                               renderRowActionMenuItems={({ row }) => [
                                 <MenuItem key="edit" onClick={() => viewRequestInfo(row.original.id,row.original.stud_userid,row.original.cid)}>
                                    <ListItemIcon>
                                <Visibility fontSize='small' />
                         </ListItemIcon>
                                   View
                                 </MenuItem>  
                               ]}
                               /> : <div className='alert alert-info text-center'>No request at this moment</div> } </>}
                                           
                                        </div>
                                        <div className="tab-pane active" id="withdrawal-b2">

                                       { 
                                        
                                         viewWith !== null ? 
                                         <> 

                                        <div className='row'>
                                              <div className='col-lg-4 mt-4 mb-4'>
                                                 { notis !== null ? notis : null }
                                                <select className='custom-select' onChange={(e) => updateUserWithdrawalStatus(e.target.value)}>
                                                    <option value={''}>Select Status</option>
                                                    <option value={'Complete'}>Request Completed</option>
                                                    <option value={'Reject'}>Reject Request</option>
                                                    <option value={'Progress'}>Request In Progress</option>
                                                    <option value={'Pending'}>Request Pending</option>
                                                </select>

                                                { load === false ? null :  <p>Please wait...</p> }

                                              </div>

                                              <div className='col-lg-8 mt-4 mb-4'>
                                                  <button className='btn btn-blue' onClick={()=> setViewWith(null)} style={{ float:'right' }}>Back</button>
                                                </div>
                                            <div className='col-lg-12'>
                                            <div className="table-responsive">
                                              <table className="table table-borderless mb-0"> 
                                                <thead className="thead-light">
                                                        <tr>
                                                            <th>Bank Name</th>
                                                            <th>Account Name</th>
                                                            <th>Account Number</th>
                                                            <th>Branch Code</th>
                                                        </tr>
                                                        
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{ usr.bname }</td>
                                                            <td>{ usr.aname }</td>
                                                            <td>{ usr.anumber }</td>
                                                            <td>{ usr.bcode }</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 mt-4'>

                                            <div className="table-responsive">
                                            <table className="table table-borderless mb-0"> 
                                                      <thead className="thead-light">
                                                        <tr>
                                                            <th>User Names</th>
                                                            <th>User Type</th>
                                                            <th>Amount Request</th>
                                                            <th>Date Request</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{ viewWith.names }</td>
                                                            <td>{ viewWith.utype }</td>
                                                            <td>{ viewWith.amt }</td>
                                                            <td>{ viewWith.date }</td>
                                                            <td>{ mstatus(viewWith.status) }</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                        </div>
                                         
                                         
                                         </> :
                                       
                                       withd.length > 0 ? <MaterialReactTable columns={wCoulumns} data={withd}
                               enableRowActions
                               renderRowActionMenuItems={({ row }) => [
                                 <MenuItem key="edit" onClick={() => withdrawDetails(row.original.id,row.original.userid)}>
                                    <ListItemIcon>
                                <Visibility fontSize='small' />
                         </ListItemIcon>
                                   View
                                 </MenuItem>
                               ]}
                               /> : <div className='alert alert-danger text-center'>No Withdrawal Request </div> }
                                        </div>
                                    </div>
                                </div>
                            </div>
    </div>

    </div>
    </div>
</div>
</div>
  )
}

export default Request